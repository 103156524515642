<template>
  <div
      class="flex flex-row justify-between items-center flex-wrap text-gray-400 size-full gap-x-2 gap-y-1"
  >
    <component
        :is="component"
        v-for="({component, props}, index) in filters"
        :key="'filterBar' + index"
        v-model="filterValues[props.name]"
        v-bind="props"
        @update="emitFilters"
    />
    <button
        v-if="reset"
        class="flex flex-row flex-nowrap justify-center items-center bg-gray-600 hover:bg-gray-700 active:bg-gray-900 p-2 px-3 rounded-xl gap-1"
        @click="resetFilters"
    >
      <svg
          class="bi bi-funnel-fill my-auto" fill="currentColor" height="16"
          viewBox="0 0 16 16" width="16"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"
        />
      </svg>
      Reset
    </button>
  </div>
</template>
<script>

export default {
    name: 'filterBar',

    props: {
        page: {
            type: Number,
            required: false
        },
        filters: {
            type: Array,
            required: true,
        },
        value: {
            required: true,
        },
        reset: {
            required: false,
            default: true,
        }
    },

    data() {
        return {
            filterValues: {},
        }
    },

    computed: {
        defaultValues() {
            const defaults = this.filters.reduce(
                (obj, filter) => {
                    obj[filter.props.name] = filter.props.default;
                    return obj;
                },
                {}
            );
            if (this.page !== undefined) {
                defaults.page = this.page;
            }

            return defaults;
        },
    },

    activated() {
        this.filterValues = this.defaultValues;
        this.emitFilters();
    },
    
    created() {
        if (!this.$vnode?.data?.keepAlive) {
            this.filterValues = this.defaultValues;
            this.emitFilters();
        }
    },

    methods: {
        resetFilters() {
            this.filterValues = this.defaultValues;
            this.emitFilters();
        },
        emitFilters() {
            this.$emit('input', this.filterValues);
        }
    },

    watch: {
        page(newValue) {
            if (newValue === null) {
                return;
            }

            this.filterValues.page = newValue;
            this.emitFilters();
        },
    },
}
</script>