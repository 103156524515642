<template>
  <div>
    <div v-if="activeIncidentType?.name?.toLowerCase() === 'vacation'">
      <div
          class="text-sm font-semibold h-[2.60rem] text-left content-center text-gray-500"
      >
        Vacation Usage
      </div>
      <div class="flex flex-col gap-y-2">
        <div
            v-for="( limit, index ) in filteredVacationLimits"
            :key="`${activeIncidentType.name}${index}`"
            class="text-left rounded-lg overflow-hidden"
        >
          <div class="bg-[#5d7a9d] text-white w-full py-0.5 text-center content-center">
            <span>{{ format(limit.date, "MMM d yyyy") }}</span>
            <span class="text-xs"> to </span>
            <span>{{ format(limit.expirationDate, "MMM d yyyy") }}</span>
          </div>
          <div class="py-0.5 bg-white text-center content-center">
            Used {{ limit.used }} out of {{ limit.maxIncidents }} days
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {format, startOfDay} from "date-fns";

export default {
    name: "IncidentInformation",
    methods: {format},

    data() {
        return {};
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'vacationLimits',
                'activeIncidentType'
            ]
        ),
        filteredVacationLimits() {
            return this.vacationLimits.filter((limit) => {return limit.expirationDate >= startOfDay(new Date());})
        }
    }
}
</script>