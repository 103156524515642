<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-center mb-4">
          <h3>Disagree Adjustment Request</h3>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="row">
      <div class="col text-center">
          <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="secondary" type="grow" label="Spinning"></b-spinner>
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
    <div v-else class="row">
      <div class="col text-center">
        <label>
          Date:
          <b>{{this.requestInfo.CreatedAt | moment('DD/MM/YYYY')}}</b>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <label>
          Event:
          <b>{{this.requestInfo.Event}}</b>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Reason:</label>
          <select v-model="adjustment.reason" class="form-control" id="exampleFormControlSelect1">
            <option value="null">Select a Reason</option>
            <option value="misuse">Agent tool misuse</option>
            <option value="internet">Internet issue</option>
            <option value="clocker">Issue with clocker</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="adjustment.reason == 'other'" class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">
            <a class="text-danger">*</a>Write the reason:
          </label>
          <input class="form-control" type="text" v-model="adjustment.otherReason" placeholder="Write the reason"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mb-1">
        <div class="form-group">
          <label for="exampleFormControlSelect1">How long did it really last?:</label>
          <vue-timepicker v-model="adjustment.duration" class="form-control" format="HH:mm" close-on-complete></vue-timepicker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" @click="sendRequest" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded">Save</button>
        <!-- <button type="button" class="bg-gray-200 px-6 py-2 rounded">Cancel</button> -->
      </div>
    </div>
  </div>
</template>
<script>
//Guardar en clocker adjustment Request
import VueTimepicker from 'vue2-timepicker'
import moment       from 'moment';
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "myComponent",
  components: { VueTimepicker },
  data() {
    return {
      time: null,
      adjustment: {
        reason: null,
      },
      isLoading: false,
    };
  },
  props: {
    requestInfo: Object,
  },
  computed: {
    ...mapState(["clockerUsers"]),
    ...mapGetters(["loadingFlag"]),
  },
  methods: {
    ...mapActions(['sendAdjustment']),
    sendRequest: function () {
        this.isLoading = true
        let objRequest = {
            EmpID:          this.requestInfo.Emp_id,
            event_date:     this.requestInfo.CreatedAt,
            event_type:     this.requestInfo.Event,
            campaign:       this.requestInfo.Campaign,
            reason:         this.adjustment.reason,
            otherReason:    this.adjustment.otherReason,
            duration:       this.adjustment.duration,
            Approval_id:    this.requestInfo.Approval_id,
            RecID:          this.requestInfo.rec_id,
            type:           'Agent'
        };
        this.sendAdjustment(objRequest).then( (res) =>{
          //ADD Loader HERE 
          if (res.status == 200){
              this.$fire({
                  type: 'success',
                  title: 'The request has been store successfully',
                  showConfirmButton: false,
                  timer: 1500
                }).then(()=>{ 
                    this.$router.go(-1);                
                }); 
            }
        });
    },
    customFormatter(date) {
        return moment(date).format('HH:mm');
    },
  },
};
</script>
<style lang="scss" scoped>
    @import '~vue2-timepicker/dist/VueTimepicker.css';
    .display-time {
        border: none !important;
        cursor: pointer !important;
        height: auto !important;
        padding: 0 !important;
        width: 100% !important;
    }
</style>