<template>
  <div class="flex flex-col">
    <div
        class="text-left text-sm font-semibold h-[2.60rem] content-center text-gray-500"
    >
      Upcoming Incidents
    </div>
    <div class="flex flex-col gap-y-1">
      <div
          v-for="incident in upcomingIncidents" :key="incident.id"
          class="rounded-lg flex flex-row justify-between items-center bg-gray-200 py-0.5 px-1"
      >
      <span class="capitalize">
        {{ incident.name.toLowerCase() }}
      </span>
        <div class="text-xs flex flex-col">
        <span>
          {{format(incident.to, 'MM-dd-yy')}}
        </span>
          <span>
          To: {{format(incident.to, 'MM-dd-yy')}}
        </span>
        </div>
        <div
            :class="{
                  'bg-[#F3C87A]': ['HR PENDING', 'PENDING'].includes(incident.status),
                  'bg-[#D6655A]': incident.status === 'REJECTED',
                  'bg-[#A8CC8C]': incident.status === 'APPROVED',
                  }"
            class="px-1 content-center flex flex-row rounded-full
          items-center text-[#615030] gap-x-0.5"
        >
          <svg
              v-if="incident.status === 'approved'"
              class="size-3.5" fill="none" stroke="currentColor"
              stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <svg
              v-if="incident.status === 'pending'"
              class="size-3.5" fill="none" stroke="currentColor"
              stroke-width="1.5" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <span
              class="capitalize text-xs select-none"
          >
          {{ incident.status }}
        </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {startOfToday, format} from 'date-fns';

export default {
    name: 'UpcomingIncidents',
    methods: {format},

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'incidentHistory'
            ]
        ),
        upcomingIncidents() {
            return this.incidentHistory.filter(
                (incident) => {
                    return incident.to.getTime() > startOfToday().getTime()
                }
            )
        }
    },

    data() {
        return {};
    },
};
</script>