<template>
    <div v-if="isVisible" class="resource-card relative w-72 rounded-md p-2 pb-3 m-2 cursor-pointer group "
        @click="goToLink" :data-category="item.category">
        <div
            class="relative w-full h-44 bg-blue-100 overflow-hidden border-8 rounded-xl shadow group-hover:border-white group-hover opacity-95">
            <img :src="item.display_image" :alt="item.title" class="w-full h-full ">
        </div>
        <div class="mt-2   text-left px-2" :title="item.title">
            <span class="text-[#2B2C4B] font-semibold text-md capitalize text-ellipsis">
                {{ item.title }}
            </span>
        </div>
        <div class="px-2 mt-2 text-justify text-sm text-[#585A5D] font-thin h-20 overflow-hidden text-ellipsis pb-5">
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            default: () => { },
            type: Object,
        },
    },
    methods: {
        goToLink() {
            if (!this.item.slug) {
                return this.$fire('This training does not have the proper resource slug set. Please contact and administrator.')
            }
            this.$router.push({ name: 'course.rise', params: { slug: this.item.slug } })
        }
    },
    computed: {
        isVisible: function () {
            if (this.isGranted('SystemAdministrator')) return true;

            const sites = this.item.site;

            if (!sites || sites === 'null') return true;

            if (sites && sites.length === 0) return true;

            if (sites?.includes(this.location.toLowerCase())) return true;

            return false;
        }
    }
}
</script>
