export default {
    bind(el, binding) {
        el.clickOutsideEvent = function (event) {
            if (el === event.target || el.contains(event.target)) {
                return;
            }

            let currentElement = event.target;
            while (currentElement) {
                let checks = [
                    currentElement.hasAttribute &&
                    currentElement.hasAttribute('data-click-outside-ignore'),

                    currentElement.classList &&
                    currentElement.classList.contains('mx-datepicker-main'),
                ]

                if (checks.includes(true)) {
                    return
                }

                currentElement = currentElement.parentElement;
            }

            binding.value(event);
        };

        document.addEventListener('click', el.clickOutsideEvent, true);
    },

    unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent, true);
    },
};