import Vue from 'vue'
import Vuex from 'vuex'
import employees from './modules/employees'
import states from './modules/states'
import session from './modules/session'
import definitions from './modules/definitions'
import cities from './modules/cities'
import mail from './modules/mail'
import clocker from './modules/exams/clocker'
import clockerUsers from './modules/clocker'
import feedbacks from './modules/feedbacks'
import clockerUtility from './modules/clockerUtility'
import AccountManagerClocker from './modules/AccountManagerClocker'
import fileState from "./modules/files";
import axios from '../config/axios'
import payroll from './modules/payroll'
import surveys from './modules/surveys'
import tickets from './modules/tickets'
import schedules from './modules/schedules'
import candidates from './modules/recruitment/candidates'
import purchaseOrders from './modules/recruitment/purchase-orders'
import trainings from './modules/recruitment/trainings'
import attendance from './modules/recruitment/attendance'
import imss from './modules/recruitment/imss'
import notifications from './modules/notifications'
import candidatePayroll from './modules/recruitment/payroll'
import incidences from './modules/incidences'
import formBuilder from './modules/formBuilder'
import campaignStore from './modules/campaignStores'
import OpenPositions from './modules/OpenPositions'
import HoursManagement from './modules/hours-management/EmployeeHoursManagement'
import NewsFeed from './modules/newsFeed'
import rsa from './modules/rsa'
import reports from './modules/reports'
import RolesManagement from './modules/roles'
import inventory from './modules/inventory'
import resources from './modules/resources'
import indicators from './modules/dashboard/indicators'
import platform from './modules/dashboard/platform'
import messages from './modules/messages'
import teams from './modules/teams'
import msl from './modules/msl'
import SurveysV2 from './modules/surveysv2'
import documents from './files/employee_documents'
import Terminations from './modules/terminations'
import Items from './modules/items'
import incidentManager from './modules/incidentManager'

// Stores for Recruitment/Training
import activities from './recruitment/training/activities'
import attrition from './recruitment/training/attrition'
import details from './recruitment/training/details'
import staff from './recruitment/training/staff'
import attritionRisk from './recruitment/AttritionRisk'
import headcount from './modules/headcount'

Vue.use(Vuex, axios)
const store = new Vuex.Store({
    modules: {
        incidentManager,
        states,
        employees,
        session,
        definitions,
        cities,
        mail,
        clocker,
        clockerUsers,
        feedbacks,
        clockerUtility,
        AccountManagerClocker,
        fileState,
        payroll,
        surveys,
        tickets,
        schedules,
        candidates,
        purchaseOrders,
        trainings,
        attendance,
        imss,
        notifications,
        candidatePayroll,
        incidences,
        formBuilder,
        campaignStore,
        OpenPositions,
        HoursManagement,
        NewsFeed,
        rsa,
        reports,
        RolesManagement,
        inventory,
        resources,
        indicators,
        messages,
        platform,
        teams,
        msl,
        SurveysV2,
        documents,
        Terminations,
        Items,
        headcount,
        // Recruitment/Training modules:
        activities,
        attrition,
        details,
        staff,
        attritionRisk
    }
});

if (module.hot) {
    module.hot.accept([
        './modules/employees',
        './modules/states',
        './modules/session',
        './modules/definitions',
        './modules/cities',
        './modules/mail',
        './modules/exams/clocker',
        './modules/clocker',
        './modules/feedbacks',
        './modules/clockerUtility',
        './modules/AccountManagerClocker',
        './modules/files',
        './modules/payroll',
        './modules/surveys',
        './modules/tickets',
        './modules/schedules',
        './modules/recruitment/candidates',
        './modules/recruitment/purchase-orders',
        './modules/recruitment/trainings',
        './modules/recruitment/attendance',
        './modules/recruitment/imss',
        './modules/notifications',
        './modules/recruitment/payroll',
        './modules/incidences',
        './modules/formBuilder',
        './modules/campaignStores',
        './modules/OpenPositions',
        './modules/hours-management/EmployeeHoursManagement',
        './modules/newsFeed',
        './modules/rsa',
        './modules/reports',
        './modules/roles',
        './modules/inventory',
        './modules/resources',
        './modules/dashboard/indicators',
        './modules/dashboard/platform',
        './modules/messages',
        './modules/teams',
        './modules/msl',
        './modules/surveysv2',
        './files/employee_documents',
        './modules/terminations',
        './modules/items',
        './modules/incidentManager',
        './recruitment/training/activities',
        './recruitment/training/attrition',
        './recruitment/training/details',
        './recruitment/training/staff',
        './recruitment/AttritionRisk',
        './modules/headcount'
    ], () => {
        store.hotUpdate({
            modules: {
                employees: require('./modules/employees').default,
                states: require('./modules/states').default,
                session: require('./modules/session').default,
                definitions: require('./modules/definitions').default,
                cities: require('./modules/cities').default,
                mail: require('./modules/mail').default,
                clocker: require('./modules/exams/clocker').default,
                clockerUsers: require('./modules/clocker').default,
                feedbacks: require('./modules/feedbacks').default,
                clockerUtility: require('./modules/clockerUtility').default,
                AccountManagerClocker: require('./modules/AccountManagerClocker').default,
                fileState: require('./modules/files').default,
                payroll: require('./modules/payroll').default,
                surveys: require('./modules/surveys').default,
                tickets: require('./modules/tickets').default,
                schedules: require('./modules/schedules').default,
                candidates: require('./modules/recruitment/candidates').default,
                purchaseOrders: require('./modules/recruitment/purchase-orders').default,
                trainings: require('./modules/recruitment/trainings').default,
                attendance: require('./modules/recruitment/attendance').default,
                imss: require('./modules/recruitment/imss').default,
                notifications: require('./modules/notifications').default,
                candidatePayroll: require('./modules/recruitment/payroll').default,
                incidences: require('./modules/incidences').default,
                formBuilder: require('./modules/formBuilder').default,
                campaignStore: require('./modules/campaignStores').default,
                OpenPositions: require('./modules/OpenPositions').default,
                HoursManagement: require('./modules/hours-management/EmployeeHoursManagement').default,
                NewsFeed: require('./modules/newsFeed').default,
                rsa: require('./modules/rsa').default,
                reports: require('./modules/reports').default,
                RolesManagement: require('./modules/roles').default,
                inventory: require('./modules/inventory').default,
                resources: require('./modules/resources').default,
                indicators: require('./modules/dashboard/indicators').default,
                platform: require('./modules/dashboard/platform').default,
                messages: require('./modules/messages').default,
                teams: require('./modules/teams').default,
                msl: require('./modules/msl').default,
                SurveysV2: require('./modules/surveysv2').default,
                documents: require('./files/employee_documents').default,
                Terminations: require('./modules/terminations').default,
                Items: require('./modules/items').default,
                incidentManager: require('./modules/incidentManager').default,
                activities: require('./recruitment/training/activities').default,
                attrition: require('./recruitment/training/attrition').default,
                details: require('./recruitment/training/details').default,
                staff: require('./recruitment/training/staff').default,
                attritionRisk: require('./recruitment/AttritionRisk').default,
                headcount: require('./modules/headcount').default
            }
        });
    });
}

export default store;