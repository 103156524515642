<template>
  <div class="flex flex-row space-x-6 mb-3 relative">
    <!-- Cybersecurity Pop-up -->
    <transition name="fade">
      <div v-if="showCyberPopup" class="cyber-popup-overlay">
        <div class="cyber-popup">
          <p>Don't forget your cybersecurity trainings!</p>
          <button class="ack-btn" @click="acknowledgeCyberPopup">OK</button>
        </div>
      </div>
    </transition>

    <!-- Giant Emoji Overlay -->
    <div v-if="activeReaction" class="giant-emoji">{{ activeReaction }}</div>

    <!-- Modal for forced password change -->
    <modal :isVisible="showChangePassword" @close="closeModal" :width="'w-2/5'">
      <template>
        <change-password-modal :profileInfo="infoEmployee"></change-password-modal>
      </template>
    </modal>

    <!-- Dashboard Loading Animation -->
    <div v-if="isDashboardLoading" class="w-full flex items-center justify-center p-10">
      <div class="loading-spinner"></div>
    </div>

    <!-- Main Dashboard Content -->
    <div v-else class="flex flex-row space-x-6 w-full">
      <!-- LEFT COLUMN -->
      <div class="flex-1 space-y-4">
        <!-- Employee Info (Top Bar) -->
        <div class="shaded-card">
          <employee-info-component :infoEmployee="infoEmployee" />
        </div>

        <!-- Row with Three Indicator Boxes -->
        <div class="shaded-card p-4">
          <div class="flex flex-row space-x-4">
            <!-- Anniversary Box -->
            <indicator-component indicatorName="Anniversary" :indicatorValue="hireDay" iconName="bi-calendar-check">
            </indicator-component>
            <!-- Birthday Box -->
            <indicator-component indicatorName="Birthday" :indicatorValue="daysBDLeft" iconName="bi-gift">
            </indicator-component>
            <!-- Tasks Box -->
            <indicator-component indicatorName="Tasks" :indicatorValue="compliancePendingDisplay"
              iconName="bi-clipboard">
            </indicator-component>
          </div>
        </div>

        <!-- Newsfeed (below the indicators) -->
        <div class="shaded-card p-4">
          <newsfeed @reaction="handleReaction" class="overflow-hidden"></newsfeed>
        </div>
      </div>

      <!-- RIGHT COLUMN -->
      <div class="w-1/3 space-y-6">
        <div class="shaded-card p-4">
          <h3 class="text-lg font-semibold mb-2">My Surveys</h3>
          <surveys />
        </div>
        <div class="shaded-card p-4">
          <h3 class="text-lg font-semibold mb-2">My Quizzes</h3>
          <quizzes />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import confetti from "canvas-confetti"; // Ensure canvas-confetti is installed
import modal from "@/views/components/Modal/modalBase.vue";
import ChangePasswordModal from "@/views/components/Modal/changeMyPassword";
import EmployeeInfoComponent from "@/views/dashboard/components/EmployeeInfoComponent";
import IndicatorComponent from "@/views/dashboard/components/indicators/IndicatorComponent";
import NewsFeed from "@/views/newsfeed/index";
import Surveys from "@/views/surveys/availableSurveysComponent";
import Quizzes from "@/views/surveys/availableQuizzesComponent";
import auth from "@/config/user";
import { mapState, mapGetters, mapActions } from "vuex";
import permissions from "@/config/mixins";

export default {
  mixins: [permissions],
  components: {
    modal,
    ChangePasswordModal,
    "employee-info-component": EmployeeInfoComponent,
    "indicator-component": IndicatorComponent,
    newsfeed: NewsFeed,
    surveys: Surveys,
    quizzes: Quizzes,
  },
  data() {
    return {
      daysBDLeft: "",
      hireDay: "",
      showChangePassword: false,
      isDashboardLoading: true, // Dashboard-level loading flag
      activeReaction: "", // Holds the emoji to show as giant overlay
      showCyberPopup: false, // Controls display of the cybersecurity pop-up
    };
  },
  computed: {
    ...mapState(["employees", "indicators"]),
    ...mapGetters(["infoEmployee", "getIndicatorValues", "compliancePending"]),
    compliancePendingDisplay() {
      return this.compliancePending == 0
        ? "You're up to date!"
        : this.compliancePending + " tasks pending";
    },
  },
  methods: {
    ...mapActions([
      "loadMyProfileByEmpId",
      "fetchScheduledTime",
      "fetchWorkedTime",
      "fetchHoursReview",
      "fetchOpenTickets",
      "fetchFollowUpTickets",
      "fetchCoursesPending",
      "fetchIncidencesPending",
      "setCheckPassword",
      "fetchComplianceData",
    ]),
    checkPermission(n) {
      return this.hasPermission(n);
    },
    closeModal() {
      this.$fire({
        type: "error",
        title: "Ups! Required to change your password every 90 days.",
        showConfirmButton: true,
      });
    },
    launchConfetti() {
      confetti({
        particleCount: 120,
        spread: 70,
        origin: { y: 0.6 },
      });
    },
    formatDuration(targetDate, currentDate) {
      const diffDays = targetDate.diff(currentDate, "days");
      if (diffDays < 30) {
        return diffDays + " day(s)";
      } else {
        const diffMonths = targetDate.diff(currentDate, "months");
        const adjusted = currentDate.clone().add(diffMonths, "months");
        const remainingDays = targetDate.diff(adjusted, "days");
        return remainingDays > 0
          ? diffMonths + " month(s) " + remainingDays + " day(s)"
          : diffMonths + " month(s)";
      }
    },
    calculateDates() {
      const currentDate = moment();

      // Birthday calculation
      let birthday = moment(this.infoEmployee.Birthday);
      birthday.year(currentDate.year());
      if (birthday.diff(currentDate, "days") < 0) {
        birthday.add(1, "year");
      }
      this.daysBDLeft = this.formatDuration(birthday, currentDate);

      // Anniversary (HireDate) calculation
      let hireDate = moment(this.infoEmployee.HireDate);
      hireDate.year(currentDate.year());
      if (hireDate.diff(currentDate, "days") < 0) {
        hireDate.add(1, "year");
      }
      this.hireDay = this.formatDuration(hireDate, currentDate);
    },
    handleReaction(reaction) {
      // Set the active reaction, then clear it after 2 seconds.
      this.activeReaction = reaction;
      setTimeout(() => {
        this.activeReaction = "";
      }, 2000);
    },
    checkCyberPopup() {
      // Get the last month when the popup was acknowledged.
      const lastShownMonth = localStorage.getItem("lastCyberPopupMonth");
      const currentMonth = moment().format("YYYY-MM");
      // Only show if we're within the first or last 5 days and it hasn't been shown this month.
      const currentDay = moment().date();
      const daysInMonth = moment().daysInMonth();
      if ((currentDay <= 5 || currentDay > daysInMonth - 5) && lastShownMonth !== currentMonth && !this.checkPermission("GeneralPermissions")) {
        this.showCyberPopup = true;
      }
    },
    acknowledgeCyberPopup() {
      // When the user acknowledges, hide the popup and store the current month.
      this.showCyberPopup = false;
      const currentMonth = moment().format("YYYY-MM");
      localStorage.setItem("lastCyberPopupMonth", currentMonth);
    },
  },
  created() {
    this.setCheckPassword({ employeeID: auth.currentUser.EmpID }).then((response) => {
      if (response.data.error) {
        this.showChangePassword = true;
      }
    });

    this.loadMyProfileByEmpId(auth.currentUser.EmpID).then(() => {
      let employee = {
        campaign: this.infoEmployee.Campaign,
        EmpID: this.infoEmployee.EmpID,
      };

      if (!this.checkPermission("GeneralPermissions")) {
        this.fetchOpenTickets(employee);
        this.fetchFollowUpTickets(employee);
        this.fetchCoursesPending(employee);
      }
      this.fetchScheduledTime(employee);
      this.fetchWorkedTime(employee);
      this.fetchHoursReview(employee);

      this.calculateDates();
      this.isDashboardLoading = false;
      this.checkCyberPopup(); // Check if we should show the cybersecurity popup
    });

    this.fetchComplianceData(auth.currentUser.EmpID);
  },
  mounted() {
    this.$emit("set-title", "");
  },
};
</script>

<style scoped>
/* Shaded Card for 3D texture */
.shaded-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Loading Spinner */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Giant Emoji Overlay */
.giant-emoji {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 5rem;
  animation: pop 0.5s forwards;
  pointer-events: none;
  z-index: 9999;
}

@keyframes pop {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

/* Cybersecurity Popup */
.cyber-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  /* Make sure it sits on top */
}

.cyber-popup {
  background: #ffdddd;
  color: #900;
  border: 2px solid #f00;
  padding: 2rem 3rem;
  border-radius: 8px;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.ack-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  background-color: #f00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ack-btn:hover {
  background-color: #d00;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>