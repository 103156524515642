<template>
    <div class="main my-4">
            <!-- Loading view -->
            <div v-if="displayLoading" class="row justify-content-center"> 
                <div>
                    <p class="font-italic font-weight-bold blink">"We're loading the detailed day information, be patient..."</p>
                    <b-progress :max="maxProgressValue" :value="progressValue" :animated="true" variant="primary" :striped="true"></b-progress>
                </div>
            </div>
            <!-- Information view -->
            <div v-else>
                <!-- If searchable -->
                <div v-if="getDayData.searchable" class="flex flex-col">
                    <!-- If day is closed -->
                    <div v-if="getMessage !== ''" class="flex text-center justify-content-center m-auto mr-0">
                        <div class="bg-gradient-to-r from-red-300 bg-red-200 text-xs max-w-xl rounded-2xl" :class="{ 'p-2' : getMessage !== ''}">
                            {{getMessage}}
                        </div>
                    </div>
                    <div class="relative mx-auto max-w-5xl flex flex-row py-3">
                        <div class="flex flex-row items-center p-2 pl-0 text-gray-500 group" title="location">
                            <span class="font-semibold text-md">Date:</span>
                            <span class="text-gray-400 text-md ml-2">{{date}}</span>
                        </div>
                             
                        <div class="flex flex-row items-center p-2 pl-0 text-gray-500 group" title="location">
                            <span class="font-semibold text-md">#ID:</span>
                            <span class="text-gray-400 text-md ml-2">{{getDayData.approvalId}}</span>
                        </div>

                        <div class="flex flex-row items-center p-2 pl-0 text-gray-500 group" title="location">
                            <span class="font-semibold text-md mr-1">Status:</span>
                            <div>
                                <span v-if="isEmployee" class="uppercase inline-block px-3 py-1 font-semibold text-sm leading-tight inset-0 bg-opacity-50 rounded-full"
                                :class="{'text-green-900 bg-green-200' :getDayData.approvalEmployee == 'Verified',
                                'text-red-900 bg-red-200' :getDayData.approvalEmployee != 'Verified'}">
                                    {{getDayData.approvalEmployee}}
                                </span>

                                <span v-else class="uppercase inline-block px-3 py-1 font-semibold text-sm leading-tight inset-0 bg-opacity-50 rounded-full"
                                :class="{'text-green-900 bg-green-200' : getDayData.approvalStatus == 'approved',
                                'text-red-900 bg-red-200' : getDayData.approvalStatus != 'approved'}">
                                    {{getDayData.approvalStatus}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Table of Detailed Days -->
                    <div class="mx-auto w-10/12  bg-white shadow-sm  rounded-md overflow-hidden mb-5">
                        <div class="relative flex flex-row items-center justify-start px-1 py-3">
                            <vs-tooltip  text="Go back" class="flex text-center">
                                <button class="px-3 text-gray-300 hover:text-green-300 border-r-2 border-gray-200 mr-3" @click="goBack()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </vs-tooltip>
                            <div class="relative w-12 h-12 bg-green-300 rounded-full border-4 border-white shadow-sm">
                                <avatar :employeeId="employeeId" class="w-10 h-10 text-2xl"/>
                            </div>
                            <!-- Personal Information -->
                            <div class="flex flex-row items-center p-2 pl-0 text-gray-500 group cursor-pointer" title="location">
                                <span class="font-semibold text-sm">Employee:</span>
                                <span class="text-gray-400 text-xs ml-2">{{employeeId}}</span>
                            </div>
                            <div class="flex flex-row items-center p-2 ml-2 text-gray-500 group cursor-pointer" title="location">
                                <span class="font-semibold text-sm">Location:</span>
                                <span class="text-gray-400 text-xs ml-2">{{getPayrollAgentInfo.Location}}</span>
                            </div>
                            <div class="flex flex-row items-center p-2 ml-2 text-gray-500 group cursor-pointer" title="location">
                                <span class="font-semibold text-sm">Campaign:</span>
                                <span class="text-gray-400 text-xs ml-2">{{getPayrollAgentInfo.Campaign}}</span>
                            </div>
                            <div class="flex flex-row items-center p-2 ml-2 text-gray-500 group cursor-pointer" title="location">
                                <span class="font-semibold text-sm">LOB:</span>
                                <span class="text-gray-400 text-xs ml-2">{{getPayrollAgentInfo.Lob}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- Tables of events and requests -->
                    <div class="mx-auto w-10/12 flex flex-col">
                        <div class="ml-auto flex flex-row border-l-2 border-gray-2 00 pl-3">
                            <!-- Toggle from decimals and time -->                                
                            <div class="w-32">
                                <div text="Toggle to decimals or time format" class="custom-control custom-switch mt-2 mb-2 text-left">
                                    <input v-model="toggle" type="checkbox" class="custom-control-input" id="customSwitch1">
                                    <label v-if="!toggle" class="custom-control-label" for="customSwitch1"><vs-tooltip text="Click to change to time format">Decimal</vs-tooltip></label>
                                    <label v-else class="custom-control-label" for="customSwitch1"><vs-tooltip text="Click to change to decimal format">Time</vs-tooltip></label>
                                </div>
                            </div>
                            <!-- Buttons -->
                            <div v-if="getMessage === ''" class="flex">
                                <div v-if="isEmployee" class="flex" name="approved_by_employee">
                                    <vs-tooltip v-if="isInRevision" text="Can't approve a day that is in revision, try to cancel the current request(s) or wait until completed" class="flex">
                                        <button class="m-auto cursor-default bg-gray-300 text-white p-2 rounded-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path fill-rule="evenodd" d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zm10.857 5.691a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 00-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </vs-tooltip>
                                    <vs-tooltip v-else text="Approve Day" class="flex">
                                        <button class="hover:drop-shadow-md m-auto bg-green-600 text-white p-2 rounded-sm" @click="approveEmployeeDay()">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path fill-rule="evenodd" d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zm10.857 5.691a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 00-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </vs-tooltip>
                                </div>
                                <div v-else class="flex" name="approved_by_supervisor">
                                    <vs-tooltip v-if="isInRevision" text="Can't approve a day that is in revision, try to cancel the current request(s) or wait until completed" class="flex">
                                        <button class="m-auto cursor-default bg-gray-300 text-white p-2 rounded-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path fill-rule="evenodd" d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zm10.857 5.691a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 00-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </vs-tooltip>
                                    <vs-tooltip v-else text="Approve Day" class="flex">
                                        <button class="hover:drop-shadow-md m-auto bg-green-600 text-white p-2 rounded-sm" @click="approveDay()">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path fill-rule="evenodd" d="M3 3.5A1.5 1.5 0 014.5 2h6.879a1.5 1.5 0 011.06.44l4.122 4.12A1.5 1.5 0 0117 7.622V16.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 16.5v-13zm10.857 5.691a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 00-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </vs-tooltip>
                                </div>

                                <vs-tooltip v-if="isInRevision" text="Can't edit if the day is in revision, try to cancel the current request(s) or wait until completed" class="ml-1 flex">
                                    <button class="m-auto cursor-default bg-gray-300 text-white p-2 rounded-sm text-xs flex flex-row">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                                        </svg>
                                    </button>
                                </vs-tooltip>
                                
                                <vs-tooltip v-else text="Edit Day" class="ml-1 flex">
                                    <button class="hover:drop-shadow-md m-auto bg-red-600 p-2 text-white rounded-sm" 
                                    v-b-modal="'sendRequests'">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                                        </svg>
                                    </button>
                                </vs-tooltip>
                            </div>
                            <b-modal ref="editDayModal" :id="'sendRequests'" hide-footer hide-header centered no-close-on-backdrop size="xl">
                                <edit-day @closeModal="closeModal()" :approvalId="getDayData.approvalId" :eventDate="date" :employee="employeeId" :componentPermissions="$route.params.user_type"></edit-day>
                            </b-modal>
                        </div>
                        <!-- Day Events Information -->
                            <my-table :options="getPayrollDetailedDay" :toggle="toggle" :key="requestKey"/>
                        <!-- Requests For this day -->
                        <div v-if="employeeId !== null">
                            <current-requests @rerender="render()" @pending_requests="reviewRequest" :empId="employeeId" :date="date" :approvalId="getDayData.approvalId" :key="requestKey"/>
                        </div>
                    </div>
                </div>
                   
                <!-- If not searchable -->
                <div v-else>
                    <not-found media="error_notification" >
                        <template slot="headline">
                            Invalid Request
                        </template>  
                        <template slot="subline">
                            The information you're trying to search is not currently available. If this is an error please report to a system administrator 
                        </template>  
                    </not-found>
                </div>
            </div>
    </div>
</template>
<script>
import CurrentRequests from '@/views/payroll/tables/AccountManagerAdjustmentRequests'
import MyTable from '../tables/remake/DetailedDay'
import NotFound from '@/views/mockups/IllustrationTemplate'
import EditDay from '@/views/components/Clocker/modals/editDetailedDay'
import auth   from "@/config/user"
import {mapGetters, mapActions } from 'vuex'
import Avatar from '@/views/components/Utilities/avatar'
export default {
    components: {
      CurrentRequests,
      NotFound,
      EditDay,
      MyTable,
      Avatar
    },
    data(){
        return{
        employeeId: null,
        date: null,
        progressValue: 0,
        maxProgressValue: 120,
        displayLoading: true,
        requestKey: 0,
        toggle: true,
        isInRevision: true,
        }
    },
    mounted(){
        this.$emit('set-title', 'Detailed Hours');
        this.employeeId = this.$route.params.emp_id === 'my-profile' ? auth.currentUser.EmpID : this.$route.params.emp_id;
        this.date   = this.$route.params.date;
        this.initLoading();
        this.render();        
    },
    computed:{
    ...mapGetters(['getPayrollAgentInfo', 'getPayrollDetailedDay', 'getDayData', 'getPayrollRequests']),

    isSupervisor(){
        return this.$route.params.user_type === 'supervisor'
    },
    isEmployee(){
        return this.$route.params.user_type === 'employee'
    },
    getMessage: function(){
        var message = '';
        if(this.getDayData.dayStatus === 'Open'){
        message = ''
        }else if(this.getDayData.dayStatus === 'Closed'){
        message = 'This day has been closed, you can no longer request adjustment nor corrections'
        }else if(this.getDayData.dayStatus === 'Approved'){
        message = 'This day has been approved by the Account Manager, you can no longer request adjustment nor corrections'
        }else if(this.getDayData.dayStatus === 'Finished'){
        message = 'This day has been approved and closed, you can no longer request adjustment nor corrections'
        }
        return message;
    }
    },
    methods:{
        ...mapActions(['fetchDetailedDay', 'approveAllDayRequests', 'approveDate']),
        reviewRequest:function(){
            this.isInRevision = this.getPayrollRequests?.length > 0 ? true : false;
        },
        initLoading: function(){
            let vm = this;
            let timer = setInterval(function() {
                vm.progressValue += 3;
                if (vm.progressValue >= 180) clearInterval(timer);
            }, 100);
        },
        render: function(){
            this.displayLoading = true;
            let form = {
                emp_id: this.employeeId,
                date: this.date,
            }
            this.fetchDetailedDay(form).then(()=>{
                this.displayLoading = false;
            }).catch(()=>{
                this.$fire({
                    type: 'warning',
                    text: 'Something went wrong, you will be send back to the previous page',
                    showConfirmButton: false,
                    timer: 7000
                }).then(()=>{
                    this.$router.go(-1)
                })
            })
        },

        approveDay: function(){
            let obj = {
                approvalId: this.getDayData.approvalId,
                newStatus: "approved"
            }

            this.$confirm("You are about to approve day with ID#"+this.getDayData.approvalId, "Do you want to continue?", "question").then((result) => {
                if (result){
                    this.approveAllDayRequests(obj).then(()=>{
                        this.$fire({
                            type: 'success',
                            title: 'All day has been approved',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{ this.$router.go(0); }); 
                    });
                }
            })
        },

        approveEmployeeDay: function(){
            this.$confirm("This will set the day as approved", "Do you agree with the payable time shown in the previous table?", "question").then((result) => {
                if (result){
                    this.approveDate(this.date).then(()=>{
                        this.$fire({
                            type: 'success',
                            title: 'All day has been approved',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(()=>{ this.$router.go(0); }); 
                    });
                }
            })
        },

        closeModal: function(){
            this.$refs['editDayModal'].hide();
            this.render();
            this.requestKey++;
            this.reviewRequest();
        },

        toTime: function(t){
            let sign = Math.sign(t) >= 0 ? '' : '-';
            let time = new Date(Math.abs(t) * 1000).toISOString().substr(11, 5)
            return sign+time;
        },

        toDecimal: function(t){
            let sign = Math.sign(t) >= 0 ? '' : '-';
            let time = Math.abs(t) / 3600;
            return sign+time.toFixed(2);
        },

        goBack: function(){
            this.$router.go(-1);
        }
    },
    created(){
    }
}
</script>
<style scoped>

</style>