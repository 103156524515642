<template>
  <div class="flex flex-col size-full overflow-hidden gap-y-2">
    <div class="h-16" v-if="!disableFilters">
      <filter-bar
          class="bg-[#f8fafc] p-2 rounded-[0.5rem]"
          :value="filters"
          @input="updateFilters"
          :filters="filterComponents"
          :page="page"
          ref="filterBar"
          :reset="true"
      />
    </div>
    <incident-queue
        @change-page="changePage"
        @update="updateInformation"
        :enable-select="filteredByPendingStatus"
    />
  </div>
</template>
<script>
import {format} from 'date-fns';
import {mapActions, mapGetters} from 'vuex';
import FilterBar from "@/views/FilterBar/FilterBar.vue";
import DropdownFilter from "@/views/FilterBar/filters/DropdownFilter.vue";
import IncidentQueue
    from "@/views/employees/IncidentManager/ListView/IncidentTable/IncidentQueue.vue";


export default {
    name: "ListView",
    components: {
        IncidentQueue,
        FilterBar,
    },

    data() {
        return {
            page: 1,
            selectMode: false,
            checkMarkedEntries: [],
            filters: {},
            alreadyCreated: false,
        }
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'paginatedIncidents',
            ],
        ),
        
        filteredByPendingStatus() {
            if (this.filters?.status?.length === 1) {
                const first = this.filters.status[0];
                if (first === 'HR PENDING') {
                    return this.permissions.includes('SystemAdministrator')
                        || this.permissions.includes('HR');
                }
                if (first === 'PENDING') {
                    return true;
                }
            }
            return false;
        },

        disableFilters() {
            return this.permissions.includes('GeneralPermissions');
        },
        
        empIdsFromList() {
            return this.employeeList.map(
                (employee) => {
                    return employee.EmpID;
                },
            );
        },
        filterComponents() {
            return [
                {
                    component: DropdownFilter,
                    props: {
                        placeholder: 'Filter by Employee ID',
                        name: 'emp_id',
                        options: this.empIdsFromList,
                        default: [],
                        filterFunction: this.searchEmployeeLike,
                        class: "flex-1 rounded-lg shadow-sm ",
                        buttonClass: "border-0",
                    }
                },
                {
                    component: DropdownFilter,
                    props: {
                        name: 'campaign',
                        options: this.getAllCampaigns,
                        default: [],
                        placeholder: "Filter by Campaigns",
                        class: "min-w-96 flex-1 rounded-lg shadow-sm border-0",
                        buttonClass: "border-0",
                    },
                },
                {
                    component: DropdownFilter,
                    props: {
                        name: 'status',
                        options: ['APPROVED', 'PENDING', 'REJECTED', 'HR PENDING'],
                        default: [],
                        placeholder: "Filter by Status",
                        class: "min-w-48 flex-1 rounded-lg shadow-sm border-0",
                        buttonClass: "border-0",
                    }
                }
            ]
        },
        ...mapGetters(
            'incidentManager',
            [
                'incidentHistory',
                'incidentTypeList',
                'incidentsIsLoading'
            ]
        ),
        ...mapGetters(
            [
                'getAllCampaigns',
                'employeeList',
            ]
        ),
    },

    watch: {
        getTickets() {
            this.checkMarkedEntries = [];
        },
    },

    methods: {
        format,
        ...mapActions(
            'incidentManager',
            [
                'fetchIncidents',
                'editRequestStatus',
                'fetchIncidentTypes',
            ]
        ),
        ...mapActions([
            'fetchCampaigns',
            'searchEmployee'
        ]),
        
        changePage(page) {
            this.page = page;
            
            if (this.disableFilters) {
                this.filters.page = page;
                this.updateInformation();
            }
        },
        
        searchEmployeeLike(employee = '') {
            const payload = {
                limit: 200,
            }
            if (employee ?? '' !== '') {
                payload.employee = employee;
            }
            
            return this.searchEmployee(payload);
        },
        
        updateFilters(value) {
            this.filters = value;
            this.updateInformation();
        },

        updateInformation() {
            return Promise.all([
                this.fetchIncidentTypes(),
                this.fetchIncidents({...this.filters}),
            ])
        },
    },
    
    activated() {
        if (this.disableFilters) {
            this.filters = {emp_id: [this.emp_id], page: 1};
            this.updateInformation();
        }
    }
}

</script>
<style lang="scss" scoped>
.md-field {
  max-width: 300px;
}

.TableSearch {
  margin: 2em 0 0 0 !important;
}

.material-icons.viewIcon {
  font-size: 2em;
  cursor: pointer;
}

.btn-primary.disabled, .btn-primary:disabled {
  cursor: no-drop;
}
</style>