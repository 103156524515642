<template>
  <div class="w-96 py-2 px-3 rounded-lg bg-gray-50 flex flex-row gap-x-3">
    <button
        class="flex-1 rounded-full py-2"
        :class="{
            'hover:text-white text-[#7293b9] hover:bg-[#7293b9]': value !== 'calendarView',
            'text-white bg-[#5d7a9d]': value === 'calendarView'
            }"
        @click.prevent="changeMode('calendarView')"
        :disabled="incidentsIsLoading"
    >
      Calendar View
    </button>
    <button
        class="flex-1 rounded-full py-2"
        :class="{
            'hover:text-white text-[#7293b9] hover:bg-[#7293b9]': value !== 'listView',
            'text-white bg-[#5d7a9d]': value === 'listView'
            }"
        @click.prevent="changeMode('listView')"
        :disabled="incidentsIsLoading"
    >
      List View
    </button>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: 'ViewSelector',
    
    props: {
        value: {
            required: true,
        }
    },
    
    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'incidentsIsLoading'
            ],
        ),
    },
    
    methods: {
        changeMode(mode) {
            this.$router.replace({query: {mode: mode}})
            this.$emit('input', mode);
        },
    },
    
}
</script>