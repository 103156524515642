<template>
  <div
      class="flex flex-col w-full transition-colors duration-300 bg-gray-50 select-none">
    <div
        class="text-sm font-semibold h-[2.60rem] text-left content-center text-gray-500"
    >
      Request An Incident
    </div>
    <div class="flex flex-col overflow-y-auto rounded-lg size-full">
      <div class="flex flex-col size-full">
        <incident-type-button
            v-for="incidentType in filteredIncidentTypes"
            :key="incidentType.name"
            :incident-type="incidentType"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IncidentTypeButton
    from "@/views/employees/IncidentManager/CalendarView/CalendarSidebar/IncidentTypeMenu/IncidentTypeButton.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: 'IncidentTypeMenu',
    components: {IncidentTypeButton},

    data() {
        return {
        };
    },
    
    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'incidentTypeList',
                'activeIncidentType',
                'selectedEmployees'
            ]
        ),
        
        filteredIncidentTypes() {
            if(this.permissions.includes('GeneralPermissions')) {
                return this.incidentTypeList.filter((x) => {return x.staffOnly === false})
            }
            return this.incidentTypeList;
        }
    },

    methods: {
        
        ...mapMutations(
            'incidentManager',
            [
                'setActiveIncidentType',
            ]
        ),
        ...mapActions(
            'incidentManager',
            [
                'fetchVacationLimits'
            ]
        ),
    },
    
    watch: {
        activeIncidentType() {
            if (this.activeIncidentType?.name?.toLowerCase() === 'vacation'){
                this.fetchVacationLimits(this.selectedEmployees[0]);
            }
        },
    }
}
</script>