<template>
    <div>
        <loading v-if="loading" msg="Loading teams" :active="loading" />
        <div class="relative flex flex-row py-1" :class="stateClass" id="main_wrapper" v-else>
            <button class="px-3 py-2 shadow-md bg-gray-200 rounded-md" @click="openModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                        d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
                Teams
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-3 inline-block animate ease-in-out"
                    :class="open ? 'transform rotate-180' : ''" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                </svg>
            </button>
            <Transition>
                <div class="absolute z-10 bg-white shadow-faded p-2 rounded-sm w-80 -left-40 top-10 text-left"
                    v-if="open">
                    <label for="search" class="text-left text-sm ml-1 font-semibold text-gray-400">
                        Filter by Supervisor
                    </label>
                    <div
                        class="relative flex flex-row items-center justify-start bg-gray-100 border border-gray-200 rounded-md placeholder-gray-700">
                        <input name="search" class="w-full pl-2 py-1 pr-8 bg-transparent" placeholder="Search..."
                            v-model="searchString" @keypress="filterSupervisors" />
                        <button class="absolute right-2 z-20"
                            @click="() => { searchString = ''; this.filterSupervisors() }">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-300 hover:text-red-600"
                                viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <div class="p-2 max-h-44 overflow-y-scroll">
                        <div class="flex flex-row items-center justify-start border-b border-gray-200 px-2 py-2 cursor-pointer group"
                            @click="emitFilter(item)" :title="item.name" v-for="(item, index) in filteredSupervisors"
                            :key="index">
                            <div class="flex-shrink-0">
                                <avatar :employeeId="item.id" class="w-10 h-10 text-2xl" />
                            </div>
                            <span
                                class="truncate ml-2 uppercase text-sm font-semibold text-gray-400 group-hover:text-gray-700 transition ease-in-out"
                                :data-id="item.id">
                                {{ item.name }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-row items-center justify-end mt-2 border-t-2 border-gray-200 px-2 pt-2 ">
                        <button class="bg-red-400 px-3 py-1 text-white hover:bg-red-600 rounded-sm" @click="cancel">
                            Cancel
                        </button>
                    </div>
                </div>
            </Transition>
        </div>
    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'debounce'
import Loading from './Loading'
import Avatar from '@/views/components/Utilities/avatar'
export default {
    components: { Loading, Avatar },
    data() {
        return {
            selectedTeam: 'Kemper_Team0',
            open: false,
            searchString: '',
            filteredSupervisors: [],
            loading: false
        }
    },
    methods: {
        ...mapActions(['fetchSupervisors']),
        openModal: debounce(function () {
            this.open = true;
        }, 300),

        filterSupervisors: debounce(function () {
            if (this.searchString == '') this.filteredSupervisors = this.getSupervisorList;
            this.filteredSupervisors = this.getSupervisorList.filter(x => x.name.toLowerCase().includes(this.searchString.toLowerCase()))
        }, 250),

        emitFilter: debounce(function (supervisor) {
            this.$emit('filter', supervisor);
        }, 500),

        cancel: debounce(function () {
            this.$emit('clear');
        }, 500)
    },
    computed: {
        ...mapGetters(['getSupervisorList']),
        stateClass: function () {
            return this.open ? 'open' : '';
        },
    },
    created() {
        this.loading = true;
        this.fetchSupervisors().then(() => {
            this.filteredSupervisors = this.getSupervisorList;
        }).finally(() => {
            this.loading = false;
        });
    }
}
</script>

<style scoped>
#dropback.open {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.02);

}

.shadow-faded {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>