<template>
  <div class="w-full">
    <div
        class="text-sm font-semibold h-[2.60rem] text-left content-center text-gray-500"
    >
      Create Request For
    </div>
    <filter-bar
        ref="filterBar"
        :filters="filterComponents"
        :reset="false"
        :value="filters"
        class="h-fit w-full"
        @input="updateFilters"
    />
    <incident-type-menu/>
    <incident-information/>
    <upcoming-incidents/>
  </div>
</template>
<script>
import IncidentTypeMenu
    from "@/views/employees/IncidentManager/CalendarView/CalendarSidebar/IncidentTypeMenu/IncidentTypeMenu.vue";
import UpcomingIncidents
    from "@/views/employees/IncidentManager/CalendarView/CalendarSidebar/IncidentInformation/UpcomingIncidents.vue";
import FilterBar from "@/views/FilterBar/FilterBar.vue";
import DropdownFilter from "@/views/FilterBar/filters/DropdownFilter.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import IncidentInformation
    from "@/views/employees/IncidentManager/CalendarView/CalendarSidebar/IncidentInformation/IncidentInfo.vue";

export default {
    components: {
        IncidentInformation,
        FilterBar,
        UpcomingIncidents,
        IncidentTypeMenu
    },

    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            filters: {},
        };
    },

    computed: {
        ...mapGetters([
            'employeeList'
        ]),
        ...mapGetters(
            'incidentManager',
            [
                'activeIncidentType',
                'incidentsIsLoading',
            ]
        ),

        filterComponents() {
            return [
                {
                    component: DropdownFilter,
                    props: {
                        placeholder: 'Filter by Employee ID',
                        name: 'emp_id',
                        options: this.empIdsFromList,
                        default: [this.emp_id],
                        filterFunction: this.searchEmployeeLike,
                        class: 'w-full',
                        disabled: this.disableEmployeeDropdown,
                        multiple: this.activeIncidentType.bulkEdit === true,
                    }
                },
            ]
        },

        disableEmployeeDropdown() {
            return this.permissions.includes('GeneralPermissions');
        },

        empIdsFromList() {
            const empIds = this.employeeList.map(
                (employee) => {
                    return employee.EmpID;
                },
            );
            if (!empIds.includes(this.emp_id)) {
                empIds.push(this.emp_id);
            }
            empIds.sort()
            return empIds;
        },
    },

    methods: {
        ...mapActions([
            'searchEmployee',
        ]),
        ...mapMutations(
            'incidentManager',
            [
                'setSelectedEmployees',
            ]
        ),
        updateFilters(value) {
            this.filters = value;
            this.setSelectedEmployees(value.emp_id);
            this.updateInformation();
        },

        searchEmployeeLike(employee = '') {
            const payload = {
                limit: 200,
            }
            if (employee ?? '' !== '') {
                payload.employee = employee;
            }

            return this.searchEmployee(payload);
        },

        updateInformation() {
            this.$emit("update");
        },
    }
};
</script>