<template>
  <div v-click-outside-modal="closeModal" class="relative">
    <button
        :disabled="disabled"
        class="w-full px-4 py-1.5 border rounded bg-white text-gray-700
        hover:bg-gray-100 focus:outline-none max-h-[38px] overflow-y-auto flex flex-row flex-wrap gap-x-1"
        :class="buttonClass"
        @click="toggleDropdown"
    >
      <span
          v-if="confirmed.length === 0"
          class="block capitalize text-left text-gray-400"
      >
        {{ placeholder }}
      </span>
      <span
          v-for="empID in confirmed"
          :key="`dropdown[${empID}]`"
          class="capitalize text-left"
      >
        <span class="bg-blue-200 px-1 py-0.5 rounded-full">{{ empID }}</span>
      </span>
    </button>
    <div
        v-if="dropdownOpen"
        class="absolute z-10 w-full bg-white rounded shadow-lg px-3 py-2"
    >
      <div class="p-2">
        <input
            v-model="searchQuery"
            class="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Search..."
            type="text"
        />
      </div>
      <ul class="max-h-60 overflow-y-auto border-b">
        <li
            v-for="option in filteredOptions"
            :key="option"
            class="cursor-pointer hover:bg-gray-100 px-4 py-2 flex items-center justify-start gap-x-4"
            @click="toggleSelection(option)"
        >
          <input
              :checked="isSelected(option)" class="pointer-events-none"
              type="checkbox"
          >
          <span class="capitalize">{{ option }}</span>
        </li>
      </ul>
      <div class="px-2 w-full py-1 flex flex-col gap-y-2 my-2">
        <button
            class="w-full py-1 px-2 rounded-lg text-left hover:bg-gray-100 cursor-pointer group"
            @click="toggleFiltered"
        >
        <span
            class="text-sm text-blue-600 group-hover:underline"
        >
          {{ selectButtonLabel }}
        </span>
        </button>
        <div class="flex flex-row gap-x-2 justify-start">
          <button
              class="px-3 py-0.5 bg-blue-600 text-white rounded hover:bg-blue-700
            focus:outline-none w-1/2"
              @click="confirmSelection"
          >
            Ok
          </button>
          <button
              class="hover:underline text-[#7293B9] w-1/2"
              @click="closeModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutsideModal from "@/directives/click-outside-modal";
import debounce from "debounce";

/**
 * DropdownMultiSelect component allows multiple selection with a search filter and dynamic select/unselect all.
 */
export default {
    name: 'EmployeeDropdown',

    directives: {
        ClickOutsideModal
    },

    props: {
        /**
         * Array of options to display.
         * Each option should be an object with at least an `id` and `label`.
         * @type {Array<string>}
         */
        options: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            required: true,
            type: Array,
        },
        placeholder: {
            required: false,
            type: String,
            default: 'Click to open',
        },
        filterFunction: {
            required: false,
        },
        multiple: {
            required:false,
            type: Boolean,
            default: true,
        },
        buttonClass: {
            required:false,
        }
    },
    data() {
        return {
            dropdownOpen: false,
            searchQuery: '',
            selected: [],
        }
    },
    computed: {
        confirmed() {
            return this.value;
        },
        /**
         * Returns options filtered by the search query.
         * @returns {Array<string>}
         */
        filteredOptions() {
            if (!this.searchQuery) return this.options;

            if (this.filterFunction !== undefined) {
                return this.options;
            }

            return this.options.filter(option =>
                option.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        /**
         * Counts how many of the filtered options are selected.
         * @returns {number}
         */
        selectedFilteredCount() {
            return this.filteredOptions.filter(option => this.isSelected(option)).length;
        },
        /**
         * Determines if all filtered options are selected.
         * @returns {boolean}
         */
        allFilteredSelected() {
            return (
                this.filteredOptions.length > 0 &&
                this.selectedFilteredCount === this.filteredOptions.length
            );
        },
        /**
         * Dynamic label for the select/unselect all button, providing feedback on the count.
         * @returns {string}
         */
        selectButtonLabel() {
            if (this.allFilteredSelected) {
                if (this.options.length === this.filteredOptions.length) {
                    return `Unselect All (${this.filteredOptions.length})`;
                } else {
                    return `Unselect (${this.filteredOptions.length})`;
                }
            } else {
                const countToSelect = this.filteredOptions.length - this.selectedFilteredCount;
                if (this.options.length === this.filteredOptions.length) {
                    return `Select All (${countToSelect})`;
                } else {
                    return `Select (${countToSelect})`;
                }
            }
        }
    },
    watch: {
        searchQuery: {
            handler: debounce(function (newVal) {
                    if (this.filterFunction !== undefined) {
                        this.filterFunction(newVal);
                    }
                },
                500
            ),
        },
        multiple(newValue) {
            if (newValue === false) {
                const firstValue = this.selected[0] ?? null;
                this.selected = firstValue === null ? []: [firstValue];
                this.confirmSelection();
            }
        },
    },
    methods: {
        /**
         * Toggles the dropdown's open state.
         */
        toggleDropdown() {
            if (this.filterFunction !== undefined && !this.dropdownOpen) {
                this.filterFunction();
            }
            this.dropdownOpen = !this.dropdownOpen;
            this.selected = JSON.parse(JSON.stringify(this.confirmed));
        },
        /**
         * Adds or removes an option from the selection.
         * @param {{id: any, label: string}} option
         */
        toggleSelection(option) {
            const index = this.selected.findIndex(item => item === option);
            if(this.multiple === false) {
                this.selected = [];
            }
            if (index > -1) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(option);
            }
        },
        /**
         * Checks if an option is currently selected.
         * @param {{id: any, label: string}} option
         * @returns {boolean}
         */
        isSelected(option) {
            return this.selected.some(item => item === option);
        },
        /**
         * Selects all filtered options that are not already selected.
         */
        selectAllFiltered() {
            this.filteredOptions.forEach(option => {
                if (!this.isSelected(option)) {
                    this.selected.push(option);
                }
            });
        },
        unselectAllFiltered() {
            this.selected = this.selected.filter(
                item => !this.filteredOptions.some(option => option === item)
            );
        },
        toggleFiltered() {
            if (this.allFilteredSelected) {
                this.unselectAllFiltered();
            } else {
                this.selectAllFiltered();
            }
        },
        confirmSelection() {
            this.$emit("input", this.selected);
            this.$emit('update');
            this.closeModal();
        },
        closeModal() {
            this.dropdownOpen = false;
        }
    }
}
</script>

<style scoped>
/* Additional styles if needed */
</style>