<template>
    <div class="tasklist-page">
      <h1 class="page-title">Onboarding/Compliance Tasks</h1>
      <p class="description">
        Please complete all tasks as soon as possible. If you have any questions about the status of these tasks, please reach out to your supervisor/trainer or request assistance via Olark chat.
      </p>
  
      <!-- Progress Bar -->
      <div class="progress-bar-container">
        <div class="progress-bar" :style="progressBarStyle"></div>
        <span class="progress-text">{{ compliancePercentage }}%</span>
      </div>
  
      <!-- Task Table -->
      <table class="task-table">
        <thead>
          <tr>
            <th class="center">Task Name</th>
            <th class="center">Status</th>
            <th class="center">Completed On</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, task) in tasks" :key="task">
            <td class="center">{{ formatTaskName(task) }}</td>
            <td class="center">
              <span v-if="isNotCompleted(value)" class="status not-completed" 
                    title="Questions? reach out to our chat (help button bottom right) for help!">
                Not Completed
              </span>
              <span v-else class="status completed" 
                    title="Questions? reach out to our chat (help button bottom right) for help!">
                Completed
              </span>
            </td>
            <td class="center">
              <span v-if="isValidDate(value)">{{ formatDate(value) }}</span>
              <span v-else>—</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import { mapActions } from "vuex";
  import auth from "@/config/user";
  
  export default {
    name: "TaskListMain",
    data() {
      return {
        complianceData: null,
      };
    },
    computed: {
      // Extract tasks from the API response (from the details object)
      tasks() {
        return this.complianceData && this.complianceData.details
          ? this.complianceData.details
          : {};
      },
      // Calculate the compliance percentage (assumes compliance_prcnt is a fraction)
      compliancePercentage() {
        if (
          this.complianceData &&
          this.complianceData.compliance_prcnt != null
        ) {
          return Math.round(this.complianceData.compliance_prcnt * 100);
        }
        return 0;
      },
      // Returns a style object for the progress bar
      progressBarStyle() {
        let gradient;
        if (this.compliancePercentage < 70) {
          gradient = "linear-gradient(90deg, #f44336, #e57373)";
        } else if (this.compliancePercentage < 100) {
          gradient = "linear-gradient(90deg, #ffeb3b, #fbc02d)";
        } else {
          gradient = "linear-gradient(90deg, #4caf50, #81c784)";
        }
        return {
          width: this.compliancePercentage + "%",
          background: gradient,
          boxShadow: "0 2px 4px rgba(0,0,0,0.3)"
        };
      }
    },
    methods: {
      ...mapActions(["fetchComplianceData"]),
      // Map task keys to friendly names
      formatTaskName(taskKey) {
        const mapping = {
          Security2025: "Security 2025",
          EXAM_COMPLETE: "Exam",
          CodeConduct: "Code of Conduct",
          EmpHandbook: "Employee Handbook",
          VaultTraining: "Vault Training",
          TrainingAgreement: "Training Agreement",
          ClockerTraining: "Clocker Training",
          VaultUse: "Password Vault Use"
        };
        return mapping[taskKey] || taskKey;
      },
      // Returns true if the task value is "NOT_COMPLETED"
      isNotCompleted(value) {
        return value === "NOT_COMPLETED";
      },
      // Returns true if the value is a valid ISO date
      isValidDate(value) {
        return moment(value, moment.ISO_8601, true).isValid();
      },
      // Formats a date string to a friendly format
      formatDate(dateValue) {
        return moment(dateValue).format("MMM D, YYYY h:mm A");
      },
      // Load compliance data using the Vuex action
      loadCompliance() {
        this.fetchComplianceData(auth.currentUser.EmpID)
          .then((response) => {
            if (!response.data.error) {
              this.complianceData = response.data;
            } else {
              console.error(
                "Error fetching compliance data:",
                response.data.message
              );
            }
          })
          .catch((error) => {
            console.error("Fetch compliance error:", error);
          });
      }
    },
    created() {
      this.loadCompliance();
    }
  };
  </script>
  
  <style scoped>
  .tasklist-page {
    padding: 2rem;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 2rem auto;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
  }
  
  .page-title {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #777;
  }
  
  /* Progress Bar Styles */
  .progress-bar-container {
    position: relative;
    height: 24px;
    background: #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }
  .progress-bar {
    height: 100%;
    transition: width 0.5s ease-in-out;
  }
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
  
  /* Task Table Styles */
  .task-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
  }
  .task-table th,
  .task-table td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: center;
  }
  .task-table th {
    background: #f5f5f5;
    font-weight: 600;
    color: #333;
  }
  .task-table td {
    font-size: 0.95rem;
    color: #555;
  }
  
  /* Task Status Styles */
  .status {
    font-weight: bold;
  }
  .completed {
    color: #4caf50;
  }
  .not-completed {
    color: #f44336;
  }
  </style>
  