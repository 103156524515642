<template>
    <div>
      <div>
        <h1>New Hire Approval</h1>
        <!-- Filters Section -->
        <div class="filters">
          <label>
            Location:
            <select v-model="filters.location">
              <option value="">All</option>
              <option v-for="loc in uniqueLocations" :key="loc" :value="loc">{{ loc }}</option>
            </select>
          </label>
          <label>
            Campaign:
            <select v-model="filters.campaign">
              <option value="">All</option>
              <option v-for="camp in uniqueCampaigns" :key="camp" :value="camp">{{ camp }}</option>
            </select>
          </label>
          <label>
            LOB:
            <select v-model="filters.lob">
              <option value="">All</option>
              <option v-for="lob in uniqueLobs" :key="lob" :value="lob">{{ lob }}</option>
            </select>
          </label>
          <label>
            Title:
            <input type="text" v-model="filters.title" placeholder="Filter by title" />
          </label>
        </div>
  
        <!-- Loading Indicator -->
        <div v-if="loading" class="loading-container">
          <div class="spinner"></div>
          <p>Loading new hires...</p>
        </div>
  
        <!-- Main Content -->
        <div v-else>
          <div v-if="filteredNewHireList.length === 0">
            <p>No new hires to review!</p>
          </div>
          <div v-else>
            <table id="employeeTable">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" @change="toggleAll" :checked="allSelected" />
                  </th>
                  <th>EmpID</th>
                  <th>Full Name</th>
                  <th>Location</th>
                  <th>Project</th>
                  <th>Title</th>
                  <th>Class</th>
                  <th>Hire Type</th>
                  <th>Source</th>
                  <th>Referral ID</th>
                  <th>Start Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="employee in paginatedNewHireList" :key="employee.EmpID">
                  <td>
                    <input type="checkbox" v-model="selected" :value="employee.EmpID" />
                  </td>
                  <td>{{ employee.EmpID }}</td>
                  <td>{{ employee.fullname }}</td>
                  <td>{{ employee.Location }}</td>
                  <!-- Project Column: two rows for Campaign and LOB -->
                  <td class="project-cell">
                    <div class="campaign">{{ employee.Campaign }}</div>
                    <div class="lob">{{ employee.LOB }}</div>
                  </td>
                  <td>{{ employee.Title }}</td>
                  <td>{{ employee.ClassID }}</td>
                  <!-- TrainingType -->
                  <td v-if="editing === employee.EmpID">
                    <select v-model="editForm.TrainingType">
                      <option value="Attrition">Attrition</option>
                      <option value="Growth">Growth</option>
                    </select>
                  </td>
                  <td v-else>{{ employee.TrainingType }}</td>
                  <!-- Source Dropdown -->
                  <td v-if="editing === employee.EmpID">
                    <select v-model="editForm.SourceID">
                      <option disabled value="">Select a source...</option>
                      <option v-for="source in sortedSources" :key="source.id" :value="source.id">
                        {{ source.label }}
                      </option>
                    </select>
                  </td>
                  <td v-else>{{ employee.Source }}</td>
                  <!-- Referral ID: mini-search-dropdown when source is referral (ID 2) -->
                  <td v-if="editing === employee.EmpID">
                    <div v-if="editForm.SourceID == 2">
                      <mini-search-dropdown :options="getReferrals" :labelKey="'empid'"
                        placeholder="Select referral..." v-model="editForm.Referralid" />
                      <span v-if="referralError" class="error-message">{{ referralError }}</span>
                    </div>
                    <span v-else>-</span>
                  </td>
                  <td v-else>{{ employee.Referralid }}</td>
                  <!-- Formatted Start Date -->
                  <td>{{ formatDate(employee.StartDate) }}</td>
                  <!-- Actions -->
                  <td>
                    <button v-if="editing !== employee.EmpID" @click="startEditing(employee)"
                      class="action-btn edit-btn">
                      Edit
                    </button>
                    <button v-else @click="saveEdits(employee.EmpID)" class="action-btn save-btn">
                      Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
  
            <!-- Pagination Controls -->
            <div class="pagination">
              <button :disabled="currentPage === 1" @click="currentPage--">Previous</button>
              <span>Page {{ currentPage }} of {{ totalPages }}</span>
              <button :disabled="currentPage === totalPages" @click="currentPage++">Next</button>
            </div>
  
            <button id="approveBtn" @click="confirmApproval">Approve Selected</button>
          </div>
        </div>
      </div>
      <!-- Easter Egg Modal -->
      <div v-if="showEasterEgg" class="easter-egg-modal">
        <div class="modal-content">
          <button class="close-btn" @click="closeEasterEgg">×</button>
          <iframe
            src="https://www.retrogames.cc/embed/8134-contra-bootleg-bootleg.html"
            frameborder="0"
            style="width: 100%; height: 80vh;"
          ></iframe>
        </div>
      </div>
    </div>
  </template>
  

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import MiniSearchDropdown from "@/views/components/Utilities/MiniSearchDropdown.vue";

export default {
    components: { MiniSearchDropdown },
    data() {
        return {
            selected: [],
            editing: null,
            loading: true,
            currentPage: 1,
            pageSize: 10,
            editForm: {
                TrainingType: '',
                SourceID: '',
                Referralid: ''
            },
            referralSearch: '',
            referralTimer: null,
            referralError: '',
            filters: {
                location: '',
                campaign: '',
                lob: '',
                title: ''
            },
            konamiCode: ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', 's'],
            keySequence: [],
            showEasterEgg: false
        };
    },
    mounted() {
        window.addEventListener('keyup', this.checkKonami);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.checkKonami);
    },
    computed: {
        ...mapGetters(['getNewHireList', 'getSources', 'getReferrals']),
        // Filter the new hires based on dropdown and text filters.
        filteredNewHireList() {
            return this.getNewHireList.filter(employee => {
                const matchesLocation = this.filters.location
                    ? employee.Location.toLowerCase() === this.filters.location.toLowerCase()
                    : true;
                const matchesCampaign = this.filters.campaign
                    ? employee.Campaign.toLowerCase() === this.filters.campaign.toLowerCase()
                    : true;
                const matchesLob = this.filters.lob
                    ? employee.LOB.toLowerCase() === this.filters.lob.toLowerCase()
                    : true;
                const matchesTitle = this.filters.title
                    ? employee.Title.toLowerCase().includes(this.filters.title.toLowerCase())
                    : true;
                return matchesLocation && matchesCampaign && matchesLob && matchesTitle;
            });
        },
        paginatedNewHireList() {
            const start = (this.currentPage - 1) * this.pageSize;
            return this.filteredNewHireList.slice(start, start + this.pageSize);
        },
        totalPages() {
            return Math.ceil(this.filteredNewHireList.length / this.pageSize);
        },
        allSelected() {
            return this.paginatedNewHireList.length &&
                this.selected.length === this.paginatedNewHireList.length;
        },
        sortedSources() {
            return [...this.getSources].sort((a, b) => a.label.localeCompare(b.label));
        },
        uniqueLocations() {
            const locs = this.getNewHireList.map(e => e.Location);
            return [...new Set(locs)].sort();
        },
        uniqueCampaigns() {
            const camps = this.getNewHireList.map(e => e.Campaign);
            return [...new Set(camps)].sort();
        },
        uniqueLobs() {
            const lobs = this.getNewHireList.map(e => e.LOB);
            return [...new Set(lobs)].sort();
        }
    },
    
    methods: {
        ...mapActions(['fetchNewHireList', 'fetchSources', 'fetchReferrals', 'approveHires', 'saveEdits']),
        formatDate(dateStr) {
            return new Date(dateStr).toLocaleDateString();
        },
        toggleAll(event) {
            if (event.target.checked) {
                this.selected = this.paginatedNewHireList.map(e => e.EmpID);
            } else {
                this.selected = [];
            }
        },
        confirmApproval() {
            if (!this.selected.length) {
                alert('No employees selected.');
                return;
            }
            // Validate each selected employee has TrainingType and SourceID.
            const invalid = this.selected.filter(empid => {
                const employee = this.getNewHireList.find(e => e.EmpID === empid);
                return (!employee.TrainingType || !employee.SourceID);
            });
            if (invalid.length) {
                alert("Please verify your approvals information is complete!");
                return;
            }
            const proceed = confirm(`You are approving: ${this.selected.join(', ')}. Continue?`);
            if (proceed) {
                this.$store.dispatch('approveHires', this.selected)
                    .then(() => {
                        alert('Approved successfully!');
                        this.selected = [];
                        this.fetchNewHireList().then(() => {
                            this.loading = false;
                            this.currentPage = 1;
                        });
                    })
                    .catch(error => {
                        alert('Approval failed!');
                        console.error(error);
                    });
            }
        },
        startEditing(employee) {
            this.editing = employee.EmpID;
            this.editForm = {
                TrainingType: employee.TrainingType,
                SourceID: employee.SourceID,
                Referralid: employee.Referralid
            };
            this.referralError = "";
        },
        async saveEdits(empid) {
            if (this.editForm.SourceID == 2) {
                if (typeof this.editForm.Referralid !== 'object' || !this.editForm.Referralid.empid) {
                    this.referralError = "Please select a valid employee ID from the list.";
                    return;
                }
                const referralObj = this.editForm.Referralid;
                const validReferral = this.getReferrals.some(item =>
                    item.empid && item.empid.toLowerCase() === referralObj.empid.toLowerCase()
                );
                if (!validReferral) {
                    this.referralError = "Please select a valid employee ID from the list.";
                    return;
                }
                this.referralError = "";
            }
            let payload = { ...this.editForm };
            if (this.editForm.SourceID == 2 && typeof payload.Referralid === 'object') {
                payload.Referralid = payload.Referralid.empid;
            }
            try {
                await this.$store.dispatch('saveEdits', { EmpID: empid, ...payload });
                this.editing = null;
            } catch (err) {
                alert('Update failed');
                console.error(err);
            }
        },

        checkKonami(e) {
            // Push the key pressed into our sequence array.
            this.keySequence.push(e.key);
            // Trim the sequence if it gets longer than our code.
            if (this.keySequence.length > this.konamiCode.length) {
                this.keySequence.shift();
            }
            // Check if the sequence matches exactly.
            if (this.keySequence.join('') === this.konamiCode.join('')) {
                this.showEasterEgg = true;
                // Reset the sequence if you want.
                this.keySequence = [];
            }
        },
        closeEasterEgg() {
            this.showEasterEgg = false;
        }

    },
    created() {
        this.fetchNewHireList().then(() => {
            this.loading = false;
        });
        this.$store.dispatch('fetchSources');
        this.$store.dispatch('fetchReferrals');
    }
};

</script>
<style scoped>
:host {
    display: block;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 2rem;
  }
  
  /* Component title */
  h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 500;
  }
  
  /* Filters Section */
  .filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
    flex-wrap: wrap;
  }
  .filters label {
    font-weight: bold;
  }
  .filters input,
  .filters select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Table Styles */
  table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    border-radius: 4px;
    /* overflow: hidden; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  th,
  td {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  th {
    background-color: #007BFF;
    color: #fff;
    font-weight: 500;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Project Column Styling */
  .project-cell {
    display: flex;
    flex-direction: column;
  }
  .project-cell .campaign {
    font-weight: bold;
  }
  .project-cell .lob {
    font-size: 0.875rem;
    color: #555;
  }
  
  /* Action Buttons */
  .action-btn {
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .action-btn:hover {
    transform: scale(1.02);
  }
  .edit-btn {
    background: linear-gradient(45deg, #007BFF, #0056b3);
    color: #fff;
  }
  .save-btn {
    background: linear-gradient(45deg, #28a745, #218838);
    color: #fff;
  }
  
  /* Approve Button */
  #approveBtn {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background: linear-gradient(45deg, #007BFF, #0056b3);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s ease;
    float: right;
  }
  #approveBtn:hover {
    background: linear-gradient(45deg, #0056b3, #007BFF);
  }
  
  /* Error message */
  .error-message {
    color: #e74c3c;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
  }
  
  /* Loading Spinner */
  .loading-container {
    text-align: center;
    margin-top: 2rem;
  }
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ddd;
    border-top: 5px solid #007BFF;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Pagination */
  .pagination {
    text-align: center;
    margin-bottom: 1rem;
  }
  .pagination button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: none;
    background-color: #007BFF;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Easter Egg Modal */
  .easter-egg-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    position: relative;
  }
  .close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
</style>