var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-96 py-2 px-3 rounded-lg bg-gray-50 flex flex-row gap-x-3"},[_c('button',{staticClass:"flex-1 rounded-full py-2",class:{
          'hover:text-white text-[#7293b9] hover:bg-[#7293b9]': _vm.value !== 'calendarView',
          'text-white bg-[#5d7a9d]': _vm.value === 'calendarView'
          },attrs:{"disabled":_vm.incidentsIsLoading},on:{"click":function($event){$event.preventDefault();return _vm.changeMode('calendarView')}}},[_vm._v(" Calendar View ")]),_c('button',{staticClass:"flex-1 rounded-full py-2",class:{
          'hover:text-white text-[#7293b9] hover:bg-[#7293b9]': _vm.value !== 'listView',
          'text-white bg-[#5d7a9d]': _vm.value === 'listView'
          },attrs:{"disabled":_vm.incidentsIsLoading},on:{"click":function($event){$event.preventDefault();return _vm.changeMode('listView')}}},[_vm._v(" List View ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }