<template>
  <div
      class="flex flex-col size-full h-full rounded-lg overflow-hidden border-2 border-[#f8fafc]"
  >
    <incident-table
        :is-loading="incidentsIsLoading"
        :options="paginatedIncidents.data"
        class="size-full bg-white overflow-hidden"
        @update="$emit('update')"
        :enable-select="enableSelect"
    />
    <div class="flex-row items-center text-start border-t-2">
      <TailwindPagination
          :data="paginatedIncidents"
          :keep-length="true"
          :limit="5"
          class="shadow-none m-1"
          @pagination-change-page="changePage"
      />
    </div>
  </div>
</template>
<script>
import IncidentTable
    from "@/views/employees/IncidentManager/ListView/IncidentTable/IncidentTable.vue";
import TailwindPagination
    from "@/views/components/Pagination/TailwindPagination.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'incidentQueue',

    components: {IncidentTable, TailwindPagination},
    
    props: {
        enableSelect: {
            required: false,
            default: false,
            type: Boolean,
        }
    },

    data() {
        return {
        };
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'incidentsIsLoading',
                'paginatedIncidents'
            ]
        ),
    },

    methods: {
        ...mapActions(
            'incidentManager',
            [
                'editRequestStatus',
            ]
        ),
        
        changePage(page) {
            this.page = page;
            this.$emit('change-page', page);
        },
    }

}
</script>