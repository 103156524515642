<template>
  <div v-click-outside-modal="closeDropdown">
    <button class="flex items-center" id="user-menu" aria-haspopup="true"
            @click="toggle">
      <slot name="title">Open</slot>
    </button>

    <transition name="slide-fade">
      <div
          class="origin-top-right absolute right-0 mt-3 rounded shadow-sm text-left bg-white min-w-[250px] px-1 py-3"
          role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
          v-show="open">
        <a href="#"
           class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline"
           role="menuitem" @click.prevent="redirect('myProfile.personal')">
          <i class="bi bi-person mr-3"></i>
          My Profile
        </a>
        <button
            class="flex flex-row space-x-5 items-center w-full px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline"
            role="menuitem" @click.prevent="redirect('emp.workCalendar')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
          </svg>
          <span>Work Calendar</span>
        </button>
        <a href="#"
           class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline"
           role="menuitem" @click.prevent="showChangePasswordModal">
          <i class="bi bi-key mr-3"></i>
          Change Password
        </a>
        <a href="#"
           class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline"
           role="menuitem" @click.prevent="logout">
          <i class="bi bi-box-arrow-right mr-3"></i>
          Sign out
        </a>
      </div>
    </transition>
  </div>
</template>
<script>
import debounce from "debounce";
import {mapState, mapActions, mapGetters} from "vuex";
import ChangePasswordModal from "@/views/components/Modal/changePassword";
import {EventBus} from "@/event-bus";
import ClickOutsideModal from "@/directives/click-outside-modal";

export default {
    directives: {
        clickOutsideModal: ClickOutsideModal
    },
    name: "Dropdown",
    data() {
        return {
            open: false,
            showChangePassword: false,
        };
    },
    computed: {
        ...mapState(["session"]),
        ...mapGetters(["infoEmployee"]),
    },
    methods: {
        ...mapActions(["logoutUser"]),
        toggle() {
            this.open = !this.open;
        },

        closeDropdown() {
            this.open = false;
        },

        redirect: function (route) {
            if (this.$route.name !== route) {
                this.$router.push({name: route});
            }
        },

        deleteSession: function () {
            this.logoutUser().then(() => {
                this.isLoading = false;
                setTimeout(window.location.reload.bind(window.location), 500);
            });
        },

        logout: debounce(async function () {
            this.deleteSession();
        }, 200),

        doSomething: debounce(function () {
            alert("focus lost");
        }, 750),

        showPasswordModal: function () {
            window.scrollTo(0, 0);
            this.showChangePassword = true;
        },

        showChangePasswordModal() {
            EventBus.$emit("open-modal", ChangePasswordModal, {
                profileInfo: this.infoEmployee,
            });
        },

        closeModals: function () {
            this.showChangePassword = false;
        },
    },
};
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all .18s ease-in-out;
}

.slide-fade-leave-active {
    transition: all .18s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-15px);
    opacity: 0;
}
</style>