<template>
  <div class="relative size-full rounded-lg overflow-hidden">
    <div
        :class="{
        'opacity-0': !isActive && !isHovered,
        'opacity-100': isActive || isHovered
        }"
        :style="backgroundStyle"
        class="absolute inset-0 size-full pointer-events-none
        transition-opacity duration-75"
    >
    </div>
    <button
        :style="buttonStyle"
        class="px-2 py-1 text-nowrap w-full rounded-lg truncate
        flex flex-row justify-start items-center space-x-2 relative"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
        @click.prevent="changeActiveIncident(incidentType)"
    >
      <svg
          class="size-6" fill="none" stroke="currentColor"
          stroke-width="1.5" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>
      <span class="capitalize truncate text-clip text-left">
        {{ incidentType.name.toLowerCase() }}
      </span>
    </button>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {

    name: 'IncidentTypeButton',

    props: {
        incidentType: {
            required: true,
            type: Object,
        }
    },

    data() {
        return {
            isHovered: false,
        };
    },

    methods: {
        ...mapMutations(
            'incidentManager',
            [
                'setActiveIncidentType',
            ]
        ),
        
        ...mapActions(
            'incidentManager',
            [
                'fetchVacationLimits'
            ]
        ),
        
        changeActiveIncident(incidentType) {
            this.setActiveIncidentType(incidentType);
        }
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'activeIncidentType',
                'selectedEmployees',
            ]
        ),
        
        isActive() {
            return this.activeIncidentType.name === this.incidentType.name;
        },
        
        backgroundStyle() {
            return {
                background: `linear-gradient(
                90deg, ${this.incidentType.color},
                 ${this.incidentType.toColor}`
            };

        },
        
        buttonStyle() {
            if (!this.isActive && !this.isHovered) {
                return {};
            }
            const style = {
                color: this.incidentType.textColor
            };

            if (this.isActive) {
                style.fontWeight = 'bold';
            }

            return style;
        },
        
        backgroundColor() {
            if (this.isHovered) {
                return `linear-gradient(` +
                    `90deg, ${this.incidentType.color},` +
                    `${this.incidentType.toColor}` +
                    `)`;
            } else {
                return 'transparent';
            }
        },
    },
};
</script>