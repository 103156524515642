<template>
  <div class="flex flex-col size-full select-none">
    <incident-creation-modal
        ref="creationModal"
        :hovered-cells="hoveredCells"
        class="z-20"
        @update="updateCalendar"
        @lock-calendar="lockCalendar"
        @unlock-calendar="unlockCalendar"
    />
    <incident-edit-modal
        ref="editModal"
        :incident="selectedIncident"
        @update="updateCalendar"
        @lock-calendar="lockCalendar"
        @unlock-calendar="unlockCalendar"
    />
    <header class="bg-gray-50">
      <div class="flex flex-row items-center px-2">
        <div class="flex flex-row justify-start space-x-1 w-full relative py-2">
          <button
              class="bg-[#FFFFFF] active:bg-gray-100 rounded-lg shadow-md py-0.5 px-3 text-center content-center"
              @click.prevent="setDate(today)"
          >
            This Month
          </button>
          <button
              class="bg-[#FFFFFF] active:bg-gray-100 rounded-lg shadow-md py-0.5 px-3 text-center content-center"
              @click.prevent="changeMonth(selectedDate.getMonth() - 1)"
          >
            &lt;
          </button>
          <button
              class="bg-[#FFFFFF] active:bg-gray-100 rounded-lg shadow-md py-0.5 px-3 text-center content-center"
              @click.prevent="changeMonth(selectedDate.getMonth() + 1)"
          >
            &gt;
          </button>
          <div class="py-2 text-lg absolute right-1/2 top-0">
            <span>
              {{ selectedDate.toLocaleString(undefined, {month: 'long'}) }}
            </span>
            -
            <span class="font-semibold">
              {{ selectedDate.getFullYear() }}
            </span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-7 text-xs font-semibold">
        <div>Sunday</div>
        <div>Monday</div>
        <div>Tuesday</div>
        <div>Wednesday</div>
        <div>Thursday</div>
        <div>Friday</div>
        <div>Saturday</div>
      </div>
    </header>
    <div
        id="work-calendar-grid"
        class="grow grid grid-rows-6 grid-cols-1 border-gray-100"
    >
      <div
          v-for="( week, index ) in onlyDateGrid"
          :key="`calendar-row-${index}]`"
          class="w-full grid grid-cols-7"
      >
        <div
            v-for="date in week"
            :key="`calendar-cell[${date}]`"
            :ref="`workCalendarGrid[${dateString(date)}]`"
            class="calendar-cell flex flex-col relative"
            @mousedown="onMouseDown($event, date)"
            @mouseenter="hoverCell(date)"
            @mouseleave="unHoverCell"
        >
          <div
              v-if="!isEnabled(date)"
              class="absolute size-[calc(100%+1px)] bg-gray-100 opacity-50 top-0 left-0 z-[2]
              cursor-not-allowed"
          />
          <div
              v-else-if="selectedDate.getMonth() !== date.getMonth()"
              class="absolute size-[calc(100%+1px)] bg-gray-100 opacity-30 top-0 left-0"
          />
          <div
              :class="{'bg-[#C85A5A] font-extrabold text-white z-[3]': date.getTime() === today.getTime()}"
              class="rounded-full text-center content-center size-6 mx-auto my-1"
          >
            {{ date.getDate() }}
          </div>
          <div
              class="size-full relative"
          >
            <div
                v-for="incident in incidentsByDate[date]"
                :key="`${date}-${incident.name}-${incident.empID}`"
                :class="{
                    'pointer-events-none': incident.id === 'new',
                    'cursor-pointer': incident.id !== 'new',
                    'ring-2 ring-red-500': incident.invalid === true,
                }"
                :style="incidentPillStyle(incident)"
                class="z-[1] absolute left-0 top-0 rounded-full h-1/2 max-h-9
                flex flex-col overflow-hidden transition-all duration-150 justify-center"
                @click="openEditModal(incident, $event)"
            >
              <div>
                {{ incident.name }}
                <span v-if="incident.colSpan > 1">
                  ({{ incident.totalSpan }} Days)
                </span>
              </div>
              <div
                  v-if="incident?.status"
                  :class="{
                      'bg-[#F3C87A]': incident.status === 'PENDING',
                      'bg-gray-100': incident.status === 'APPROVED',
                  }"
                  class="px-2.5 content-center flex flex-row items-center text-[#615030]"
              >
                <svg
                    v-if="incident.status === 'APPROVED'"
                    class="size-3" fill="none" stroke="currentColor"
                    stroke-width="1.5" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
                <svg
                    v-if="incident.status === 'PENDING'"
                    class="size-3" fill="none" stroke="currentColor"
                    stroke-width="1.5" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
                <span
                    class="capitalize text-[0.5rem]"
                >
                  {{ incident.status }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import IncidentCreationModal
    from "@/views/employees/IncidentManager/CalendarView/InteractiveCalendar/CalendarModals/IncidentCreationModal.vue";
import {mapGetters} from "vuex";
import {
    addDays,
    differenceInDays,
    isSameDay,
    max,
    min,
    startOfDay,
    startOfMonth,
    subDays,
} from 'date-fns';
import IncidentEditModal
    from "@/views/employees/IncidentManager/CalendarView/InteractiveCalendar/CalendarModals/IncidentEditModal.vue";

export default {
    name: 'WorkCalendar',
    components: {IncidentEditModal, IncidentCreationModal},

    props: {
        disabled: {
            required: false,
            default: false,
        }
    },

    data() {
        return {
            selectedIncident: {},
            isLocked: false,
            selectedDate: new Date(new Date(Date.now()).toDateString()),
            lastClicked: null,
            lastClickedElement: null,
            hoveredCells: [],
            events: {},
        };
    },
    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'activeIncidentType',
                'incidentHistory',
                'incidentTypeList',
                'vacationLimits'
            ]
        ),

        invalidHovered() {
            if (this.activeIncidentType?.name.toLowerCase() !== 'vacation') {
                return [];
            }

            const hoverUsages = this.vacationLimits.reduce(
                (arr, limit) => {
                    arr.push({...limit, hovered: 0});
                    return arr;
                },
                [],
            );

            return this.hoveredCells.reduce(
                (arr, date) => {
                    const valid = hoverUsages.some(
                        (limit) => {
                            const withinDates = limit.date <= date && date <= limit.expirationDate;
                            const withinLimit = (limit.used + limit.hovered) < limit.maxIncidents;

                            if (withinDates && withinLimit) {
                                limit.hovered += 1;
                                return true;
                            }
                            return false;
                        },
                    );

                    if (!valid) {
                        arr.push(date);
                    }

                    return arr;
                },
                [],
            );
        },

        incidentHistoryDates() {
            return this.incidentHistory.reduce(
                (arr, value) => {
                    const dayDifference = differenceInDays(value.to, value.from);
                    for (let i = 0; i <= dayDifference; i++) {
                        arr.push(addDays(value.from, i));
                    }
                    return arr;
                },
                []
            )
        },

        today() {
            return startOfDay(Date.now());
        },

        firstGrid() {
            let firstDate = this.copySelectedDate();
            firstDate.setDate(1);

            return firstDate.getDay();
        },

        firstDate() {
            // Making sure that the first cell always starts on Sunday.
            return subDays(startOfMonth(this.selectedDate), this.firstGrid);
        },

        onlyDateGrid() {
            // The i and j in the arrow functions are just iterators.
            return Array.from(
                {length: 6},
                (_, i) => {
                    return Array.from(
                        {length: 7},
                        (_, j) => {
                            return addDays(this.firstDate, i * 7 + j)
                        },
                    );
                },
            );
        },

        incidentsByDate() {
            const allIncidents = [...this.incidentHistory];

            // Insert hovered cells.
            if (this.hoveredCells.length > 0) {
                allIncidents.push(
                    {
                        id: 'new',
                        name: this.activeIncidentType.name,
                        from: min(this.hoveredCells),
                        to: max(this.hoveredCells),
                    },
                );
            }

            const lastDate = addDays(this.firstDate, 41)

            const scope = allIncidents.filter(
                (incident) => {
                    return incident.to >= this.firstDate
                        && incident.from <= lastDate
                }
            )

            return scope.reduce(
                (obj, incident) => {
                    const startDate = this.firstDate >= incident.from
                        ? this.firstDate : incident.from
                    const endDate = incident.to >= lastDate
                        ? lastDate : incident.to;

                    // Calculate First Row
                    const calendarColSpan = 7;
                    const duration = differenceInDays(endDate, startDate) + 1;
                    const firstRowColSpan = Math.min(duration, calendarColSpan - startDate.getDay());
                    (obj[startDate] ??= []).push(this.packageIncident(incident, firstRowColSpan, duration));

                    // Calculate Subsequent Rows
                    const remainderDuration = duration - firstRowColSpan;

                    if (remainderDuration <= 0) {
                        return obj;
                    }
                    const remainingRows = Math.ceil(remainderDuration / calendarColSpan);

                    for (let i = 0; i < remainingRows; i++) {
                        const currentDate = addDays(
                            startDate, firstRowColSpan + i * calendarColSpan,
                        );
                        const colSpan = Math.min(remainderDuration - i * calendarColSpan, calendarColSpan);
                        (obj[currentDate] ??= []).push(this.packageIncident(incident, colSpan, duration));
                    }
                    return obj;
                },
                {}
            );
        },
    },

    mounted() {
        this.$emit("update");
    },

    methods: {
        incidentPillStyle(incident) {
            const incidentType = this.incidentTypeList.find(
                (x) => {
                    return x.name.toLowerCase() === incident.name.toLowerCase();
                }
            );

            return {
                width: `calc(100% * ${incident.colSpan})`,
                background: `linear-gradient(90deg, ${incidentType?.color}, ${incidentType?.toColor})`,
                color: incidentType?.textColor,
            }
        },

        packageIncident(incident, colSpan, totalSpan) {
            const packaged = {
                colSpan: colSpan, ...incident,
                invalid: false,
                totalSpan: totalSpan
            };
            if (incident.id !== 'new') {
                return packaged
            }

            packaged.invalid = this.invalidHovered.some(
                (date) => {
                    return date >= incident.from && date <= incident.to;
                },
            );

            return packaged;
        },

        updateCalendar() {
            this.$emit("update");
        },

        openEditModal(incident, event) {
            this.selectedIncident = incident;
            this.$refs.editModal.openModal(event);
        },

        isEnabled(date) {
            return date.getTime() > addDays(this.today, this.activeIncidentType.anticipationDays - 1).getTime();
        },

        lockCalendar() {
            this.isLocked = true;
        },

        unlockCalendar() {
            this.isLocked = false;
            this.unHoverCell();
        },

        onMouseDown(event, dateClicked) {
            if (
                this.isLocked
                || !this.isEnabled(dateClicked)
                || this.hoveredCells.length === 0
            ) {
                return;
            }

            this.lastClickedElement = event.target;
            this.lastClicked = dateClicked;

            document.addEventListener('mouseup', this.onMouseUp);
        },

        onMouseUp() {
            this.lockCalendar();
            this.lastClicked = null;
            document.removeEventListener('mouseup', this.onMouseUp);

            if (this.hoveredCells.length < 0) {
                return;
            }

            if (this.invalidHovered.length > 0) {
                this.unlockCalendar();
                this.unHoverCell();
                this.$fire({
                    type: 'error',
                    title: "You Don't Have Enough Vacation Days.",
                    showConfirmButton: true,
                })
                return;
            }

            setTimeout(
                () => {
                    this.$refs.creationModal.openModal(
                        this.$refs[
                            `workCalendarGrid[${this.dateString(this.hoveredCells.slice(-1)[0])}]`
                            ][0]
                    );
                },
                0
            )
        },

        unHoverCell() {
            if (this.lastClicked !== null || this.isLocked) {
                return;
            }
            this.hoveredCells = [];
        },

        hoverCell(hoveredDate) {
            // ??? Should fix this.
            if (
                this.disabled
                || this.isLocked
                || !this.isEnabled(hoveredDate)
                || this.incidentHistoryDates.some(
                    (date) => {
                        return isSameDay(date, hoveredDate);
                    }
                )
            ) {
                return;
            }

            let anchor = this.lastClicked ?? hoveredDate;
            let limit = hoveredDate;

            let startDate = anchor;
            let dayDifference = differenceInDays(max([limit, startDate]), min([startDate, limit]));

            let selected = [];
            const sign = startDate < limit ? 1 : -1;
            for (let i = 0; i <= dayDifference; i++) {
                let dateToAdd = addDays(startOfDay(startDate), i * sign);

                const conflicts = this.incidentHistory.some(
                    incident => {
                        return incident.from.getTime() <= dateToAdd.getTime()
                            && incident.to.getTime() >= dateToAdd.getTime();
                    }
                )

                if (conflicts) {
                    break;
                }

                selected.push(dateToAdd);
            }

            this.hoveredCells = selected;
        },

        copySelectedDate() {
            return startOfDay(this.selectedDate);
        },

        setDate(date) {
            this.selectedDate = startOfDay(date);
        },

        changeMonth(offset) {
            let newDate = this.copySelectedDate();
            newDate.setMonth(offset);
            this.selectedDate = newDate;
        },

        dateString(date) {
            return date.toISOString().split('T')[0];
        },

        isHovered(date) {
            return this.hoveredCells.map(date => {
                return this.dateString(date);
            }).includes(this.dateString(date));
        }
    },
}
</script>
<style scoped>
#work-calendar-grid .calendar-cell:nth-child(1n) {
    border-width: 0 0 1px 1px;
}

#work-calendar-grid .calendar-cell:last-child {
    border-bottom: unset;
    background-color: #F9FAFB;
}

#work-calendar-grid .calendar-cell:nth-child(7n - 6) {
    border-left: unset;
    background-color: #f3f4f6;
}

#work-calendar-grid .calendar-cell:nth-child(7n) {
    background-color: #f3f4f6;
}

#work-calendar-grid .calendar-cell:nth-child(1n + 36) {
    border-bottom: unset;
}
</style>