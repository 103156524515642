import axios from '@/config/axios'

const state = {
    order: {
        label: null,
        site_id: "",
        campaign_id: "",
        lob_id: "",
        heads_required: 0,
        type: "",
        fte_size: 0,
        recruitment_start: null,
        recruitment_deadline: null,
        training_start: null
    },
    defaultOrder: {
        label: null,
        site_id: "",
        campaign_id: "",
        lob_id: "",
        heads_required: 0,
        type: "",
        fte_size: 0,
        recruitment_start: null,
        recruitment_deadline: null,
        training_start: null
    },
    filters: {
        purchase_order: null,
        site_id: ""
    },
    purchaseOrders: [],
    status: 'pending'
};

const getters = {
    getPurchaseOrders: (state) => state.purchaseOrders,
    getFilters: (state) => state.filters,
    getCurrentPurchaseOrder: (state) => state.order,
    getPurchaseStatus: (state) => state.status
};

const actions = {
    async fetchPurchaseOrders({ commit }) {

        const response = await axios.get('api/v2/recruitment/purchase-orders');
        commit('setPurchaseOrders', response.data.list);
    },
    async filterPurchaseOrders({ commit }, filters) {
        const response = await axios.get('api/v2/recruitment/purchase-orders/filter', {
            params:
                filters
        });
        commit('setPurchaseOrders', response.data.list);
        return response;
    },
    async storePurchaseOrder({ commit }, form) {
        const response = await axios.post('api/v2/recruitment/purchase-orders/new', form);
        commit('setCurrentPurchaseOrder', response.data.order);
        return response;
    },
    async getPurchaseOrderById({ commit }, id) {
        commit('setPurchaseStatus', 'loading');
        const response = await axios.get(`api/v2/recruitment/purchase-orders/${id}`);
        commit('setCurrentPurchaseOrder', response.data.order);
        commit('setPurchaseStatus', 'loaded');
        return response;
    },
    async updatePurchaseOrder({ commit }, form) {
        const response = await axios.post(`api/v2/recruitment/purchase-orders/${form.id}/update`, form);
        commit('setCurrentPurchaseOrder', response.data.order);
        return response;
    },
    async fetchPendingOrders({ commit }) {
        const response = await axios.get('api/v2/recruitment/purchase-orders/pending');
        commit('setPurchaseOrders', response.data.list);
        return response;
    }
}

const mutations = {
    setPurchaseOrders: (state, list) => (state.purchaseOrders = list),
    setCurrentPurchaseOrder: (state, order) => (state.order = order),
    SET_SITE_LOCATION_FILTER: (state, location) => (state.filters.site_id = location),
    SET_LABEL_FILTER: (state, label) => (state.filters.purchase_order = label),
    SET_PO_LABEL: (state, label) => (state.order.label = label),
    SET_SITE_LOCATION: (state, location) => (state.order.site_id = location),
    SET_CURRENT_CAMPAIGN: (state, campaign) => (state.order.campaign_id = campaign),
    SET_CURRENT_LOB: (state, lob) => (state.order.lob_id = lob),
    SET_CURRENT_TYPE: (state, type) => (state.order.type = type),
    SET_RECRUITMENT_START: (state, recruitment_start) => (state.order.recruitment_start = recruitment_start),
    SET_RECRUITMENT_DEADLINE: (state, recruitment_deadline) => (state.order.recruitment_deadline = recruitment_deadline),
    SET_TRAINING_DATE: (state, training_start) => (state.order.training_start = training_start),
    SET_REQUIRED_AGENTS: (state, agents) => (state.order.heads_required = agents),
    SET_WEEKLY_HOURS: (state, hours) => (state.order.fte_size = hours),
    RESET_STATE: (state) => (state.order = state.defaultOrder),
    setPurchaseStatus: (state, status) => (state.status = status)
};

export default {
    state,
    getters,
    actions,
    mutations
};