<template>
  <div class="absolute inset-0 flex flex-row" id="container">
    <div class="basis-full md:basis-6/12 lg:basis-4/12 xl:basis-3/12 relative h-full bg-white shadow p-16 grid grid-cols-1 place-content-center z-50">
      <div>
        <div class="text-center mb-16">
          <img src="../../../assets/centris-logo.png" class="brand_logo mx-auto" alt="Logo">
        </div>
        <div>
          <h4 class="mb-0">Welcome to EMS!</h4>
          <p>Sign in to continue.</p>
        </div>
        <loading :active.sync="isLoading" :background-color="bgcolor" :color="color" :is-full-page="fullPage"></loading>
        <form @submit.prevent="submit">
          <div class="border" style="margin-bottom: -1px;">
            <input type="text" v-model="EmpID" autocomplete="off" class="w-full px-3 py-2 input_user" placeholder="username">
          </div>
          <div class="border">
            <input type="password" v-model="password" autocomplete="off" class="w-full px-3 py-2 input_pass" placeholder="password">
          </div>

          <div class="text-xs font-medium text-red-900 p-2 bg-red-200 rounded-md mt-4 flex flex-row" :hidden="error===''">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
              </svg>
            </span>
            <span class="flex-1 text-left">
              {{ error }}
            </span>
          </div>

          <div class="d-flex justify-content-center mt-3 login_container">
            <button type="submit" class="bg-c-primary px-4 py-2 rounded text-white login_btn">Login</button>
          </div>
        </form>
        <p class="text-gray versioning mt-16"><small class="vernumber">v{{ version }}</small></p>
      </div>
    </div>
    <div class="basis-0 md:basis-6/12 lg:basis-8/12 xl:basis-9/12 relative h-full">
      <div class="absolute top-1/3 bottom-0 left-0 right-0 z-40">
        <h2 class="font-black text-4xl lg:text-6xl text-center text-white drop-shadow-xl w-2/3 mx-auto">
          {{ chosenQuote.text }}<br/>
          <small class="text-2xl">{{ chosenQuote.author }}</small>
        </h2>
      </div>
      <div class="absolute top-0 bottom-0 left-0 right-0 z-30">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <!--<img src="img/undraw_add_notes_re_ln36.svg" alt="" class="absolute bottom-0 right-1/3 z-40">-->
      <div class="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-c-blue/30 to-blue-500/80 z-20"></div>
      <div class="absolute top-0 bottom-0 left-0 right-0 bg-cover bg-center bg-login z-10"></div>
    </div>
  </div>
</template>

<script>
  import { ClientJS } from 'clientjs';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import auth from "@/config/user";
  
  export default {
    name: 'Login',
    data(){
      return {
        EmpID: '',
        password: '',
        fullPage: true,
        isLoading: false,
        color: "white",
        bgcolor: "#000000",
        version: 2.11,
        error: '',
        // A large array of positive, self-improvement quotes.
        quotes: [
  { text: "Well done is better than well said.", author: "Benjamin Franklin" },
  { text: "Faith is taking the first step even when you don't see the whole staircase.", author: "Martin Luther King, Jr." },
  { text: "Not all of us can do great things. But we can do small things with great love.", author: "Mother Teresa" },
  { text: "If you're going to try, go all the way.", author: "Charles Bukowski" },
  { text: "Do not go where the path may lead, go instead where there is no path and leave a trail.", author: "Ralph Waldo Emerson" },
  { text: "Success is not final, failure is not fatal: it is the courage to continue that counts.", author: "Winston Churchill" },
  { text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
  { text: "It always seems impossible until it's done.", author: "Nelson Mandela" },
  { text: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt" },
  { text: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
  { text: "Your time is limited, don't waste it living someone else's life.", author: "Steve Jobs" },
  { text: "The only limit to our realization of tomorrow will be our doubts of today.", author: "Franklin D. Roosevelt" },
  { text: "You miss 100% of the shots you don't take.", author: "Wayne Gretzky" },
  { text: "Hardships often prepare ordinary people for an extraordinary destiny.", author: "C.S. Lewis" },
  { text: "Believe in yourself and all that you are.", author: "Christian D. Larson" },
  { text: "Everything you can imagine is real.", author: "Pablo Picasso" },
  { text: "What you get by achieving your goals is not as important as what you become by achieving your goals.", author: "Zig Ziglar" },
  { text: "Dream big and dare to fail.", author: "Norman Vaughan" },
  { text: "Keep your face always toward the sunshine—and shadows will fall behind you.", author: "Walt Whitman" },
  { text: "Success is the sum of small efforts, repeated day in and day out.", author: "Robert Collier" },
  { text: "Opportunities don't happen, you create them.", author: "Chris Grosser" },
  { text: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
  { text: "You are never too old to set another goal or to dream a new dream.", author: "C.S. Lewis" },
  { text: "It does not matter how slowly you go as long as you do not stop.", author: "Confucius" },
  { text: "Act as if what you do makes a difference. It does.", author: "William James" },
  { text: "Change your thoughts and you change your world.", author: "Norman Vincent Peale" },
  { text: "Don't wait. The time will never be just right.", author: "Napoleon Hill" },
  { text: "Everything has beauty, but not everyone can see.", author: "Confucius" },
  { text: "Start where you are. Use what you have. Do what you can.", author: "Arthur Ashe" },
  { text: "The secret of getting ahead is getting started.", author: "Mark Twain" },
  { text: "All our dreams can come true, if we have the courage to pursue them.", author: "Walt Disney" },
  { text: "You don't have to be great to start, but you have to start to be great.", author: "Zig Ziglar" },
  { text: "Every moment is a fresh beginning.", author: "T.S. Eliot" },
  { text: "Believe in the power of positive thinking.", author: "Louise Hay" },
  { text: "Turn your wounds into wisdom.", author: "Oprah Winfrey" },
  { text: "Happiness is not something ready made. It comes from your own actions.", author: "Dalai Lama" },
  { text: "Life is 10% what happens to us and 90% how we react to it.", author: "Charles R. Swindoll" },
  { text: "Keep your eyes on the stars, and your feet on the ground.", author: "Theodore Roosevelt" },
  { text: "In the middle of every difficulty lies opportunity.", author: "Albert Einstein" },
  { text: "Your life does not get better by chance, it gets better by change.", author: "Jim Rohn" },
  { text: "With the new day comes new strength and new thoughts.", author: "Eleanor Roosevelt" },
  { text: "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.", author: "Roy T. Bennett" },
  { text: "Every day may not be good... but there's something good in every day.", author: "Alice Morse Earle" },
  { text: "You are braver than you believe, stronger than you seem, and smarter than you think.", author: "A.A. Milne" },
  { text: "What lies behind us and what lies before us are tiny matters compared to what lies within us.", author: "Ralph Waldo Emerson" },
  { text: "The power of imagination makes us infinite.", author: "John Muir" },
  { text: "Act as if it were impossible to fail.", author: "Dorothea Brande" },
  { text: "Strive not to be a success, but rather to be of value.", author: "Albert Einstein" },
  { text: "You are capable of amazing things.", author: "Helen Keller" },
  { text: "Embrace the glorious mess that you are.", author: "Elizabeth Gilbert" },
  { text: "Don’t stop when you’re tired. Stop when you’re done.", author: "Les Brown" },
  { text: "Don't let yesterday take up too much of today.", author: "Will Rogers" },
  { text: "The best revenge is massive success.", author: "Frank Sinatra" },
  { text: "Dream big, work hard, stay focused.", author: "Tony Robbins" },
  { text: "Don't count the days, make the days count.", author: "Muhammad Ali" },
  { text: "Don't stop until you're proud.", author: "Eric Thomas" },
  { text: "Little by little, a little becomes a lot.", author: "Tanzanian Proverb" },
  { text: "Do something today that your future self will thank you for.", author: "Mark Victor Hansen" },
  { text: "Sometimes later becomes never. Do it now.", author: "George Bernard Shaw" },
  { text: "Great things never come from comfort zones.", author: "Robin Sharma" },
  { text: "Dream it. Wish it. Do it.", author: "Dr. Seuss" },
  { text: "Success doesn't just find you. You have to go out and get it.", author: "Henry Ford" },
  { text: "The key to success is to focus on goals, not obstacles.", author: "Brian Tracy" },
  { text: "Every accomplishment starts with the decision to try.", author: "John F. Kennedy" },
  { text: "Success is not how high you have climbed, but how you make a positive difference to the world.", author: "Roy T. Bennett" },
  { text: "The best time to plant a tree was 20 years ago. The second best time is now.", author: "Chinese Proverb" },
  { text: "Don't wait for opportunity. Create it.", author: "Robert Kiyosaki" },
  { text: "Small steps in the right direction can turn out to be the biggest step of your life.", author: "Steve Maraboli" },
  { text: "Believe in yourself, take on your challenges, dig deep within yourself to conquer fears.", author: "Chantal Sutherland" },
  { text: "The future depends on what you do today.", author: "Mahatma Gandhi" },
  { text: "Everything you need to accomplish your goals is already in you.", author: "Paulo Coelho" },
  { text: "Push yourself, because no one else is going to do it for you.", author: "Arnold Schwarzenegger" },
  { text: "Doubt kills more dreams than failure ever will.", author: "Suzy Kassem" },
  { text: "It’s not whether you get knocked down, it’s whether you get up.", author: "Vince Lombardi" },
  { text: "Failure is not the opposite of success; it's part of success.", author: "Arianna Huffington" },
  { text: "Success usually comes to those who are too busy to be looking for it.", author: "Henry David Thoreau" },
  { text: "Don't be afraid to give up the good to go for the great.", author: "John D. Rockefeller" },
  { text: "I find that the harder I work, the more luck I seem to have.", author: "Thomas Jefferson" },
  { text: "Don't wish it were easier; wish you were better.", author: "Jim Rohn" },
  { text: "Make each day your masterpiece.", author: "John Wooden" },
  { text: "Your only limit is you.", author: "Michael Jordan" },
  { text: "Keep going. Everything you need will come to you at the perfect time.", author: "Lao Tzu" },
  { text: "Your journey is your strength.", author: "Rumi" },
  { text: "We hope your day is filled with smiles, sunshines, puppies and kittens!", author: "Centris" },
  { text: "We believe in you. Every challenge is a stepping stone towards your success.", author: "Centris" },
  { text: "If you want to change the world, start off by making your bed.", author: "William H. McRaven"}          
        ],
        chosenQuote: {}
      }
    },
    computed: {
      ...mapState(['session']),
      ...mapGetters(['getAuthObject','getIP']),
      employeeIsActive() {
        return auth.currentUser.status.toLowerCase() === 'active';
      },
      employeeIsPredismissal() {
        return auth.currentUser.status.toLowerCase() === 'predismissal';
      }
    },
    components: {
      Loading
    },
    methods: {
      ...mapActions(['loginUser', 'getIPFromClient']),
      async submit() {
        this.isLoading = true;
        const client = new ClientJS();
        try {
          const empObject = {
            EmpID: this.EmpID.toUpperCase(),
            password: this.password,
            ClientIP: this.getIP,
            ActionTriggered: 'Login',
            UserAgent: client.getUserAgent(),
            DeviceOS: client.getOS(),
            DeviceFingerprint: client.getFingerprint(),
          };
          this.loginUser(empObject).then(() => {
            this.isLoading = false;
            this.error = '';
            this.$emit('loadCredentials', true);
          }).catch(error => {
            this.isLoading = false;
            this.error = error.response.data.message;
          });
        } catch (error) {
          this.isLoading = false;
          this.$fire({
            type: 'error',
            title: 'Sorry, wrong username or password',
            showConfirmButton: true
          });
        }
      },
      reviewLogin() {
        const hasToken = auth.currentUser.token ? true : false;
        if (hasToken && this.employeeIsActive) {
          this.$router.push('/dashboard');
        } else if (hasToken && this.employeeIsPredismissal) {
          this.$router.push('/employee/predismissal');
        }
      }
    },
    mounted(){
      this.reviewLogin();
      this.getIPFromClient();
      this.version = window.version;
      // Randomly select a quote when the component mounts
      this.chosenQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
    }
  }
</script>

<style scoped>
  .brand_logo {
    max-height: 55px;
  }
  .bg-login {
    background-image: url('../../../assets/background_2.jpg');
  }
  /* Animation */
  .circles {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
  }
  .circles li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animate 25s linear infinite;
      bottom: -150px;
  }
  .circles li:nth-child(1) {
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
  }
  .circles li:nth-child(2) {
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
  }
  .circles li:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
  }
  .circles li:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
  }
  .circles li:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
  }
  .circles li:nth-child(6) {
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
  }
  .circles li:nth-child(7) {
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
  }
  .circles li:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
  }
  .circles li:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
  }
  .circles li:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
  }
  @keyframes animate {
      0% {
          transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
      }
      100% {
          transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
      }
  }
</style>