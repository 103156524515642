<template>
  <div class="bg-white rounded-custom shadow-sm overflow-hidden">
    <transition name="fade">
      <div v-if="!isLoading">
        <div class="bg-centris-secondary text-right text-white leading-none px-10 pt-6 pb-4">
          <!-- Welcome message removed for a cleaner, more inviting look -->
          <img
            class="float-left h-24 w-24 -mt-4 border shadow border-white rounded-full"
            src="https://placecats.com/bella/300/300"
            alt="Profile Picture"
          />
          <h5 class="text-[120%] whitespace-nowrap text-ellipsis overflow-hidden mb-0">
            {{ fullName }}
          </h5>
          <p class="text-[110%] mb-0"><i>{{ infoEmployee.Title }}</i></p>
        </div>
        <div class="px-10 pt-8 pb-6 text-left leading-none">
          <div class="grid grid-cols-6 gap-4">
            <div>
              <h6 class="mb-0">{{ formattedTenure }}</h6>
              <small class="text-gray-400">Tenure</small>
            </div>
            <div>
              <h6 class="mb-0">{{ infoEmployee.Campaign + '-' + infoEmployee.LOB }}</h6>
              <small class="text-gray-400">Project</small>
            </div>
            <div>
              <h6 class="mb-0">{{ formattedHireDate }}</h6>
              <small class="text-gray-400">Hire Date</small>
            </div>
            <div>
              <h6 class="mb-0">{{ formattedCertificationDate }}</h6>
              <small class="text-gray-400">Certification Date</small>
            </div>
            <div>
              <h6 class="mb-0">{{ infoEmployee.Title }}</h6>
              <small class="text-gray-400">Title</small>
            </div>
            <div>
              <h6 class="mb-0">{{ infoEmployee.ReportTo }}</h6>
              <small class="text-gray-400">Supervisor</small>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="isLoading" class="flex items-center justify-center p-10">
        <div class="loading-spinner"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import moment from "moment";
import auth from "@/config/user";

export default {
  name: "EmployeeInfoComponent",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["employees"]),
    ...mapGetters(["infoEmployee"]),
    fullName() {
      // Concatenate available name parts
      const parts = [
        this.infoEmployee.FirstName,
        this.infoEmployee.MiddleName,
        this.infoEmployee.LastName1,
        this.infoEmployee.LastName2,
      ];
      return parts.filter(Boolean).join(" ");
    },
    formattedTenure() {
      // Compute tenure as "Xy Ym Zd" based on HireDate
      if (!this.infoEmployee.HireDate) return "N/A";
      let start = moment(this.infoEmployee.HireDate, "YYYY-MM-DD");
      const end = moment();
      const years = end.diff(start, "years");
      start.add(years, "years");
      const months = end.diff(start, "months");
      start.add(months, "months");
      const days = end.diff(start, "days");
      return `${years}y ${months}m ${days}d`;
    },
    formattedHireDate() {
      if (!this.infoEmployee.HireDate) return "N/A";
      return moment(this.infoEmployee.HireDate).format("YYYY-MM-DD");
    },
    formattedCertificationDate() {
      if (!this.infoEmployee.CertificationDate) return "N/A";
      return moment(this.infoEmployee.CertificationDate).format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions(["loadMyProfileByEmpId"]),
  },
  created() {
    this.loadMyProfileByEmpId(auth.currentUser.EmpID)
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Modern, Sleek Styles */
.bg-centris-secondary {
  /* Updated to a red gradient keeping a regal feel */
  background: linear-gradient(135deg, #B91C1C, #F87171);
}
.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.rounded-custom {
  border-radius: 10px;
}
</style>
