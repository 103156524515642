import axios from '@/config/axios'
// import auth from '@/config/user'
import { API_URL } from '../actions/config'
const state = {
    pagination: [],
    employeeList: [],
    loadingFlag: true,
    infoEmployee: [],
    answersEmployee: [],
    promoteSuccessfull: false,
    terminationStatus: false,
    transferStatus: false,
    newEmployeeStatus: 0,
    editSuccessfull: false,
    contactList: [],
    unseenMessages: [],
    messages: [],
    messageStatus: false,
    editableInfo: [],
    passwords: [],
    employeeFieldOptions: [],
    compliancePending: 0,
};
const getters = {
    getEmployeeFieldOptions: (state) => state.employeeFieldOptions.map(x => Object.values(x)[0]),
    employeeList: (state) => state.employeeList,
    getEmployeePagination: (state) => state.pagination,
    loadingFlag: (state) => state.loadingFlag,
    infoEmployee: (state) => state.infoEmployee,
    answersEmployee: (state) => state.answersEmployee,
    getPromoteStatus: (state) => state.promoteSuccessfull,
    getNewEmployeeStatus: (state) => state.newEmployeeStatus,
    contactList: (state) => state.contactList,
    unseenMessages: (state) => state.unseenMessages,
    messages: (state) => state.messages,
    messageStatus: (state) => state.messageStatus,
    getEditableInfo: (state) => state.editableInfo,
    getVault: (state) => state.passwords,
    compliancePending: (state) => state.compliancePending,
};
const actions = {
    async fetchEmployees({ commit }) {
        commit('toggleFlag', true);
        const endpoint = API_URL + 'api/v1/employees';
        await axios.get(endpoint).then((response) => {
            commit('setEmployeeList', response.data.data);
            commit('toggleFlag', false);
        });
    },
    async fetchAllEmployees({ commit }) {
        const endpoint = API_URL + 'api/v1/employees/allEmployees';
        const response = await axios.get(endpoint);
        commit('setEmployeeList', response.data.data);
    },
    async fetchNewHiresNotifications({ commit }) {
        commit('toggleFlag', true);

        const endpoint = API_URL + 'api/v1/mail/newEmployees';
        await axios.get(endpoint).then((response) => {
            commit('setEmployeeList', response.data);
            commit('toggleFlag', false);
        });
    },
    async searchEmployee({ commit }, obj) {
        commit('toggleFlag', true);

        const endpoint = API_URL + `api/v1/employees/search`;
        await axios.post(endpoint, obj, {params: {page: obj.page}}).then((res) => {
            commit('toggleFlag', false);
            commit('setEmployeeList', res.data.data);
            if (Object.keys(obj).includes('page')) {
                commit('setEmployeePagination', { meta: res.data.meta, links: res.data.links });
            }
        });
    },
    async loadMyProfileByEmpId({ commit }, EmpID) {
        const endpoint = API_URL + 'api/v1/employees/getProfileByEmpID';
        const response = await axios.post(endpoint, {
            employeeId: EmpID,
        });
        commit('setInfoEmployee', response.data.fullData);
    },
    async fetchEditableEmployeeInfo({ commit }, EmpID) {
        const endpoint = API_URL + 'api/v1/employees/getEmployeeEditableData';
        const response = await axios.post(endpoint, {
            employeeId: EmpID,
        });
        commit('setEditableInfo', response.data.data);
    },

    async loadMyProfile({ commit }, ID) {

        const endpoint = API_URL + 'api/v1/employees/' + ID;
        const response = await axios.get(endpoint);
        commit('setInfoEmployee', response.data[0]);
    },
    async loadProfileInfo({ commit }, ID) {

        const endpoint = API_URL + 'api/v1/employees/' + ID + '/getProfile';
        const response = await axios.get(endpoint);
        commit('toggleFlag', false);
        commit('setInfoEmployee', response.data);
    },

    async setPromote({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/promote';
        const response = await axios.post(endpoint, data).then((response) => {
            commit('setPromoteStatus', true);
            return response;
        });
        return response;

    },

    async setDemote({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/demote';
        const response = await axios.post(endpoint, data).then((response) => {
            commit('setPromoteStatus', true);
            return response;
        });
        return response;
    },


    async sendPredismissal({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/predismiss';
        let response = await axios.post(endpoint, data).then((res) => {
            commit('setPredismissalStatus', true);
            return res;
        });
        return response;
    },

    async sendTermination({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/terminate';
        const response = await axios.post(endpoint, data);
        if (response.status == 200) {
            commit('setTerminationStatus', true);
            return response.status;
        }
    },
    async sendTransfer({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/transfer';
        const response = await axios.post(endpoint, data).then((result) => {
            commit('setTransferStatus', true);
            return result;
        });
        return response;
    },
    async sendEdit({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/edit_information';
        const response = await axios.post(endpoint, data).then((result) => {
            commit('setEditStatus', true);
            return result;
        });
        return response;
    },
    async fetchAgentsByCampaign({ commit }) {
        const endpoint = API_URL + 'api/v1/employees/byCampaign';
        const response = await axios.get(endpoint);
        commit('setEmployeeList', response.data.data);
    },
    async loadMyMimirAnswers({ commit }, EmpID) {
        const endpoint = API_URL + 'api/v1/employees/getMyMimirAnswers';
        const response = await axios.post(endpoint, {
            employeeId: EmpID,
        });
        commit('setAnswersEmployee', response.data[0]);
    },
    async sendMimirAnswers({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/sendMimir';
        const response = await axios.post(endpoint, data).then((result) => {
            commit('setEditStatus', true);
            return result;
        });
        return response;
    },
    async saveNewEmployee ({ commit }, data ){
        let employee = {
            FirstName:          data.name?.toUpperCase(),                                //mandatory
            MiddleName:         data.middle_name ? data.middle_name?.toUpperCase() : null,
            LastName1:          data.first_lastName?.toUpperCase(),                      //mandatory
            LastName2:          data.second_lastName ? data.second_lastName?.toUpperCase() : null,
            Gender:             data.gender ? data.gender?.toUpperCase() : null,
            StateOfBirth:       data.citySelected ? data.cityOfBirth.cityName?.toUpperCase() : '',
            CityOfBirth:        data.stateSelected ? data.stateOfBirth.stateName?.toUpperCase() : '',
            Nationality:        data.nationality?.toUpperCase(),
            CURP:               data.curp?.toUpperCase(),
            RFC:                data.rfc?.toUpperCase(),
            SSN:                data.ssn,
            UMF:                data.imssClinic,
            Email:              data.email ? data.email : null,
            Phone1:             data.phoneOne,
            Phone2:             data.phoneTwo,
            EmergencyPhone:     data.emergencyPhone,
            Address1:           data.addressOne ? data.addressOne?.toUpperCase() : null,
            Address2:           data.addressTwo ? data.addressTwo?.toUpperCase() : null,
            City:               data.citySelected ? data.citySelected.cityName?.toUpperCase() : '',
            State:              data.stateSelected ? data.stateSelected.stateName?.toUpperCase() : '',
            Zip:                data.zip,
            Bilingual:          data.isBilingual ? data.isBilingual?.toUpperCase() : null,
            maritalStatus:      data.maritalStatus ? data.maritalStatus?.toUpperCase() : null,
            education:          data.education ? data.education?.toUpperCase() : null,
            specialty:          data.specialty ? data.specialty?.toUpperCase() : null,
            TrainingType:       data.trainingType ? data.trainingType?.toUpperCase() : null,
            FatherName:         data.fatherName?.toUpperCase(),
            MotherName:         data.motherName?.toUpperCase(),
            PaymentInterval:    data.paymentInterval.toUpperCase(),
            PaymentMethod:      data.paymentMethod?.toUpperCase(),
            PaymentBank:        data.paymentBank?.toUpperCase(),
            SDI:                data.sdi,
            SD:                 data.sd,
            EligibleForLoan:    data.eligibleForLoan,
            RequestedLoan:      data.requestedLoan,
            BeneficiaryName:    data.beneficiaryName,
            BeneficiaryRFC:     data.beneficiaryRfc,
            BeneficiaryAddress: data.beneficiaryAddress,
            Role:               data.titleSelected ? data.titleSelected.label?.toUpperCase() : null,
            Title:              data.titleSelected ? data.titleSelected.label?.toUpperCase() : null,
            Location:           data.location?.toUpperCase(),                            //mandatory
            Department:         data.departmentSelected.label?.toUpperCase(),   //mandatory
            Campaign:           data.campaign.label?.toUpperCase(),              //mandatory
            LOB:                data.lob.label?.toUpperCase(),                             //mandatory
            Project:            data.campaign.label?.toUpperCase(),              //mandatory
            reportTo:           data.reportTo?.toUpperCase(),                            //mandatory
            HireDate:           data.hired_date,
            CertificationDate:  data.cert_date,
            StartDate:          data.start_date,
            TerminationDate:    null,
            LastHireDate:       null,
            Rehirable:          null,
            VoluntaryTermination: null,
            ContractSigningDate:data.contractSigningDate,
            ScheduledHours:     data.scheduledHours,
            Note:               data.notes,
            scheduleInfo:       data.scheduleInfo,
            Po_id :             data.po_id,
        }
        const endpoint = API_URL + '/api/v1/employees';
        await axios.post(endpoint,employee).then((response)=>{            
            commit('setNewEmployeeStatus', response.status);
        });    
    },

    async fetchEmployeesByTitle({ commit }, title) {
        const endpoint = `${API_URL}api/v1/employees/fetchByTitle/${title}`;
        commit('toggleFlag', true);
        await axios.get(endpoint).then((response) => {
            commit('setEmployeeList', response.data.list);
            commit('toggleFlag', false);
        });
    },
    async fetchEmployeesByRole({ commit }, role) {
        const endpoint = `${API_URL}api/v1/employees/fetchByRole/${role}`;
        commit('toggleFlag', true);
        await axios.get(endpoint).then((response) => {
            commit('setEmployeeList', response.data.list);
            commit('toggleFlag', false);
        });
    },

    // Chat components endpoints
    async loadMyListOfContacts({ commit }, objectData) {
        const endpoint = API_URL + 'api/v1/employees/contacts';
        const response = await axios.post(endpoint, objectData);
        commit('setContactList', response.data.data);
    },

    async sendThisMessage({ commit }, objectData) {
        const endpoint = API_URL + 'api/v1/employees/sendMessage';
        let response = await axios.post(endpoint, objectData).then((res) => {
            commit('setStatusReview', false);
            return res;
        });
        return response;
    },

    async loadMessages({ commit }, objectData) {
        const endpoint = API_URL + 'api/v1/employees/getMessages';
        const response = await axios.post(endpoint, objectData);
        commit('setMessages', response.data);
    },

    async loadUnseenMessages({ commit }, objectData) {
        const endpoint = API_URL + 'api/v1/employees/getUnseenMessages';
        const response = await axios.post(endpoint, objectData);
        commit('setUnseenMessages', response.data.data);
    },
    async loginAsEmployee({ commit }, data) {
        commit('toggleFlag', true);
        const endpoint = API_URL + 'api/v1/register/loginAsEmployee';
        await axios.post(endpoint, data).then((res) => {
            sessionStorage.clear();
            sessionStorage.setItem('token', JSON.stringify(res.data.token));
            sessionStorage.setItem('EmpID', JSON.stringify(res.data.EmpID));
            sessionStorage.setItem('status', JSON.stringify(res.data.status));
            sessionStorage.setItem('location', JSON.stringify(res.data.location));
            sessionStorage.setItem('campaign', JSON.stringify(res.data.campaign));
            sessionStorage.setItem('roles', JSON.stringify(res.data.roles));
            sessionStorage.setItem('passwordReset', JSON.stringify(res.data.changePassword))
            return res;
        });
    },

    async publicProfile({ commit }, data) {
        const endpoint = API_URL + 'api/v1/employees/public-profile/' + data;
        const response = await axios.get(endpoint).then((res) => {
            commit('setInfoEmployee', res.data.employee);
            return res.data;
        });
        return response;
    },

    // password vault
    async fetchPasswordVault({ commit }) {
        const endpoint = API_URL + 'api/v4/employees/passwords/my-vault';
        const response = await axios.get(endpoint).then((res) => {
            commit('setVault', res.data.vault);
        });
        return response;
    },
    async storePasswordVault({ commit }, data) {
        const endpoint = API_URL + 'api/v4/employees/passwords/store';
        const response = await axios.post(endpoint, data).then((res) => {
            commit('toggleFlag', false);
            return res.data.msg;
        });
        return response;
    },
    async editPasswordVault({ commit }, data) {
        const endpoint = API_URL + `api/v4/employees/passwords/${data.id}/edit`;
        const response = await axios.post(endpoint, data).then((res) => {
            commit('toggleFlag', false);
            return res.data.msg;
        });
        return response;
    },
    async deletePasswordVault({ commit }, id) {
        const endpoint = API_URL + `api/v4/employees/passwords/${id}/delete`;
        const response = await axios.get(endpoint).then((res) => {
            commit('toggleFlag', false);
            return res.data.msg;
        });
        return response;
    },

    async validateIdentity({ commit }, password) {
        const endpoint = API_URL + `api/v4/employees/passwords/validate-identity`;
        const response = await axios.post(endpoint, { password }).then((res) => {
            commit('toggleFlag', false);
            return res.data.verified;
        });
        return response;
    },
    async setProject(_, payload) {
        const endpoint = API_URL + `api/v4/employees/set-project`;
        return await axios.post(endpoint, payload);
    },
    async fetchEmployeeFieldOptions({ commit }, payload){
        const endpoint = API_URL + `api/v4/employees/options-for-employee-field`;
        const response = await axios.get(endpoint, payload);
        commit('setEmployeeFieldOptions', response.data.options);
        return response;
    },
    async fetchEmployeesUsingRole({ commit }) {
        const endpoint = API_URL + 'api/v1/employees/get-employees';
        const response = await axios.get(endpoint);
        commit('setEmployeeList', response.data.data);
    },
    async fetchComplianceData({ commit }, empID) {
        const endpoint = API_URL + 'api/v2/indicators/conformance/getComplianceData';
        const response = await axios.post(endpoint, { employeeId: empID });
        commit('setCompliancePending', response.data.compliance_pending);
        return response;
    },
};
const mutations = {
    setEmployeeFieldOptions: (state, options) => (state.employeeFieldOptions = options),
    setEmployeePagination: (state, pagination) => (state.pagination = pagination),
    setEmployeeList: (state, employeeList) => (state.employeeList = employeeList),
    toggleFlag: (state, newStatus) => (state.loadingFlag = newStatus),
    setInfoEmployee: (state, infoEmployee) => (state.infoEmployee = infoEmployee),
    setContactList: (state, contactList) => (state.contactList = contactList),
    setUnseenMessages: (state, unseenMessages) => (state.unseenMessages = unseenMessages),
    setMessages: (state, messages) => (state.messages = messages),
    setMessageStatus: (state, messageStatus) => (state.messageStatus = messageStatus),
    setAnswersEmployee: (state, answersEmployee) => (state.answersEmployee = answersEmployee),
    setSuccessfullStatus: (state, infoEmployee) => (state.infoEmployee = infoEmployee),
    setPromoteStatus: (state, promoteSuccessfull) => (state.promoteSuccessfull = promoteSuccessfull),
    setTerminationStatus: (state, promoteSuccessfull) => (state.promoteSuccessfull = promoteSuccessfull),
    setPredismissalStatus: (state, promoteSuccessfull) => (state.promoteSuccessfull = promoteSuccessfull),
    setTransferStatus: (state, transferStatus) => (state.transferStatus = transferStatus),
    setNewEmployeeStatus: (state, params) => (state.newEmployeeStatus = params),
    setEditStatus: (state, editSuccessfull) => (state.editSuccessfull = editSuccessfull),
    setEditableInfo: (state, editableInfo) => (state.editableInfo = editableInfo),
    setVault: (state, passwords) => (state.passwords = passwords),
    setCompliancePending: (state, data) => (state.compliancePending = data),
};
export default {
    state,
    getters,
    actions,
    mutations
};