<template>
  <div v-if="isOpen">
    <div class="fixed w-screen left-0 top-0 h-screen z-10"/>
    <div
        ref="incidentCreationModal"
        v-click-outside-modal="closeModal"
        :style="{top: `${this.position.top}px`, left: `${this.position.left}px`}"
        class="fixed drop-shadow-lg rounded-2xl overflow-hidden z-50"
    >
      <div class="flex flex-row bg-white p-2">
        <button
            :disabled="isLoading"
            class="rounded-xl py-1 px-2 bg-[#c95b5b] text-white disabled:bg-gray-200"
            @click="cancelRequest"
        >
          Cancel Request
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutsideModal from "@/directives/click-outside-modal";
import {mapActions, mapGetters} from "vuex";

/**
 * This is the template for both the creation and edit modals for incidents.
 * Its main feature is appearing either on top or below a given html element,
 * based on the available space on the screen.
 *
 * @component
 */

export default {
    name: 'baseIncidentModal',

    directives: {
        clickOutsideModal: ClickOutsideModal
    },

    props: {
        incident: {
            required: true,
            type: Object,
        }
    },

    data() {
        return {
            isLoading: false,
            isOpen: false,
            position: {},
        };
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'selectedEmployees',
            ]
        )
    },

    methods: {
        ...mapActions(
            'incidentManager',
            [
                'editRequestStatus',
                'fetchIncidents',
            ]
        ),
        changeToRejected() {
            const payload = {
                request_ids: [this.incident.id],
                status: 'REJECTED',
            };
            return this.editRequestStatus(payload).then(
                () => {
                    this.$emit("update");
                    return this.$fire(
                        {
                            type: "success",
                            title: "Success",
                            text: "The request has been successfully cancelled.",
                            showConfirmButton: true,
                        },
                    );
                },
            );
        },
        cancelRequest() {
            this.isLoading = true;
            this.$fire(
                {
                    title: "Are you sure?",
                    text: "You'll have to create a new request if you change " +
                        "your mind",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonText: "I'm sure",
                    showConfirmButton: true,
                }
            ).then(
                (result) => {
                    if (result.isConfirmed === false) {
                        return;
                    }

                    return this.changeToRejected();
                },
            ).catch(
                error => {
                    console.error(error);
                    return this.$fire(
                        {
                            title: "Something went wrong",
                            type: "error",
                            showConfirmButton: true,
                        }
                    )
                }
            ).finally(
                () => {
                    this.isLoading = false;
                    this.closeModal();
                },
            )
        },
        openModal(event) {
            this.isOpen = true;

            this.$nextTick(() => {
                const modal = this.$refs.incidentCreationModal;
                const modalWidth = modal.offsetWidth;
                const modalHeight = modal.offsetHeight;
                const offset = 10;

                let left = event.clientX + offset;
                let top = event.clientY - modalHeight - offset;

                if (top < 0) {
                    top = event.clientY + offset;
                }

                if (left + modalWidth > window.innerWidth) {
                    left = window.innerWidth - modalWidth - offset;
                }

                if (left < offset) {
                    left = offset;
                }

                this.position = {top, left};
                this.$emit('lock-calendar');
            });
        },
        closeModal() {
            this.$emit('unlock-calendar')
            this.isOpen = false;
        }
    }
}
</script>