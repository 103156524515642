<template>
    <div class="color_case text-xs text-gray-500 flex flex-row items-center justify-start mx-2 mt-1"
        :style="`--color-case: #${hex};`">
        <slot name="icon">
            <div class="mx-2"> </div>
        </slot>
        {{ title }}
    </div>
</template>

<script>
export default {
    props: ['title', 'hex']
}
</script>

<style scoped>
.color_case {
    position: relative;
}

.color_case div {
    position: relative;
    width: 1.25em;
    height: 1.25em;
    background: var(--color-case);
    border-radius: 999px;
    border: solid 2px white;
}
</style>