<template>
    <div class="main p-4 space-4">
        <div class="grid grid-cols-2 gap-4">
            <button class="bg-green-600 hover:bg-green-700 px-3 py-2 rounded-2xl text-white mt-2 m-auto relative overflow-hidden shadow-md" @click="recoverActive()" :hidden="!ADMIN_VIEW || !IS_NOT_ACTIVE">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
                <span class="uppercase font-semibold font-sans text-sm">
                    Recover User
                </span>
            </button>

            <button :disabled="IS_NOT_ACTIVE" class="bg-green-600 hover:bg-green-700 px-3 py-2 rounded-2xl text-white mt-2 m-auto relative overflow-hidden shadow-md" v-b-modal="'promotion'" :hidden="!ADMIN_VIEW">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                </svg>
                <span class="uppercase font-semibold font-sans text-sm">
                    Promote
                </span>
            </button>

            <button :disabled="IS_NOT_ACTIVE" class="bg-orange-700 hover:bg-orange-800 px-3 py-2 rounded-2xl text-white mt-2 m-auto relative overflow-hidden shadow-md" v-b-modal="'demotion'" :hidden="!ADMIN_VIEW">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                </svg>
                <span class="uppercase font-semibold font-sans text-sm">
                    Demote
                </span>
            </button>

            <button :disabled="IS_NOT_ACTIVE" class="bg-yellow-600 hover:bg-yellow-700 px-3 py-2 rounded-2xl text-white mt-2 m-auto relative overflow-hidden shadow-md" v-b-modal="'transfer'" :hidden="!ADMIN_VIEW">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                </svg>
                <span class="uppercase font-semibold font-sans text-sm">
                    Transfer
                </span>
            </button>

            <button :disabled="IS_NOT_ACTIVE" class="bg-red-800 hover:bg-red-900 px-3 py-2 rounded-2xl text-white mt-2 m-auto relative overflow-hidden shadow-md" v-b-modal="'terminate'" :hidden="!ADMIN_VIEW">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <span class="uppercase font-semibold font-sans text-sm">
                    Terminate
                </span>
            </button>

            <button :disabled="IS_NOT_ACTIVE" class="bg-blue-500 hover:bg-blue-700 px-3 py-2 rounded-2xl text-white mt-2 m-auto relative overflow-hidden shadow-md" v-b-modal="'incidence'" :hidden="!ADMIN_VIEW">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12 absolute text-gray-300 opacity-25 -top-1 -left-1 scale-150">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                </svg>
                <span class="uppercase font-semibold font-sans text-sm">
                    Incidences
                </span>
            </button>
        </div>

        <b-modal ref="incidenceRef" id="incidence" title="Incidences" size="lg" no-close-on-backdrop centered hide-footer>
            <IncidencesModal @closeModal="closeModals" :profileInfo="infoEmployee"></IncidencesModal>
        </b-modal>
        <b-modal ref="terminatenRef" id="terminate" title="Terminate" no-close-on-backdrop centered hide-footer>
            <TerminateModal  @closeTermination="closeModals" :profileInfo="infoEmployee"></TerminateModal>
        </b-modal>
        <b-modal ref="transferRef" id="transfer" title="Transfer" no-close-on-backdrop centered hide-footer>
            <TransferModal @closeTransfer="closeModals" :profileInfo="infoEmployee"></TransferModal>
        </b-modal>
        <b-modal ref="promotionRef" id="promotion" title="Promotion" no-close-on-backdrop centered hide-footer>
            <PromotionModal @closePromotion="closeModals" :profileInfo="infoEmployee"></PromotionModal>
        </b-modal>
        <b-modal ref="demotionRef" id="demotion" title="Demotion" no-close-on-backdrop centered hide-footer>
            <DemotionModal @closeDemotion="closeModals" :profileInfo="infoEmployee"></DemotionModal>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import modal from '@/views/components/Modal/modalBase.vue'
import PromotionModal   from '@/views/components/Modal/promotion'
import DemotionModal   from '@/views/components/Modal/demotion'
import TerminateModal   from '@/views/components/Modal/terminate'
import TransferModal    from '@/views/components/Modal/transfer'
import IncidencesModal  from '@/views/components/Modal/incidences'
export default {
    components: {
        // modal,
        PromotionModal,
        DemotionModal,
        TerminateModal,
        TransferModal,
        IncidencesModal,
    },
    data() {
        return{ 
            showEditModal:false,
        }
    },
    props: {
        role:{
            default: 'e'
        }
    },
    computed: {
        ...mapGetters(['infoEmployee', 'getSurveys']),
        activeSurveys: function() {
            return this.getSurveys.filter(function(u) {
                return u.Status
            })
        },
        IS_NOT_ACTIVE(){
            return !(this.infoEmployee.EmpStatus?.toLowerCase() === 'active');
        },
        ADMIN_VIEW (){
            return this.role === 'a';
        },
        USER_VIEW (){
            return this.role === 'e';
        }
    },
    methods:{
        ...mapActions(['sendPredismissal']),
        
        closeModals: function(){
            this.$refs['terminateRef']?.hide();
            this.$refs['incidenceRef']?.hide();
            this.$refs['transferRef']?.hide();
            this.$refs['promotionRef']?.hide();
            this.$refs['demotionRef']?.hide();
            this.$refs['surveyRef']?.hide();
        },
        cancelPredismissal: function(){
            this.$confirm("Do you want to cancel predismissal for "+this.infoEmployee.EmpID+"?","Are you sure?", "warning").then((result) => {
                if (result){                
                    let objData = { 
                        newStatus: "Active",
                        comment: null,
                        action: 'Pre Dismissal Cancelation',
                        reason: 'Pre Dismissal Canceled',
                        recID: this.infoEmployee.RecID,
                    }
                    this.sendPredismissal(objData).then(() => {
                        this.$fire({
                            type: "success",
                            title: "Predismissal Status Sent",
                            text: "Active Status Recovered",
                            showConfirmButton: false,
                            timer: 2500
                        }).then(() => {
                            location.reload();
                        })
                    }).catch(() => {
                        this.$fire({
                            type: "error",
                            title: "Oops!",
                            text: "Something went wrong, please report to the system administrator"
                        })
                    })              
                }
            });
        },
        recoverActive: function(){
          this.$confirm("Do you want to put Agent "+this.infoEmployee.EmpID+" in ACTIVE Status?","Are you sure?", "warning").then((result) => {
                if (result){                
                    let objData = { 
                        newStatus: "Active",
                        comment: null,
                        action: 'User Recover',
                        reason: 'User Recover',
                        recID: this.infoEmployee.RecID,
                    }
                    this.sendPredismissal(objData).then(() => {
                        this.$fire({
                            type: "success",
                            title: "User Reactivated",
                            text: "Active Status Recovered",
                            showConfirmButton: false,
                            timer: 2500
                        }).then(() => {
                            location.reload();
                        })
                    }).catch(() => {
                        this.$fire({
                            type: "error",
                            title: "Oops!",
                            text: "Something went wrong, please report to the system administrator"
                        })
                    })              
                }
            })
        },
    }
}
</script>
<style scoped>
.main{
    width: 100% !important;
}
button { 
    width: 13rem !important;
    height: 3rem !important;
}
</style>