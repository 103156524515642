<template>
  <div
      class="flex flex-row size-full h-full divide-x-2 rounded-lg overflow-hidden transition-shadow duration-300"
  >
    <div
        class="flex flex-col w-80 h-full overflow-y-auto bg-gray-50 space-y-1 py-1 px-2"
    >
      <calendar-sidebar :disabled="disabled" @update="updateCalendar"/>
    </div>
    <interactive-calendar :disabled="disabled" @update="updateCalendar"/>
  </div>
</template>
<script>
import CalendarSidebar
    from "@/views/employees/IncidentManager/CalendarView/CalendarSidebar/CalendarSidebar.vue";
import InteractiveCalendar
    from "@/views/employees/IncidentManager/CalendarView/InteractiveCalendar/InteractiveCalendar.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'IncidentCalendar',
    components: {
        CalendarSidebar,
        InteractiveCalendar,
    },

    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            isUpdating: false,
        };
    },

    activated() {
        this.updateCalendar();
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'selectedEmployees',
                'activeIncidentType'
            ]
        )
    },

    methods: {
        ...mapActions(
            'incidentManager',
            [
                'fetchIncidents',
                'fetchIncidentTypes',
                'fetchVacationLimits',
            ]
        ),
        updateCalendar() {
            if (!this.isUpdating) {
                this.isUpdating = true;

                const requests = [
                    this.fetchIncidentTypes(),
                    this.fetchIncidents(
                        {
                            emp_id: this.selectedEmployees,
                            exclude_status: ["REJECTED"]
                        }
                    ),
                ]

                if (this.activeIncidentType?.name?.toLowerCase() === 'vacation'
                    && this.selectedEmployees.length > 0) {
                    requests.push(
                        this.fetchVacationLimits(this.selectedEmployees[0])
                    );
                }
                Promise.all(requests).finally(
                    () => this.isUpdating = false
                );
            }
        },
    }
}
</script>