<template>
  <div class="relative flex bg-white shadow-sm rounded-[10px] p-4 m-1">
    <div
      class="absolute top-0 left-11 rounded-b-lg bg-c-primary shadow-md text-xs font-semibold text-white uppercase whitespace-nowrap px-3 py-2">
      New Topic!
    </div>
    <div v-if="!hasPreview" class="flex-none h-36 w-36 bg-cover bg-center bg-gray-200 rounded-[10px] overflow-hidden"
      :style="{ backgroundImage: 'url(' + newsfeedImage + ')' }">
    </div>
    <div v-else class="flex-none w-1/3 text-center overflow-hidden">
      <div v-for="(img, idx) in imgPreview" :key="idx">
        <img class="content-center min-h-full" :src="img" alt="Preview Image" />
      </div>
    </div>
    <div class="pl-6 text-left">
      <h3 class="text-lg font-semibold text-c-secondary leading-none">{{ entryTitle }}</h3>
      <p class="mt-3 mb-0 leading-tight">{{ entryDescription }}</p>
      <!-- Reaction Bar -->
      <div class="reaction-bar mt-2 flex justify-around">
        <button class="reaction-btn" data-tooltip="Like" @click="$emit('reaction', '👍')">👍</button>
        <button class="reaction-btn" data-tooltip="Celebrate" @click="$emit('reaction', '🎉')">🎉</button>
        <button class="reaction-btn" data-tooltip="Support" @click="$emit('reaction', '🙌')">🙌</button>
        <button class="reaction-btn" data-tooltip="Love" @click="$emit('reaction', '❤️')">❤️</button>
        <button class="reaction-btn" data-tooltip="Insightful" @click="$emit('reaction', '💡')">💡</button>
        <button class="reaction-btn" data-tooltip="Funny" @click="$emit('reaction', '😂')">😂</button>
        <button class="reaction-btn" data-tooltip="Fire" @click="$emit('reaction', '🔥')">🔥</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsfeedImage: {
      type: String,
      required: true,
    },
    entryDescription: {
      type: String,
      required: true,
    },
    entryTitle: {
      type: String,
      required: true,
    },
    imgPreview: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    hasPreview() {
      return this.imgPreview && this.imgPreview.length > 0;
    },
  },
};
</script>

<style scoped>
/* Reaction Bar Styles */
.reaction-bar {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.reaction-btn {
  position: relative;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.reaction-btn:hover {
  transform: scale(1.5);
}

.reaction-btn::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s;
  white-space: nowrap;
  pointer-events: none;
}

.reaction-btn:hover::after {
  opacity: 1;
}
</style>
