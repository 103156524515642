<template>
    <div class="w-full h-full relative flex flex-row align-items-start justify-content-center">
        <div class="bg-white shadow-sm rounded-sm p-3 mt-2 lg:w-10/12 sm:w-full">
            <form class="grid md:grid-cols-3 sm:grid-cols-2 gap-2" @submit.prevent="submit">
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Site Location
                    </label>
                    <select class="border-b border-gray-200 px-3 py-2 focus:border-blue-400 w-full" v-model="location"
                        required>
                        <option hidden value="">Select a location...</option>
                        <option v-for="(site, index) in getSites[0].children" :key="index" :value="site.id">
                            {{ site.label }}
                        </option>
                    </select>
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Campaign
                    </label>
                    <select class="border-b border-gray-200 px-3 py-2 focus:border-blue-400 w-full" v-model="campaign"
                        required>
                        <option hidden value="">Select a campaign...</option>
                        <option v-for="(campaign, index) in getCampaigns" :key="index" :value="campaign.id">
                            {{ campaign.label }}
                        </option>
                    </select>
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        LOB
                    </label>
                    <select class="border-b border-gray-200 px-3 py-2 focus:border-blue-400 w-full" v-model="lob"
                        required :disabled="noLobs">
                        <option hidden value="">Select a line of business...</option>
                        <option v-for="(lob, index) in getLob" :key="index" :value="lob.id">
                            {{ lob.label }}
                        </option>
                    </select>
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        PO Type
                    </label>
                    <select class="border-b border-gray-200 px-3 py-2 focus:border-blue-400 w-full" v-model="type"
                        required>
                        <option hidden value="">Select a type...</option>
                        <option value="ATTRITION">Attrition</option>
                        <option value="GROWTH">Growth</option>
                        <option value="HYBRID">Hybrid</option>
                    </select>
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Recruiting start date
                    </label>
                    <input type="date" :min="minDate"
                        class="border-b border-gray-200 px-3 py-1 focus:border-blue-400 w-full" required
                        v-model="recruitment_start">
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Recruiting deadline
                    </label>
                    <input type="date" :min="minDate"
                        class="border-b border-gray-200 px-3 py-1 focus:border-blue-400 w-full" required
                        v-model="recruitment_deadline">
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Training date
                    </label>
                    <input type="date" :min="minDate"
                        class="border-b border-gray-200 px-3 py-1 focus:border-blue-400 w-full" required
                        v-model="training_start">
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Required Agents
                    </label>
                    <input type="number" min="1" class="border-b border-gray-200 px-3 py-1 focus:border-blue-400 w-full"
                        required v-model="agents">
                </div>
                <div class="group">
                    <label class="text-gray-400 text-left text-xs ml-2 block group-focus:text-gray-600">
                        Weekly hours
                    </label>
                    <input type="number" min="1" max="48"
                        class="border-b border-gray-200 px-3 py-1 focus:border-blue-400 w-full" required
                        v-model="hours">
                </div>

                <button type="button" class="mt-2 bg-red-300 hover:bg-red-500 ml-auto text-white px-3 py-2 rounded-sm"
                    @click.prevent="$router.go()">
                    Clear
                </button>
                <button class="mt-2 bg-gray-700 ml-2 text-white px-3 py-2 rounded-md shadow-md">
                    Submit
                </button>
            </form>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            minDate: this.$moment().add(1, 'days').format('YYYY-MM-DD')
        }
    },
    watch: {
        location: function (newVal) {
            if (newVal && this.campaign) {
                this.fetchLobByCampaignSite({ location: this.location, campaign: this.campaign});
            }
        },
        campaign: function (newVal) {
            if (newVal && this.location) {
                this.fetchLobByCampaignSite({ location: this.location, campaign: this.campaign});
            }
        },
    },
    computed: {
        ...mapGetters(['getCurrentPurchaseOrder', 'getCampaigns', 'getSites', 'getLob']),
        noLobs () {
            return !this.getLob.length || this.getLob?.length <= 0;
        },
        location: {
            get() {
                return this.getCurrentPurchaseOrder.site_id;
            },
            set(value) {
                this.$store.commit('SET_SITE_LOCATION', value);
            }
        },
        campaign: {
            get() {
                return this.getCurrentPurchaseOrder.campaign_id;
            },
            set(value) {
                this.$store.commit('SET_CURRENT_CAMPAIGN', value);
            }
        },
        lob: {
            get() {
                return this.getCurrentPurchaseOrder.lob_id;
            },
            set(value) {
                this.$store.commit('SET_CURRENT_LOB', value);
            }
        },
        type: {
            get() {
                return this.getCurrentPurchaseOrder.type;
            },
            set(value) {
                this.$store.commit('SET_CURRENT_TYPE', value);
            }
        },
        agents: {
            get() {
                return this.getCurrentPurchaseOrder.heads_required;
            },
            set(value) {
                this.$store.commit('SET_REQUIRED_AGENTS', value);
            }
        },
        hours: {
            get() {
                return this.getCurrentPurchaseOrder.fte_size;
            },
            set(value) {
                this.$store.commit('SET_WEEKLY_HOURS', value);
            }
        },
        recruitment_start: {
            get() {
                return this.getCurrentPurchaseOrder.recruitment_start;
            },
            set(value) {
                this.$store.commit('SET_RECRUITMENT_START', value);
            }
        },
        recruitment_deadline: {
            get() {
                return this.getCurrentPurchaseOrder.recruitment_deadline;
            },
            set(value) {
                this.$store.commit('SET_RECRUITMENT_DEADLINE', value);
            }
        },
        training_start: {
            get() {
                return this.getCurrentPurchaseOrder.training_start;
            },
            set(value) {
                this.$store.commit('SET_TRAINING_DATE', value);
            }
        },
    },
    methods: {
        ...mapActions(['storePurchaseOrder', 'fetchCampaignsByDepartmentName', 'fetchSiteDefinitions', 'fetchLobByCampaignSite']),
        submit: function () {
            this.storePurchaseOrder(this.getCurrentPurchaseOrder).
                then(() => {
                    this.$fire({
                        type: 'success',
                        title: 'The purchase order has been store.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500)
                });
        },
    },
    mounted() {
        this.$emit('set-title', 'Create Purchase Order');
        this.fetchSiteDefinitions();
        this.fetchCampaignsByDepartmentName('Operations');
        this.date = this.$moment().format('YYYY-MM-DD');
    },
    beforeMount() {
        this.$store.commit('RESET_STATE');
    }

}
</script>
