<template>
    <form @submit.prevent="beforeSubmit"
        class="relative w-full h-full flex flex-col items-center justify-start overflow-y-scroll py-3 pt-5 pb-5">
        <div class="lg:w-3/5 md:w-10/12 sm:w-10/12 shadow-3xl bg-white">
            <div
                class="relative mx-auto bg-blue-200 w-16 h-16 rounded-full ring ring-blue-300 -mt-8 mb-3 flex items-center justify-center select-none">
                <span class="font-semibold text-white text-2xl">{{ employee_initials }}</span>
            </div>
            <div>
                <h4>
                    <span v-if="getCandidate.profile">
                        Edit {{
                            (getCandidate.profile.name + ' ' + getCandidate.profile.first_last_name)
                        }}
                    </span>
                    <span v-else>
                        Edit Candidate
                    </span>
                </h4>
            </div>

            <div class="p-4 space-y-5">
                <div>
                    <div class="w-full text-xl font font-medium">Recruitment</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">Site:<span
                                    class="text-red-600">*</span></label>
                            <select :class="$v.form.site_id.$invalid && $v.form.$dirty ? errorClass : ''"
                                class="w-full border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 text-sm text-gray-400 "
                                v-model="form.site_id">
                                <option hidden value="">Select a location...</option>
                                <option value="MTY">Monterrey</option>
                                <option value="QRO">Queretaro</option>
                                <option value="AGS">Aguascalientes</option>
                                <option value="LB">Longview</option>
                            </select>
                            <small class="text-red-400 ml-2 text-xs" v-if="$v.form.site_id.$invalid && $v.form.$dirty">
                                This field is required.
                            </small>
                        </div>

                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Source: <span class="text-red-600">*</span>
                            </label>
                            <my-source :source="form.source_id" :error="$v.form.site_id.$invalid && $v.form.$dirty"
                                @change="setSource" />
                            <small class="text-red-400 ml-2 text-xs"
                                v-if="$v.form.source_id.$invalid && $v.form.$dirty">
                                This field is required.
                            </small>
                        </div>

                        <Transition name="fade">
                            <div class="text-left" v-show="hasReferral">
                                <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                    Referral id:<span class="text-red-600">*</span>
                                </label>
                                <multiselect :options="getTeamMembers" v-model="referralObject" label="name"
                                    track-by="id" :allow-empty="false" />
                                <small class="text-red-400 ml-2 text-xs"
                                    v-if="$v.form.referral_id.$invalid && $v.form.$dirty">
                                    This field is required.
                                </small>
                            </div>
                        </Transition>
                    </div>
                </div>

                <div>
                    <div class="w-full text-xl font font-medium">Personal Information</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Name:<span class="text-red-600">*</span>
                            </label>
                            <input v-model="form.name"
                                :class="$v.form.name.$invalid && $v.form.$dirty ? errorClass : ''"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="$v.form.name.$invalid && $v.form.$dirty">
                                This field is required.
                            </small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Middle name:
                            </label>
                            <input v-model="form.middle_name"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                First Last Name:
                                <span class="text-red-600">*</span>
                            </label>
                            <input :class="$v.form.first_last_name.$invalid && $v.form.$dirty ? errorClass : ''"
                                v-model="form.first_last_name"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="$v.form.name.$invalid && $v.form.$dirty">
                                This field is required.
                            </small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">Second Last Name:</label>
                            <input v-model="form.second_last_name"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Gender:
                            </label>
                            <candidate-dropdown id="gender" column="gender" v-model="form.gender"
                                placeholder="Select..." />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Nationality:
                            </label>
                            <candidate-dropdown id="nationality" column="nationality" v-model="form.nationality"
                                placeholder="Select..." />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                State of Birth:
                            </label>
                            <select v-model="form.stateOfBirth" @change="loadCities(form.stateOfBirth, 'birth')" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                    bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected disabled>Choose a state...</option>
                                <option v-for="state in stateList" :value="state.stateName" :key="state.stateName">
                                    {{ state.stateName }}
                                </option>
                            </select>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                City of Birth:
                            </label>
                            <div v-if="showLoadingCity.birth" class="w-full flex flex-col">
                                <loading />
                                <span class="mx-auto text-sm text-gray-400">
                                    Loading cities...
                                </span>
                            </div>
                            <div v-if="selectState.birth" class="w-full h-[40px]">
                                <label class="text-center content-center size-full text-gray-400">
                                    Select a state first
                                </label>
                            </div>
                            <div v-if="showSelectCity.birth" class="w-full">
                                <select class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                        bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]" id="cityUser"
                                    v-model="form.cityOfBirth">
                                    <option value="" hidden>Choose a city...</option>
                                    <option v-for="city in cityOptions.birth" :value="city.cityName"
                                        :key="city.cityName">
                                        {{ city.cityName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Curp:<span class="text-red-600">*</span>
                            </label>
                            <input maxlength="18" :class="$v.form.curp.$invalid && $v.form.$dirty ? errorClass : ''"
                                v-model="form.curp"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="$v.form.curp.$invalid && $v.form.$dirty">
                                Please provide a valid curp.
                            </small>
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                RFC:
                            </label>
                            <input maxlength="13" :class="nonRequiredInvalid('rfc') ? errorClass : ''"
                                v-model="form.rfc"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="nonRequiredInvalid('rfc')">
                                Please provide a valid rfc.
                                Í</small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                SSN:
                            </label>
                            <input maxlength="11" v-model="form.ssn"
                                :class="nonRequiredInvalid('ssn') ? errorClass : ''"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="nonRequiredInvalid('ssn')">
                                Please provide a valid 11 digit SSN.
                            </small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                IMSS Clinic Number:
                            </label>
                            <input maxlength="10" v-model="form.umf" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm
                                bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="w-full text-xl font font-medium">Contact Information</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">Personal Email:<span
                                    class="text-red-600">*</span></label>
                            <input :class="$v.form.email.$invalid && $v.form.$dirty ? errorClass : ''"
                                v-model="form.email"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="$v.form.email.$invalid && $v.form.$dirty">
                                Please provide a valid email.
                            </small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Phone 1:
                                <span class="text-red-600">*</span>
                            </label>
                            <input maxlength="10" :class="$v.form.phone.$invalid && $v.form.$dirty ? errorClass : ''"
                                v-model="form.phone"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="$v.form.phone.$invalid && $v.form.$dirty">
                                Please provide a valid 10 digits phone.
                            </small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Phone 2:
                            </label>
                            <input maxlength="10" v-model="form.phoneTwo"
                                :class="nonRequiredInvalid('phoneTwo') ? errorClass : ''"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="nonRequiredInvalid('phoneTwo')">
                                Please provide a valid 10 digits phone.
                            </small>
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Emergency Contact Name:
                            </label>
                            <input maxlength="100" v-model="form.emergencyName"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Relationship with employee:
                            </label>
                            <input maxlength="100" v-model="form.emergencyRelationship"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Emergency Contact Phone:
                            </label>
                            <input maxlength="10" v-model="form.emergencyPhone"
                                :class="nonRequiredInvalid('emergencyPhone') ? errorClass : ''"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="nonRequiredInvalid('emergencyPhone')">
                                Please provide a valid 10 digits phone.
                            </small>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="w-full text-xl font font-medium">Address</div>
                    <div class="grid grid-cols-4 gap-2">
                        <div class="col-span-3 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Full Address:
                            </label>
                            <input maxlength="255" v-model="form.addressOne"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Zip Code:
                            </label>
                            <input maxlength="5" v-model="form.zipCode" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm
                                   bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                State:
                            </label>
                            <select v-model="form.stateSelected" @change="loadCities(form.stateSelected, 'address')"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                    bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected disabled>Choose a state...</option>
                                <option v-for="state in stateList" :value="state.stateName" :key="state.stateName">
                                    {{ state.stateName }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                City:
                            </label>
                            <div v-if="showLoadingCity.address" class="w-full flex flex-col">
                                <loading />
                                <span class="mx-auto text-sm text-gray-400">
                                    Loading cities...
                                </span>
                            </div>
                            <div v-if="selectState.address" class="w-full h-[40px]">
                                <label class="text-center content-center size-full text-gray-400">
                                    Select a state first
                                </label>
                            </div>
                            <div v-if="showSelectCity.address" class="w-full">
                                <select class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                        bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]" id="cityUser"
                                    v-model="form.citySelected">
                                    <option value="" hidden>Choose a city...</option>
                                    <option v-for="city in cityOptions.address" :value="city.cityName"
                                        :key="city.cityName">
                                        {{ city.cityName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="w-full text-xl font font-medium">Other</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Is Bilingual:
                            </label>
                            <select v-model="form.bilingual" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected hidden>Select...</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                            </select>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Marital Status:
                            </label>
                            <select v-model="form.maritalStatus" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected hidden>Select...</option>
                                <option value="Soltero">Single</option>
                                <option value="Casado">Married</option>
                                <option value="Unión libre">Domestic Partnership</option>
                                <option value="Divorciado">Divorced</option>
                                <option value="Separado">Separated</option>
                                <option value="Viudo">Widowed</option>
                            </select>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Education:
                            </label>
                            <select v-model="form.education" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                                bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected hidden>Select...</option>
                                <option value="ELEMENTARY">Elementary</option>
                                <option value="MIDDLE SCHOOL">Middle School</option>
                                <option value="HIGH SCHOOL">High School</option>
                                <option value="UNDERGRADUATE">Undergraduate</option>
                                <option value="GRADUATE">Graduate</option>
                                <option value="POSTGRADUATE">Postgraduate</option>
                            </select>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Specialty:
                            </label>
                            <candidate-dropdown column="specialty" v-model="form.specialty" placeholder="Select..." />
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Father's Name:
                            </label>
                            <input maxlength="100" v-model="form.fatherName"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Mother's Name:
                            </label>
                            <input maxlength="100" v-model="form.motherName"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                    </div>
                </div>

                <div>
                    <div class="w-full text-xl font font-medium">Payment</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="col-span-2 text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Payment Bank:
                            </label>
                            <candidate-dropdown column="PaymentBank" v-model="form.paymentBank"
                                placeholder="Select..." />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Payment Method:
                            </label>
                            <candidate-dropdown column="PaymentMethod" v-model="form.paymentMethod"
                                placeholder="Select..." />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Payment Interval:
                            </label>
                            <candidate-dropdown column="PaymentInterval" v-model="form.paymentInterval"
                                placeholder="Select..." />
                        </div>
                        <div class="text-left">
                            <label class="text-gray-500 text-xs font-thin m-0">
                                SDI:
                            </label>
                            <span
                                class="flex flex-row border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 w-full text-sm text-gray-400 items-center h-[40px]">
                                $
                                <input v-model="form.sdi" type="number" step="0.01"
                                    class="bg-gray-50 w-full h-full px-2" />
                            </span>
                        </div>
                        <div class="text-left">
                            <label class="text-gray-500 text-xs font-thin m-0">
                                SD:
                            </label>
                            <span
                                class="flex flex-row border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 w-full text-sm text-gray-400 items-center h-[40px]">
                                $
                                <input v-model="form.sd" type="number" step="0.01"
                                    class="bg-gray-50 w-full h-full px-2" />
                            </span>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Eligible For Loan:
                            </label>
                            <select v-model="form.eligibleForLoan" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                            bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected hidden>Select...</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                            </select>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Requested Loan:
                            </label>
                            <select v-model="form.requestedLoan" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-l-sm
                            bg-gray-50 px-3 py-2 w-full text-sm text-gray-400 h-[40px]">
                                <option value="" selected hidden>Select...</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                                <option value="NA">N/A</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="w-full text-xl font font-medium">Beneficiary</div>
                    <div class="grid grid-cols-1 gap-2">
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Beneficiary Name:
                            </label>
                            <input maxlength="100" v-model="form.beneficiaryName" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm
                                bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Beneficiary RFC:
                            </label>
                            <input maxlength="18" :class="nonRequiredInvalid('beneficiaryRfc') ? errorClass : ''"
                                v-model="form.beneficiaryRfc"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                            <small class="text-red-400 ml-2 text-xs" v-if="nonRequiredInvalid('beneficiaryRfc')">
                                Please provide a valid rfc.
                            </small>
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Beneficiary Address:
                            </label>
                            <input v-model="form.beneficiaryAddress"
                                class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                    </div>
                </div>

                <div>
                    <div class="w-full text-xl font font-medium">Work-Related Information</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Contract Signing Date:
                            </label>
                            <datepicker v-model="form.contractSigningDate" :format="customFormatter"
                                placeholder=" yyyy/mm/dd" name="contractSigningDate" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm
                                bg-gray-50 px-3 py-2 text-sm text-gray-400 h-[40px] w-full" typeable />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Scheduled Hours:
                            </label>
                            <candidate-dropdown column="ScheduledHours" input-type="number"
                                v-model="form.scheduledHours" placeholder="Select..." />
                        </div>
                        <div class="text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                Training Type:
                            </label>
                            <candidate-dropdown column="TrainingType" v-model="form.trainingType"
                                placeholder="Select..." />
                        </div>
                        <div class="w-full text-left">
                            <label class="px-2 text-gray-500 text-xs font-thin m-0">
                                PO Number:
                            </label>
                            <input type="number" v-model="form.purchaseOrder" class="border-b-4 border-gray-200 focus:border-blue-400 rounded-sm
                                bg-gray-50 px-3 py-2 w-full text-sm text-gray-400" />
                        </div>
                    </div>
                </div>

                <button :disabled="loading" type="submit"
                    class="w-full bg-gray-700 py-3 px-3 text-white rounded-sm mt-4 text-white">

                    <span v-if="!loading">Update profile</span>
                    <span v-else>
                        Saving
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block animate-bounce"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path
                                d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                            <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                        </svg>
                    </span>
                </button>
            </div>

        </div>
        <div class="lg:w-1/3 md:w-1/2 sm:w-10/12 shadow-3xl mt-5">
            <delivery-details :files="getCandidate.deliveries" v-if="getCandidate.profile" />
            <div v-else class="animate-pulse">
                Loading files ...
            </div>
        </div>

        <div class="lg:w-1/3 md:w-1/2 sm:w-10/12 shadow-3xl mt-5">
            <terminate-candidate />
        </div>
    </form>
</template>

<script>

import { email, required, requiredIf, numeric } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import MySource from '../../components/dropdowns/CandidateSource'
import DeliveryDetails from '../../components/misc/DeliveryDetails'
import TerminateCandidate from '../../components/partials/TerminateCandidate'
import CandidateDropdown from "@/views/components/Dropdown/CandidateDropdown.vue";
import moment from "moment/moment";
import Datepicker from "vuejs-datepicker";
import loading from "@/views/mockups/loading.vue";

export default {
    components: {
        loading,
        CandidateDropdown,
        MySource,
        DeliveryDetails,
        TerminateCandidate,
        Datepicker
    },
    data() {
        return {
            useCurp: true,
            loading: false,
            // City Select
            cityOptions: { birth: {}, address: {} },
            selectState: { birth: true, address: true },
            showLoadingCity: { birth: false, address: false },
            showSelectCity: { birth: false, address: false },
            // Form
            form: {
                name: '',
                middle_name: '',
                second_last_name: '',
                first_last_name: '',
                site_id: '',
                source_id: '',
                recruiter_id: '',
                referral_id: '',
                id: '',
                gender: '',
                nationality: '',
                dateOfBirth: '',
                stateOfBirth: '',
                cityOfBirth: '',
                curp: '',
                rfc: '',
                ssn: '',
                umf: '',
                email: '',
                phone: '',
                phoneTwo: '',
                emergencyName: '',
                emergencyRelationship: '',
                emergencyPhone: '',
                addressOne: '',
                addressTwo: '',
                zipCode: '',
                stateSelected: '',
                citySelected: '',
                bilingual: '',
                maritalStatus: '',
                education: '',
                specialty: '',
                fatherName: '',
                motherName: '',
                paymentBank: '',
                paymentMethod: '',
                paymentInterval: 'WEEKLY',
                sdi: '',
                sd: '',
                eligibleForLoan: '',
                requestedLoan: '',
                beneficiaryName: '',
                beneficiaryRfc: '',
                beneficiaryAddress: '',
                contractSigningDate: '',
                scheduledHours: '',
                purchaseOrder: '',
            },
            referralObject: {}
        }
    },
    created() {
        this.$emit('set-title', 'Edit Candidate');
        this.fetchStates();
        this.getCandidateProfile(this.$route.params.id).then(() => {
            const profile = Object.fromEntries(
                Object.entries(this.getCandidate.profile).map((x) => [x[0], x[1] ?? ''])
            );

            this.form.name = this.getCandidate.profile.name;
            this.form.curp = this.getCandidate.profile.curp;
            this.form.phone = this.getCandidate.profile.phone;
            this.form.email = this.getCandidate.profile.email;
            this.form.middle_name = this.getCandidate.profile.middle_name;
            this.form.second_last_name = this.getCandidate.profile.second_last_name;
            this.form.first_last_name = this.getCandidate.profile.first_last_name;
            this.form.site_id = this.getCandidate.profile.site_id;
            this.form.source_id = this.getCandidate.profile.source_id;
            this.form.referral_id = this.getCandidate.profile.referral_id;
            this.form.first_last_name = this.getCandidate.profile.first_last_name;
            this.form.id = this.getCandidate.profile.id;
            this.referralObject = this.getCandidate.profile.referral;
            this.form.gender = profile.gender;
            this.form.nationality = profile.nationality;
            this.form.dateOfBirth = profile.dateOfBirth;
            this.form.stateOfBirth = profile.stateOfBirth;
            if (profile.stateOfBirth !== '') {
                this.loadCities(profile.stateOfBirth, 'birth')
            }
            this.form.cityOfBirth = profile.cityOfBirth;
            this.form.rfc = profile.rfc;
            this.form.ssn = profile.ssn;
            this.form.umf = profile.umf;
            this.form.phoneTwo = profile.phoneTwo;
            this.form.emergencyName = profile.emergencyName;
            this.form.emergencyRelationship = profile.emergencyRelationship;
            this.form.emergencyPhone = profile.emergencyPhone;
            this.form.addressOne = profile.addressOne;
            this.form.addressTwo = profile.addressTwo;
            this.form.zipCode = profile.zipCode;
            this.form.stateSelected = profile.state;
            if (profile.state !== '') {
                this.loadCities(profile.state, 'address')
            }
            this.form.citySelected = profile.city;
            this.form.bilingual = profile.bilingual;
            this.form.maritalStatus = profile.maritalStatus;
            this.form.education = profile.education;
            this.form.specialty = profile.specialty;
            this.form.trainingType = this.getCandidate.profile.trainingType;
            this.form.fatherName = profile.fatherName;
            this.form.motherName = profile.motherName;
            this.form.paymentBank = profile.paymentBank;
            this.form.paymentMethod = profile.paymentMethod;
            this.form.paymentInterval = profile.paymentInterval;
            this.form.sdi = profile.sdi;
            this.form.sd = profile.sd;
            this.form.eligibleForLoan = profile.eligibleForLoan;
            this.form.requestedLoan = profile.requestedLoan;
            this.form.beneficiaryName = profile.beneficiaryName;
            this.form.beneficiaryRfc = profile.beneficiaryRfc;
            this.form.beneficiaryAddress = profile.beneficiaryAddress;
            this.form.startDate = profile.startDate;
            this.form.contractSigningDate = profile.contractSigningDate;
            this.form.scheduledHours = profile.scheduledHours;
            this.form.purchaseOrder = profile.purchaseOrder;
        }).catch((error) => {
            console.log(error);
            this.$fire({
                type: 'warning',
                title: `The selected candidate doesn't exist. If you think this is an error, please contact an administrator.`,
                showConfirmButton: true,
            }).then(() => {
                this.routeBack();
            });

        });
        this.fetchRecruiters();
        this.fetchTeamMembers();
    },
    computed: {
        ...mapGetters([
            'getCandidate',
            'getTeamMembers',
            'getRecruitersList',
            'employeeList',
            'stateList',
            'getCities',
        ]),
        activeClass: function () {
            return 'border-b-4 border-gray-700';
        },
        defaultClass: function () {
            return 'hover:underline';
        },
        employee_initials: function () {
            if (this.form.name !== '' && this.form.first_last_name !== '') {
                return this.form['name']?.charAt(0) + this.form['first_last_name']?.charAt(0);
            }
            return '';
        },
        errorClass: function () {
            return 'bg-red-50 border-red-200';
        },
        hasReferral: function () {
            return this.form.source_id === 2;
        },
        isLoading: function () {
            return this.loadingReferrals || this.loadingRecruiters;
        },
    },
    methods: {
        ...mapActions([
            'registerEMSCandidate',
            'getCandidateProfile',
            'fetchRecruiters',
            'fetchTeamMembers',
            'updateCandidate',
            'fetchStates',
            'fetchCitiesByState',
        ]),
        routeBack() {
            let routerParams;
            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'training')) {
                routerParams = {
                    name: 'recruitment.trainings.attendance',
                    params: { id: this.$route.query.training }
                };
            } else {
                routerParams = { name: 'recruitment.candidates' };
            }
            this.$router.push(routerParams);
        },
        numberOnly(fieldName) {
            if (typeof this.form[fieldName] !== 'string') {
                return;
            }

            this.form[fieldName].replace(/[^0-9]+/g, '');
        },
        nonRequiredInvalid(field) {
            return (
                this.$v.form[field].$invalid &&
                this.$v.form.$dirty &&
                this.form[field] !== ''
            );
        },
        customFormatter(date) {
            return moment(date).format("YYYY/MM/DD");
        },
        loadCities: function (selectedState, dropdownId) {
            this.selectState[dropdownId] = false;
            this.showLoadingCity[dropdownId] = true;
            this.showSelectCity[dropdownId] = false;
            let stateId = this.stateList.filter(x => x.stateName === selectedState)[0].id;
            this.fetchCitiesByState(stateId).then(() => {
                this.cityOptions[dropdownId] = JSON.parse(JSON.stringify(this.getCities));
                this.showLoadingCity[dropdownId] = false;
                this.showSelectCity[dropdownId] = true;
            });
        },
        beforeSubmit: function () {
            this.loading = true;
            this.$v.$touch();
            if (this.$v.form.$invalid) {
                return this.$fire({
                    type: 'error',
                    title: 'Fill the mandatory fields before submit.',
                    showConfirmButton: true
                }).then(() => {
                    this.loading = false;
                });
            }
            this.loading = true;

            const nullableForm = Object.fromEntries(
                Object.entries(this.form).map((x) => [x[0], x[1] === '' ? null : x[1]])
            );

            this.updateCandidate(nullableForm).then(() => {
                this.$fire({
                    type: 'success',
                    title: `The candidate has been stored successfully`,
                    showConfirmButton: true,
                });
                this.loading = false;
                this.routeBack();
            })
                .catch((error) => {
                    let message = error.response.data.message ?? 'Something went wrong';
                    this.loading = false;
                    this.$fire({
                        type: 'warning',
                        title: `Something went wrong.Error message: ${message}`,
                        showConfirmButton: true,
                    });

                })


        },
        updateForm: function (form) {
            this.form.name = form.name;
            this.form.first_last_name = form.first_last_name;
            this.form.second_last_name = form.second_last_name;
            this.form.middle_name = form.middle_name;
            this.form.source_id = form.source_id;
            this.form.middle_name = form.middle_name;
            this.form.referral_id = form.referral_id;
            this.form.recruiter_id = form.recruiter_id;
            this.form.site_id = form.site_id;
        },
        getSwitchStyle: function (active = true) {
            return active ? 'text-green-300  bg-green-100 ring-2 ring-green-200 rounded-sm px-2 text-xs' : 'text-gray-400 text-xs cursor-pointer hover:text-gray-600'
        },
        setSource: function (value) {
            this.form.source_id = value;
            if (value !== 2) {
                this.form.referral_id = '';
                this.referralObject = null;
            }

        }
    },
    validations: {
        form: {
            name: { required },
            first_last_name: { required },
            source_id: { required },
            site_id: { required },
            referral_id: {
                required: requiredIf(function () {
                    return this.form.source_id === 2;
                })
            },
            curp: {
                required: requiredIf(function () {
                    return this.form.phone === '' || this.form.email === '';
                }),
                format: function () {
                    let regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

                    return (this.form.phone !== '' && this.form.email !== '') || this.form.curp?.length === 18 && regex.test(this.form.curp);
                }
            },
            rfc: {
                format: function () {
                    let regex = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;

                    return (this.form.rfc?.length === 13 && regex.test(this.form.rfc)) || this.form.rfc?.length === 0;
                }
            },
            beneficiaryRfc: {
                format: function () {
                    let regex = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;

                    return (this.form.rfc?.length === 13 && regex.test(this.form.rfc)) || this.form.rfc?.length === 0;
                }
            },
            phone: {
                numeric,
                required: requiredIf(function () {
                    return this.form.curp === '';
                }),
                length: function () {
                    return this.form.curp !== '' || this.form.phone.length === 10;
                }
            },
            phoneTwo: {
                numeric,
                length: function () {
                    return [10, 0].includes(this.form.phoneTwo.length);
                }
            },
            email: {
                required: requiredIf(function () {
                    return this.form.curp === '';
                }),
                format: function (value) {
                    return this.form.curp !== '' || email(value);
                }
            },
            ssn: {
                format: function () {
                    let isNum = /^\d+$/.test(this.form.ssn);
                    return (this.form.ssn.length === 11 && isNum) || this.form.ssn.length === 0;
                }
            },
            dateOfBirth: {},
            emergencyPhone: {
                numeric,
                length: function () {
                    return [10, 0].includes(this.form.emergencyPhone.length);
                }
            },
        }
    },
    watch: {
        'form.phone': function () {
            this.numberOnly('phone')
        },
        'form.phoneTwo': function () {
            this.numberOnly('phoneTwo')
        },
        'form.emergencyPhone': function () {
            this.numberOnly('emergencyPhone')
        },
        'form.zipCode': function () {
            this.numberOnly('zipCode')
        },
        'form.ssn': function () {
            this.numberOnly('ssn')
        },
        'form.curp': function () {
            this.form.curp = this.form.curp?.toUpperCase();
        },
        'form.rfc': function () {
            this.form.rfc = this.form.rfc?.toUpperCase();
        },
        'form.beneficiaryRfc': function () {
            this.form.beneficiaryRfc = this.form.beneficiaryRfc?.toUpperCase();
        },
        'form.umf': function () {
            this.form.umf = this.form.umf?.toUpperCase();
        },
        referralObject: function (newVal) {
            this.form.referral_id = newVal?.id;
        }
    },

}
</script>

<style>
.shadow-fade {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>