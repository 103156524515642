<template>
    <transition name="slide-fade">
        <div class="w-60 shadow-sm bg-white overflow-hidden h-full flex flex-column z-10" id="sidebar_wrapper">

            <div class="w-full flex align-items-center justify-content-center h-20">
                <a href="/dashboard" class="h-full py-3">
                    <img src="../../assets/centris-logo.png" alt="Workflow" class="h-full">
                </a>
            </div>

            <ul class="flex flex-col list-none p-2 pb-1 m-0 overflow-y-scroll size-full">
                <sidebar-link title="Dashboard" link="base.dashboard">
                    <i class="bi bi-speedometer2"></i>
                </sidebar-link>
                <sidebar-link title="Buzón de Q&S"
                    v-if="itemContains('SystemAdministrator') || itemContains('Engagement')"
                    to="/employees/reviewsuggestionbox" link="emp.suggestions.rev">
                    <i class="bi bi-mailbox"></i>
                </sidebar-link>
                <sidebar-link title="Sugerencias & Quejas" link="emp.suggestions">
                    <i class="bi bi-envelope-open"></i>
                </sidebar-link>
                <sidebar-link title="Open positions" :badge="getPositions.length" link="positions.index">
                    <i class="bi bi-inboxes"></i>
                </sidebar-link>
                <sidebar-link :active="false" title="Tasklist" link="tasklist.tasklist_main" register="2025-03-10"> 
                    <i class="bi bi-clipboard-check"></i>
                </sidebar-link>
                <sidebar-link :active="false" title="Work Calendar" link="emp.workCalendar" register="2025-04-02">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                    </svg>
                </sidebar-link>
              
                <accordion>
                    <template slot="title">
                        Message Center
                    </template>
                    <sidebar-link title="New Message" link="msg.create"
                        v-if="itemContains('SystemAdministrator') || itemContains('Staff') || itemContains('HelpDesk')">
                        <i class="bi bi-chat-left-text"></i>
                    </sidebar-link>
                    <sidebar-link title="Management" link="msg.manager"
                        v-if="itemContains('SystemAdministrator') || itemContains('Staff') || itemContains('HelpDesk')">
                        <i class="bi bi-view-list"></i>
                    </sidebar-link>
                    <sidebar-link title="Message Center" link="msg.center">
                        <i class="bi bi-list-check"></i>
                    </sidebar-link>
                </accordion>

                <accordion>
                    <template slot="title">
                        Resources
                    </template>
                    <sidebar-link title="Training" link="trainings">
                        <i class="bi bi-easel2"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Survey Management V2" link="surveys.index"
                        v-if="itemContains('SystemAdministrator')">
                        <i class="bi bi-clipboard-check"></i>
                    </sidebar-link>
                    <a href="https://reports.centris.cloud/login"
                        class="block text-left text-ellipsis text-nowrap hover:bg-gray-100 px-3 py-2 rounded text-body"
                        target="_blank">
                        <span class="inline-block"><i class="bi bi-bar-chart"></i></span>
                        <span class="inline-block ml-2">
                            Grafana
                        </span>
                    </a>
                    <a href="https://centrisinfo.helpdocsonline.com/login"
                        class="block text-left text-ellipsis text-nowrap text-body hover:bg-gray-100 px-3 py-2 rounded"
                        target="_blank">
                        <span class="inline-block"><i class="bi bi-book"></i></span>
                        <span class="inline-block ml-2">Wiki</span>
                    </a>
                </accordion>

                <!-- #Tickets -->
                <accordion
                    v-if="itemContains('SystemAdministrator') || itemContains('Staff') || itemContains('HelpDesk')">
                    <template slot="title">
                        Tickets
                    </template>
                    <sidebar-link :active="false" title="My Tickets" link="ticket.mytickets">
                        <i class="bi bi-life-preserver"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Management" link="ticket.ticketsassignation"
                        v-if="itemContains('SystemAdministrator') || itemContains('HelpDesk')">
                        <i class="bi bi-card-checklist"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Review" link="ticket.ticketsreview"
                        v-if="itemContains('SystemAdministrator') || itemContains('HelpDesk')">
                        <i class="bi bi-check-circle"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Queue Management" link="tickets.management.queue"
                        v-if="itemContains('SystemAdministrator') || itemContains('HelpDesk')">
                        <i class="bi bi-bezier2"></i>
                    </sidebar-link>
                </accordion>

                <!-- #Forms -->
                <accordion v-if="itemContains('SystemAdministrator')">
                    <template slot="title">
                        Forms
                    </template>
                    <sidebar-link :active="false" title="Maintenance Form" link="forms.maintenance">
                        <i class="bi bi-envelope-paper"></i>
                    </sidebar-link>
                </accordion>

                <!--#Employees-->
                <accordion
                    v-if="itemContains('SystemAdministrator') || itemContains('WF') || itemContains('IncidencesManagement') || itemContains('HR') || itemContains('Staff') || itemContains('Payroll')">
                    <template slot="title">
                        Employees
                    </template>
                    <sidebar-link title="Register Employee" link="emp.create"
                        v-if="itemContains('SystemAdministrator') ">
                        <i class="bi bi-person-plus"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Search employee" link="emp.search"
                        v-if="itemContains('SystemAdministrator') || itemContains('WF') || itemContains('HR') || itemContains('Staff')">
                        <i class="bi bi-search"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Documents" link="documents.management"
                        v-if="itemContains('SystemAdministrator') || itemContains('HR')">
                        <i class="bi bi-file-text"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Incidences" link="incidences.management"
                        v-if="itemContains('SystemAdministrator') || itemContains('WF') || itemContains('IncidencesManagement')">
                        <i class="bi bi-exclamation-circle"></i>
                    </sidebar-link>
                    <sidebar-link title="Medical Leaves" link="msl.index"
                        v-if="itemContains('SystemAdministrator') || itemContains('HR') || itemContains('Payroll')">
                        <i class="bi bi-heart-pulse"></i>
                    </sidebar-link>
                    <sidebar-link title="Teams" link="teams.index"
                        v-if="itemContains('SystemAdministrator') || itemContains('Helpdesk')">
                        <i class="bi bi-people"></i>
                    </sidebar-link>
                    <sidebar-link title="Terminations" link="terminations.index"
                        v-if="itemContains('SystemAdministrator') || itemContains('HR') || itemContains('Payroll')">
                        <i class="bi bi-person-slash"></i>
                    </sidebar-link>
                </accordion>

                <!--#Candidates-->
                <accordion v-if="itemContains('SystemAdministrator') || itemContains('Trainer') || itemContains('WF')">
                    <template slot="title">
                        Candidates
                    </template>
                    <sidebar-link title="Candidates" link="recruitment.candidates">
                        <i class="bi bi-person-badge"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Purchase Orders" link="recruitment.po" register="2025-03-10"> 
                        <i class="bi bi-ticket-perforated"></i>
                    </sidebar-link>
                    <sidebar-link title="Trainings" link="trainings.index">
                        <i class="bi bi-easel2"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="New Hire Approval" link="recruitment.newhire.approval" register="2025-03-10">
                        <i class="bi bi-person-badge"></i>
                    </sidebar-link>
                </accordion>

                <!--#Clocker-->
                <accordion>
                    <template slot="title">
                        Clocker Utilities
                    </template>
                    <!-- <sidebar-link :active="false" title="Clocker Attendance" link="clocker.attendance" register="2025-02-10"
                        v-if="itemContains('SystemAdministrator') || itemContains('Watchmen') || itemContains('Staff')">
                        <i class="bi bi-list-check"></i>
                    </sidebar-link> -->
                    <sidebar-link :active="false" title="Today's Clocker Hours" link="clocker.today">
                        <i class="bi bi-watch"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Clocker Adjustments" link="clocker.adjustments"
                        v-if="itemContains('SystemAdministrator') || itemContains('Watchmen') || itemContains('Staff')">
                        <i class="bi bi-stopwatch"></i>
                    </sidebar-link>
                </accordion>

                <accordion>
                    <template slot="title">
                        Payroll Utilities
                    </template>
                    <sidebar-link :active="false" title="Hours Review" link="hoursReview.current">
                        <i class="bi bi-alarm"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Supervisor Hours" link="clocker.supervisor.hours"
                        v-if="itemContains('SystemAdministrator') || itemContains('Supervisor')">
                        <i class="bi bi-clock"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Hours review AM" link="clocker.manager.hours"
                        v-if="itemContains('SystemAdministrator') || itemContains('AccountManager')">
                        <i class="bi bi-clock-history"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Watchmen Dashboard" link="watchmen.payroll.dashboard"
                        v-if="isGranted('SystemAdministrator|Watchmen')">
                        <i class="bi bi-eye"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Payroll Adjustments" link="payroll.adjustments"
                        v-if="isGranted('SystemAdministrator|Watchmen')">
                        <i class="bi bi-cash-stack"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Approved Days" link="payroll.approvedays"
                        v-if="itemContains('SystemAdministrator')">
                        <i class="bi bi-calendar-check"></i>
                    </sidebar-link>
                </accordion>

                <!--#Special Payroll-->
                <accordion v-if="itemContains('SystemAdministrator') || itemContains('Staff')">
                    <template slot="title">
                        Special Payroll
                    </template>
                    <sidebar-link :active="false" title="Special Request" link="payroll.specialpayroll">
                        <i class="bi bi-coin"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Request Management" link="payroll.specialpayrollmanagement"
                        v-if="itemContains('SystemAdministrator')">
                        <i class="bi bi-card-list"></i>
                    </sidebar-link>
                </accordion>

                <!--#Feedback-->
                <accordion>
                    <template slot="title">
                        Feedback
                    </template>
                    <sidebar-link :active="false" title="Management" link="feedback.management"
                        v-if="itemContains('SystemAdministrator')">
                        <i class="bi bi-card-list"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="My feedback" link="feedback">
                        <i class="bi bi-list-check"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Give a feedback" link="feedback.new"
                        v-if="itemContains('SystemAdministrator') || itemContains('Staff')">
                        <i class="bi bi-chat-text"></i>
                    </sidebar-link>
                </accordion>

                <accordion v-if="itemContains('SystemAdministrator') || itemContains('HR') || itemContains('Staff')">
                    <template slot="title">Utilities</template>
                    <sidebar-link :active="false" title="Create Forms" link="utilities.formbuilder"
                        v-if="itemContains('SystemAdministrator')">
                        <i class="bi bi-input-cursor"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Dashboard Manager" link="utilities.manager.dashboard"
                        v-if="itemContains('SystemAdministrator') || itemContains('HR')">
                        <i class="bi bi-images"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="NewsFeed Manager" link="utilities.manager.newsfeed"
                        v-if="itemContains('SystemAdministrator') || itemContains('HR')">
                        <i class="bi bi-chat-left-quote"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="RSA Management" link="rsa.search"
                        v-if="itemContains('SystemAdministrator') || itemContains('Staff')">
                        <i class="bi bi-key"></i>
                    </sidebar-link>
                </accordion>

                <!--#Westmed-->
                <accordion v-if="itemContains('SystemAdministrator') || itemContains('WestmedEmail')">
                    <template slot="title">
                        Westmed
                    </template>
                    <sidebar-link :active="false" title="Emails" link="westmed.emails">
                        <i class="bi bi-envelope"></i>
                    </sidebar-link>
                </accordion>

                <accordion v-if="itemContains('SystemAdministrator')">
                    <template slot="title">Inventory</template>
                    <sidebar-link :active="false" title="Search" link="inventory.search">
                        <i class="bi bi-search"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Add Item" link="inventory.new">
                        <i class="bi bi-plus-circle"></i>
                    </sidebar-link>
                </accordion>

                <accordion v-if="itemContains('SystemAdministrator')">
                    <template slot="title">Reports (Beta Version)</template>
                    <sidebar-link :active="false" title="Billing Report" link="reports.billing">
                        <i class="bi bi-receipt"></i>
                    </sidebar-link>
                </accordion>

                <accordion
                    v-if="itemContains('SystemAdministrator') || itemContains('AccountManager') || itemContains('HR') || itemContains('Director') || itemContains('Payroll')">
                    <template slot="title">Organization</template>
                    <sidebar-link :active="false" title="Headcount Management" link="headcount.management">
                        <i class="bi bi-clock-history"></i>
                    </sidebar-link>
                    <sidebar-link :active="false" title="Management" link="organization.management"
                        class="flex items-center hover:no-underline">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                        </svg>

                    </sidebar-link>
                </accordion>
            </ul>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SidebarLink from "@/views/Utilities/SidebarLink";
import Accordion from "@/views/Utilities/Accordion";
import debounce from 'debounce';
import permissions from '@/config/mixins'
import auth from "@/config/user"
export default {
    name: "Sidebar",
    mixins: [permissions],
    components: {
        SidebarLink,
        Accordion
    },
    data() {
        return {
            CurrentPermissions: '',
            CurrentCampaign: '',
        }
    },
    props: {
        visible: Boolean
    },
    methods: {
        ...mapActions(['fetchPositions']),
        toggle: debounce(function () {
            this.$emit('toggle');
        }, 250),
        itemContains(n) {
            return this.hasPermission(n)
        }
    },
    computed: {
        ...mapGetters(['getPositions']),
        isVisible: function () {
            return this.visible;
        }
    },
    created() {
        if (auth.currentUser.token) {
            this.fetchPositions().then(() => {
                this.getPositions.forEach(item => {
                    if (document.getElementById('modal-changepassword').length == 0) {
                        this.$toasted.show("New Position: " + item.title, {
                            theme: "toasted-primary",
                            icon: 'error',
                            position: "bottom-right",
                            duration: null,
                            action: [
                                {
                                    text: 'Open Positions',
                                    onClick: (e, toastObject) => {
                                        this.$router.push({ name: 'positions.index' });
                                        toastObject.goAway(0);
                                    }
                                }, {
                                    text: 'Ok',
                                    onClick: (e, toastObject) => {
                                        toastObject.goAway(0);
                                    }
                                }
                            ],
                        });
                    }
                });
            });
        }
    },
    mounted() {
        /*this.CurrentPermissions = auth.currentUser.roles;
        this.CurrentCampaign = auth.currentUser.campaign;*/
    },
}
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0.325rem;
    padding: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    height: 50px !important;
    background-color: #a3a3a3;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.075);
}
</style>