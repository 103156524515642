<template>
  <div
      class="flex flex-col w-full h-[calc(100vh-7rem)] overflow-hidden p-2 bg-white rounded-lg shadow-sm"
  >
    <div class="flex flex-col gap-y-2 size-full">
      <div>
        <view-selector v-model="mode"/>
      </div>
      <keep-alive>
        <calendar-view
            :disabled="incidentsIsLoading"
            v-if="mode === 'calendarView'"
        />
      </keep-alive>
      <keep-alive>
        <list-view v-if="mode === 'listView'"/>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import CalendarView
    from "@/views/employees/IncidentManager/CalendarView/CalendarView.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ListView
    from "@/views/employees/IncidentManager/ListView/ListView.vue";
import ViewSelector
    from "@/views/employees/IncidentManager/ViewSelector/ViewSelector.vue";

export default {
    name: 'IncidentManager',
    components: {ViewSelector, ListView, CalendarView},

    data() {
        return {
            mode: this.$route.query.mode ?? 'calendarView',
        };
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'selectedEmployees',
                'incidentsIsLoading',
            ]
        )
    },

    methods: {
        ...mapActions(
            'incidentManager',
            [
                'fetchIncidentTypes',
                'fetchIncidents',
            ]
        ),
        ...mapMutations(
            'incidentManager',
            [
                'setSelectedEmployees'
            ]
        ),
    },

    created() {
        this.$emit('set-title', 'Incidents')
        this.setSelectedEmployees(this.emp_id);
    },
}
</script>