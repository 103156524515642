<template>
  <base-incident-modal
      ref="baseModal" @lock="lockCalendar" @unlock="unlockCalendar"
  >
    <div
        class="flex flex-col text-left bg-white w-[450px]"
    >
      <header
          class="py-2 px-3 flex flex-row justify-between items-center bg-[#5d7a9d]"
      >
        <div class="font-semibold text-sm capitalize text-white">
          {{ activeIncidentType.name }}
        </div>
        <button
            class="flex items-center justify-center border-[1px] bg-red-200
            hover:bg-red-300 border-red-400 rounded-full size-5 text-red-400
            text-sm"
            @click.prevent="closeModal"
        >
          <span>x</span>
        </button>
      </header>
      <hr>
      <div class="flex flex-col gap-y-4 m-3 mx-12 justify-between h-full">
        <div class="flex flex-col gap-y-4">
          <div
              class="flex flex-row items-end font-medium text-sm space-x-2 h-8"
          >
            <div class="flex flex-row items-center h-full">
              <svg
                  class="size-6 content-center" fill="none"
                  stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
                class="border-b-[1px] border-gray-300 text-center
            content-center text-md h-full w-32 cursor-not-allowed"
            >
              {{ humanStartDate }}
            </div>
            <div class="text-sm h-full content-center">
              To
            </div>
            <div
                class="border-b-[1px] border-gray-300 text-center
            content-center text-md h-full w-32 cursor-not-allowed"
            >
              {{ humanEndDate }}
            </div>
          </div>
          <div
              class="flex flex-row items-end font-medium text-sm space-x-2 h-8"
          >
            <div class="flex flex-row items-center h-full">
              <svg
                  class="size-6" fill="none"
                  stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="flex flex-row space-x-2" v-if="!isAllDay">
              <timepicker
                  v-model="startTime"
                  :class="{
                    'border-gray-300 hover:border-gray-400': !(triedSubmission && durationIsInvalid && startTimeIsInvalid),
                    'border-red-600 hover:border-red-700': triedSubmission && durationIsInvalid && startTimeIsInvalid
                }"
                  :disabled="!durationIsInvalid"
                  :hide-clear-button="!endTimeIsInvalid"
                  class="border-b-[1px] w-32"
                  close-on-complete
                  format="HH:mm"
                  input-class="!border-none border-gray-300 !text-md h-full text-center !w-full"
                  placeholder="Start Time"
              />
              <div class="text-sm h-full content-center">
                To
              </div>
              <timepicker
                  v-model="endTime"
                  :class="{
                    'border-gray-300 hover:border-gray-400': !(triedSubmission && durationIsInvalid && endTimeIsInvalid),
                    'border-red-600 hover:border-red-700': triedSubmission && durationIsInvalid && endTimeIsInvalid
                }"
                  :disabled="startTimeIsInvalid"
                  :hour-range="availableEndHourRange"
                  :minute-range="availableEndMinuteRange"
                  class="border-b-[1px] w-32"
                  close-on-complete
                  format="HH:mm"
                  input-class="!border-none border-gray-300 !text-md h-full text-center !w-full"
                  placeholder="End Time"
              />
            </div>
            <div
                v-if="isAllDay"
                class="w-32 text-center content-center border-gray-300
                border-b-[1px] h-full"
            >
              All Day
            </div>
          </div>
          <div
              v-if="!isAllDay"
              class="flex flex-row items-end font-medium text-sm space-x-2 h-8"
          >
            <div class="flex flex-row justify-center items-center h-full w-6">
              <div class="text-sm h-full content-center text-center">
                or
              </div>
            </div>
            <timepicker
                v-model="duration"
                :class="{
                    'border-gray-300 hover:border-gray-400': !(triedSubmission && durationIsInvalid && startTimeIsInvalid),
                    'border-red-600 hover:border-red-700': triedSubmission && durationIsInvalid && startTimeIsInvalid
                }"
                :disabled="!startTimeIsInvalid"
                class="border-b-[1px] w-32"
                close-on-complete
                format="HH:mm"
                input-class="!border-none border-gray-300 !text-md h-full text-center !w-full"
                placeholder="Duration"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-between space-x-2">
              <div class="flex flex-row items-center h-full">
                <svg
                    class="size-6" fill="none"
                    stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
              </div>
              <textarea
                  v-model="description"
                  :class="{'ring-1 ring-red-600': description.length === 0 && triedSubmission}"
                  :disabled="isLoading"
                  class="resize-none w-full bg-gray-50 rounded-lg py-1 px-3
                border-2 border-gray-100"
                  cols="30"
                  placeholder="Description"
                  rows="2"
              />
            </div>
            <div class="relative">
              <span
                  v-if="description.length === 0 && triedSubmission"
                  class="text-red-600 text-xs absolute top-1 left-8"
              >
                You must add a description
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-end gap-x-8">
          <button
              :disabled="isLoading"
              class="text-[#7293B9] hover:underline"
              @click.prevent="closeModal"
          >
            Cancel
          </button>
          <button
              class="rounded-lg py-2 px-3 bg-[#7293B9] text-white"
              @click="submitRequest"
              @disabled="isLoading"
          >
            Send Request
          </button>
        </div>
      </div>
    </div>
  </base-incident-modal>
</template>
<script>
import BaseIncidentModal
    from "@/views/employees/IncidentManager/CalendarView/InteractiveCalendar/CalendarModals/BaseIncidentModal.vue";
import {mapActions, mapGetters} from "vuex";
import Timepicker from 'vue2-timepicker';
import Datepicker from 'vue2-datepicker';
import {format, max, min,} from 'date-fns';

/**
 * This is the confirmation modal for submitting an incident.
 *
 * @component
 */

export default {
    name: "incidentCreationModal",

    components: {BaseIncidentModal, Timepicker, Datepicker},

    props: {
        hoveredCells: {
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            description: "",
            startDate: null,
            endDate: null,
            startTime: {
                HH: "",
                mm: "",
            },
            endTime: {
                HH: "",
                mm: "",
            },
            duration: {
                HH: "",
                mm: "",
            },
            triedSubmission: false,
        };
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'activeIncidentType',
                'selectedEmployees'
            ]
        ),
        
        humanStartDate() {
            return format(this.startDate, "MMMM do yyyy");
        },
        
        humanEndDate() {
            return format(this.endDate, "MMMM do yyyy")
        },
        
        isAllDay() {
            return this.activeIncidentType.allDay === true;
        },

        availableEndHourRange() {
            if (!this.isValidTime(this.startTime))
                return [...Array(24).keys()];

            const startHour = Number(this.startTime.HH);
            return [...Array(24).keys()].filter((hour) => hour >= startHour);
        },

        availableEndMinuteRange() {
            if (!this.isValidTime(this.startTime))
                return [...Array(60).keys()];

            const startHour = Number(this.startTime.HH);
            const startMinute = Number(this.startTime.mm);

            const endHour = this.isValidTime(this.end_time)
                ? Number(this.end_time.HH)
                : null;

            if (startHour === endHour) {
                return [...Array(60).keys()].filter(
                    (minute) => minute >= startMinute
                );
            }

            return [...Array(60).keys()];
        },

        startTimeIsInvalid() {
            return !this.startTime || !this.isValidTime(this.startTime);
        },

        endTimeIsInvalid() {
            return !this.endTime || !this.isValidTime(this.endTime);
        },

        durationIsInvalid() {
            return !this.duration || !this.isValidTime(this.duration);
        },
    },

    watch: {
        hoveredCells(newVal) {
            if (newVal.length > 0) {
                this.startDate = min(newVal);
                this.endDate = max(newVal);
            }
        },
    },

    methods: {
        ...mapActions(
            'incidentManager',
            [
                'sendRequest',
                'fetchIncidents',
            ]
        ),

        isValidTime(time) {
            return (
                time
                && time.HH !== undefined
                && time.mm !== undefined
                && !isNaN(Number(time.HH))
                && !isNaN(Number(time.mm))
                && time.HH !== ""
                && time.mm !== ""
            );
        },

        safeNumber(value) {
            return value === '' || value == null ? 0 : Number(value);
        },

        durationInSeconds(time) {
            if (time === undefined) {
                return null;
            }
            
            const hours = this.safeNumber(time?.HH) * 3600;
            const minutes = this.safeNumber(time?.mm) * 60;
            const seconds = this.safeNumber(time?.ss);
            return hours + minutes + seconds;
        },
        
        defaultTimeString(value) {
            return value === '' || value === undefined ? "00" : value;
        },
        
        timeString(time) {
            const hours = this.defaultTimeString(time?.HH);
            const minutes = this.defaultTimeString(time?.mm);
            const seconds = this.defaultTimeString(time?.ss);
            
            const isInvalid = ![hours, minutes, seconds].some(x => {return x !== '00';});
            if (isInvalid) {
                return null;
            }
            
            return `${hours}:${minutes}:${seconds}`;
        },
        
        submitRequest() {
            this.triedSubmission = true;

            const noReference = this.startTimeIsInvalid && this.durationIsInvalid;
            const missingEnd = !this.startTimeIsInvalid && this.endTimeIsInvalid;
            const invalidDescription = this.description === "";

            if (((noReference || missingEnd) && !this.isAllDay) || invalidDescription) {
                return;
            }
            
            const durInSecs = this.durationInSeconds(this.duration);

            const payload = {
                emp_id: this.selectedEmployees,
                type_id: this.activeIncidentType.id,
                description: this.description,
                start_date: format(this.startDate, 'yyyy-MM-dd'),
                end_date: format(this.endDate, 'yyyy-MM-dd'),
                daily_start_time: this.timeString(this.startTime),
                daily_end_time: this.timeString(this.endTime),
                duration_seconds: durInSecs !== 0 ? durInSecs : null,
                status: "PENDING",
                created_by: this.emp_id,
            };

            this.isLoading = true;
            this.sendRequest(payload).then(
                () => {
                    this.$emit('update');
                    return this.$fire({
                        title: "Success!",
                        text: "Your request is currently pending " +
                            "approval by a staff member.",
                        type: "success",
                        showConfirmButton: true,
                    });
                }
            ).catch(
                () => {
                    this.$fire({
                        title: "Something went wrong",
                        icon: "error",
                        showConfirmButton: true,
                    })
                }
            ).finally(
                () => {
                    this.isLoading = false;
                    this.closeModal();
                }
            );
        },
        
        resetFields() {
            this.description = "";
            this.startTime = {HH: "", mm: ""};
            this.endTime = {HH: "", mm: ""};
            this.duration = {HH: "", mm: ""};
            
            const defaultDuration = this.activeIncidentType.defaultDuration;
            if (defaultDuration !== null) {
                this.duration.HH = Math.floor(defaultDuration / 3600);
                this.duration.mm = Math.floor((defaultDuration % 3600) / 60);
                this.duration.ss = defaultDuration % 60;
            }
        },

        openModal(element) {
            this.triedSubmission = false;
            this.resetFields();
            this.$refs.baseModal.openModal(element);
        },

        closeModal() {
            this.$refs.baseModal.closeModal();
        },

        lockCalendar() {
            this.$emit('lock-calendar');
        },

        unlockCalendar() {
            this.$emit('unlock-calendar');
        }
    },

}
</script>