<template>
    <a href="#" class="flex justify-start items-center text-ellipsis text-nowrap hover:bg-gray-100 px-3 py-2 rounded relative !no-underline"
        :class="isActive ? 'bg-gradient-to-r from-blue-800 to-blue-500 text-white' : 'text-body'"
        @click.prevent="goToTarget">
        <span class="inline-block">
            <slot></slot>
        </span>
        <span class="inline-block ml-2">{{ title }}</span>
        <span v-if="badge > 0"
            class="flex items-center justify-center w-5 h-5 rounded-full bg-red-600 font-semibold text-white text-xs float-right">
            {{ badge }}
        </span>
        <span v-if="isNew"
            class="bg-c-primary text-white text-xs font-semibold rounded-lg px-1 absolute -right-1 bottom-0 -rotate-6 drop-shadow-md"
            :class="isActive ? 'opacity-100' : 'opacity-70'">
            New <i class="bi bi-stars text-yellow-200"></i>
        </span>
    </a>
</template>

<script>
export default {
    name: "SidebarLink",
    props: {
        active: {
            default: false,
            type: Boolean
        },
        link: {
            default: 'home',
            type: String
        },
        title: {
            default: 'Home',
            type: String
        },
        badge: {
            default: 0,
            type: Number
        },
        register: {
            required: false,
            type: String
        }
    },
    computed: {
        isActive: function () {
            return this.$route.name === this.link;
        },

        isNew() {
            if (!this.register) return;
            const register_date = new Date(this.register);
            let fifteen_days_after = new Date(this.register);
            fifteen_days_after.setDate(register_date.getDate() + 10);
            return register_date.getDate() <= fifteen_days_after.getDate();
        }
    },
    methods: {
        goToTarget: function () {
            this.$router.push({ name: this.link });
        }
    },
    data() {
        return {
        }
    }
}
</script>