<template>
    <div class="newsfeed-container">
        <transition name="fade">
            <div v-if="isLoading" class="flex items-center justify-center p-10">
                <div class="loading-spinner"></div>
            </div>
            <div v-else>
                <hooper :settings="hooperSettings">
                    <slide v-for="entry in orderedEntries" :key="entry.id">
                        <newsfeed-slide :entryTitle="entry.title" :newsfeedImage="entry.image"
                            :entryDescription="entry.description" @reaction="handleReaction" />
                        <div class="reaction-bar mt-2 flex justify-around">
                            <button class="reaction-btn" @click="emitReaction('👍')">👍</button>
                            <button class="reaction-btn" @click="emitReaction('🎉')">🎉</button>
                            <button class="reaction-btn" @click="emitReaction('🙌')">🙌</button>
                            <button class="reaction-btn" @click="emitReaction('❤️')">❤️</button>
                            <button class="reaction-btn" @click="emitReaction('💡')">💡</button>
                            <button class="reaction-btn" @click="emitReaction('😂')">😂</button>
                            <button class="reaction-btn" @click="emitReaction('🔥')">🔥</button>
                        </div>
                    </slide>
                </hooper>
            </div>
        </transition>
    </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import newsfeedSlide from '@/views/newsfeed/slide/index';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    components: {
        Hooper,
        Slide,
        'newsfeed-slide': newsfeedSlide,
    },
    data() {
        return {
            hooperSettings: {
                itemsToShow: 1,
                vertical: true,
                autoPlay: true,
                mouseDrag: false,
                touchDrag: false,
            },
            isLoading: true,
        };
    },
    computed: {
        ...mapState(['NewsFeed']),
        ...mapGetters(['getAllEntries']),
        orderedEntries() {
            const entries = Array.isArray(this.getAllEntries)
                ? this.getAllEntries.slice().reverse()
                : [];
            if (entries.length === 0) {
                // Use placeholder entry if no entries are found.
                return [{
                    id: 1,
                    title: "Slow news day",
                    image: "https://placecats.com/300/200?r",
                    description: "There doesn't seem to be much going on."
                }];
            }
            return entries;
        },
    },
    methods: {
        ...mapActions(['fetchAllEntries']),
        handleReaction(reaction) {
            // Re-emit the reaction event so parent components (like dashboard.vue) can catch it.
            this.$emit('reaction', reaction);
        },
        emitReaction(emoji) {
            // Emit the reaction directly when a reaction bar button is clicked.
            this.$emit('reaction', emoji);
        },
    },
    created() {
        this.fetchAllEntries().then(() => {
            this.isLoading = false;
        });
    },
};
</script>

<style scoped>
.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.newsfeed-container {
    margin: 0 auto;
    max-width: 800px;
}

.reaction-bar {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 0.5rem;
}

.reaction-btn {
    position: relative;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.reaction-btn:hover {
    transform: scale(1.5);
}

.reaction-btn::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.2s;
    white-space: nowrap;
    pointer-events: none;
}

.reaction-btn:hover::after {
    opacity: 1;
}
</style>