<template>
    <div class="main mb-3">
        <div class="flex flex-col space-y-2"> 
            <div v-if="hideProgress">
                <div class="mt-3">
                    <div>
                        <b-progress variant="info" :striped="striped" :animate="animate" class="m3"  height="1.5rem" :max="max">
                            <b-progress-bar :value="value" :label="`Loading Profile ${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </div>
    
            <div v-else class="space-y-2 space-x-3 page">
                <div class="profile-size bg-white flex">
                    <profile-information class="m-auto"/>
                    <svg xmlns="http://www.w3.org/2000/svg" class="svg_wave_1" viewBox="0 0 1440 320"><path fill="#6074ff" fill-opacity="1" d="M0,288L24,256C48,224,96,160,144,149.3C192,139,240,181,288,202.7C336,224,384,224,432,202.7C480,181,528,139,576,117.3C624,96,672,96,720,117.3C768,139,816,181,864,202.7C912,224,960,224,1008,202.7C1056,181,1104,139,1152,133.3C1200,128,1248,160,1296,160C1344,160,1392,128,1416,112L1440,96L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path></svg>
                </div>
                <div class="flex flex-1 flex-col">
                    <div name="tabs" class="flex flex-row space-x-1">
                        <button class="rounded-t-2xl bg-gray-300 hover-gray-200 py-1 px-3 uppercase text-xs" :class="{'bg-white shadow-tab' : window === 'personal'}" @click="changeWindow('personal')">
                            <i class="bi bi-person-circle text-xl"></i>
                        </button>
                        <button class="rounded-t-2xl bg-gray-300 hover-gray-200 py-1 px-3 uppercase text-xs" :class="{'bg-white shadow-tab' : window === 'professional'}" @click="changeWindow('professional')">
                            <i class="bi bi-mortarboard text-xl"></i>
                        </button>
                        <button class="rounded-t-2xl bg-gray-300 hover-gray-200 py-1 px-3 uppercase text-xs" :class="{'bg-white shadow-tab' : window === 'emergency'}" @click="changeWindow('emergency')">
                            <i class="bi bi-telephone text-xl"></i>
                        </button>
                        <button class="rounded-t-2xl bg-gray-300 hover-gray-200 py-1 px-3 uppercase text-xs" :class="{'bg-white shadow-tab' : window === 'documents'}" @click="changeWindow('documents')">
                            <i class="bi bi-archive text-xl"></i>
                        </button>
                        <button class="rounded-t-2xl bg-gray-300 hover-gray-200 py-1 px-3 uppercase text-xs" :class="{'bg-white shadow-tab' : window === 'passwordVault'}" @click="changeWindow('passwordVault')">
                            <i class="bi bi-safe text-xl"></i>
                        </button>
                    </div>
                    <div name="information" class="bg-white flex-1 rounded-b-xl rounded-r-xl h-full w-full overflow-hidden">
                        <personal-information role="e" v-if="window === 'personal'"/>
                        <professional-information role="e" v-if="window === 'professional'"/>
                        <emergency-contact role="e" v-if="window === 'emergency'"/>
                        <employee-documents role="a" v-if="window === 'documents'" :employee="infoEmployee.RecID"/>
                        <password-vault role="a" v-if="window === 'passwordVault'" :employee="infoEmployee.RecID"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions}   from 'vuex'
import ProfileInformation from '@/views/employees/profile/views/profile_information'
import PersonalInformation from '@/views/employees/profile/views/personal_information'
import ProfessionalInformation from '@/views/employees/profile/views/professional_information'
import EmergencyContact from '@/views/employees/profile/views/emergency_contact'
import EmployeeDocuments from '@/views/employees/profile/views/documents'
import PasswordVault from '@/views/employees/profile/views/password_vault'
import auth                                 from "@/config/user"
export default {
    components: {
        ProfileInformation,
        PersonalInformation,
        ProfessionalInformation,
        EmergencyContact,
        EmployeeDocuments,
        PasswordVault,
    },
    data(){
        return{
            striped: true,
            animate: true,
            timer: 0,
            value: 0,
            max: 100,    
            hideProgress: true,
            title:'My profile',
        }
    },
    computed:{
        ...mapGetters(['infoEmployee','loadingFlag']),
        window () {
            return this.$route.name.split('.').pop();
        }
    },
    created() {
        this.loadInfoEmployee();
    },
    methods: {
        ...mapActions(['loadMyProfileByEmpId', 'fetchEditableEmployeeInfo',]),
        startTimer() {
            let vm = this;
            let timer = setInterval(function() {
                vm.value += 3;
                if (vm.value >= 99) clearInterval(timer);
            }, 100);
        },
        loadInfoEmployee(){
            this.startTimer();
            let idEmployee = auth.currentUser.EmpID;
            this.loadMyProfileByEmpId(idEmployee).then(() => {
                this.fetchEditableEmployeeInfo(idEmployee).then(() => {
                    this.hideProgress = false;
                })
            }, error => {
                console.error("Error fetching the projects: "+ error)
            });
        },
        changeWindow: function(id){
            this.$router.push({ name: `myProfile.${id}` });
        },
    },
    mounted(){
        let title = 'My Profile';
        this.$emit('set-title',title);
    },
}
</script>
<style scoped>
    .profile-size{
    width: 20rem !important;
    height: 40rem !important;
    border-radius: 10px;
    overflow: hidden;
    position: relative !important;
}
.main{
    width: 100% !important;
}
.page{
    height: 40rem !important;
    width: 100% !important;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    background: transparent;
    border: none;
}
.svg_wave_1{
    position: absolute;
    height: auto !important;
    width: 250% !important;
    top: 0rem;
    overflow: hidden !important;
    opacity: 0.7;
}
</style>