import {API_URL} from "@/store/actions/config";
import axios from "@/config/axios";
import {parseISO} from "date-fns";

const API_PREFIX = API_URL + "api/v4/incidents/"

const incidentColors = [
    {
        "color": "#7293B9",
        "toColor": "#84A5C6",
        "textColor": "#2D3A4A"
    },
    {
        "color": "#84B2CE",
        "toColor": "#95C3DB",
        "textColor": "#344752"
    },
    {
        "color": "#C489B8",
        "toColor": "#D29DC6",
        "textColor": "#4E3649"
    },
    {
        "color": "#A8CC8C",
        "toColor": "#B7DB9F",
        "textColor": "#435138"
    },
    {
        "color": "#F3C87A",
        "toColor": "#F6D78D",
        "textColor": "#615030"
    },
    {
        "color": "#E27D6D",
        "toColor": "#F09382",
        "textColor": "#5A312B"
    }
];

const state = {
    incidentTypes: [],
    activeIncidentType: {},
    employeeIncidents: [],
    selectedEmployeesList: [],
    activeRequests: 0,
    vacationLimits: [],
};

const getters = {
    incidentsIsLoading() {
        return state.activeRequests > 0;
    },

    incidentTypeList() {
        return state.incidentTypes;
    },

    activeIncidentType() {
        return state.activeIncidentType;
    },

    incidentHistory() {
        if (!Array.isArray(state.employeeIncidents)) {
            return []
        } else {
            return state.employeeIncidents;
        }
    },

    paginatedIncidents() {
        if (state.employeeIncidents?.data === undefined) {
            return {data:[]};
        }
        return state.employeeIncidents
    },

    selectedEmployees() {
        return state.selectedEmployeesList;
    },

    vacationLimits() {
        return state.vacationLimits;
    }
};

const mutations = {
    addActiveRequest(state, value) {
        state.activeRequests = Math.max(0, state.activeRequests += value);
    },

    setSelectedEmployees(state, employeeIds) {
        employeeIds = Array.isArray(employeeIds) ? employeeIds : [employeeIds];

        state.selectedEmployeesList = employeeIds;
    },

    setIncidentTypes(state, incidentTypes) {
        const formatted = [];

        for (let i = 0; i < incidentTypes.length; i++) {
            const colorIndex = i % incidentColors.length;

            formatted[i] = {...incidentTypes[i], ...incidentColors[colorIndex]};
        }

        state.incidentTypes = formatted;

        if (Object.keys(state.activeIncidentType).length === 0) {
            state.activeIncidentType = formatted[0];
        }
    },

    setActiveIncidentType(state, incidentType) {
        state.activeIncidentType = incidentType;
    },

    setIncidentHistory(state, incidentHistory) {
        state.employeeIncidents = incidentHistory.map(
            (incident) => {
                incident.from = parseISO(incident.from);
                incident.to = parseISO(incident.to);

                return incident;
            }
        );
    },

    setPaginatedIncidentHistory(state, incidentHistory) {
        incidentHistory.data = incidentHistory.data.map(
            (incident) => {
                incident.from = parseISO(incident.from);
                incident.to = parseISO(incident.to);

                return incident;
            }
        );

        state.employeeIncidents = incidentHistory;
    },

    setVacationLimits(state, value) {
        state.vacationLimits = (state.vacationLimits = value.map(
            (limit) => {
                limit.date = parseISO(limit.date);
                limit.expirationDate = parseISO(limit.expirationDate);

                return limit;
            }
        ));
    }
};

const actions = {
    async fetchIncidents({commit}, params) {
        commit('addActiveRequest', 1);
        try {
            const payload = {
                params: params,
            }

            const endpoint = API_PREFIX;
            const res = await axios.get(endpoint, payload);

            if (Object.keys(params).includes('page')) {
                commit('setPaginatedIncidentHistory', res.data.incidents);
            } else {
                commit('setIncidentHistory', res.data.incidents);
            }
            return res;
        } finally {
            commit('addActiveRequest', -1);
        }
    },

    async sendRequest({commit}, data) {
        commit('addActiveRequest', 1);
        try{
            const endpoint = API_PREFIX;
            return await axios.post(endpoint, data);
        } finally {
            commit("addActiveRequest", -1);
        }
    },

    async editRequestStatus({commit}, data) {
        commit('addActiveRequest', 1);
        try{
            const endpoint = API_PREFIX + 'set-status';
            return await axios.post(endpoint, data);
        } finally {
            commit("addActiveRequest", -1);
        }
    },

    async editRequestHrFolio({commit}, payload) {
        commit('addActiveRequest', 1);
        try{
            const endpoint = API_PREFIX + 'folio';
            return await axios.post(endpoint, payload);
        } finally {
            commit("addActiveRequest", -1);
        }
    },

    async fetchIncidentTypes({commit}, params) {
        commit('addActiveRequest', 1);
        try {
            const payload = {
                params: params,
            }

            const endpoint = API_PREFIX + 'types';
            const res = await axios.get(endpoint, payload);

            commit('setIncidentTypes', res.data.types);
            return res;
        } finally {
            commit("addActiveRequest", -1);
        }
    },

    async fetchVacationLimits({commit}, empID) {
        commit('addActiveRequest', 1);
        try {
            const payload = {
                params: {emp_id: empID}
            }

            const endpoint = API_PREFIX + 'vacations';
            const res = await axios.get(endpoint, payload);

            commit('setVacationLimits', res.data.entries);
            return res;
        } finally {
            commit("addActiveRequest", -1);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}