<template>
  <div v-if="isOpen">
    <div class="fixed w-screen left-0 top-0 h-screen z-10"/>
    <div
        ref="incidentCreationModal"
        v-click-outside-modal="closeModal"
        :style="{top: `${this.position.top}px`, left: `${this.position.left}px`}"
        class="fixed drop-shadow-lg rounded-2xl overflow-hidden z-50"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import moment from "moment/moment";
import ClickOutsideModal from "@/directives/click-outside-modal";

/**
 * This is the template for both the creation and edit modals for incidents.
 * Its main feature is appearing either on top or below a given html element,
 * based on the available space on the screen.
 *
 * @component
 */

export default {
    name: 'baseIncidentModal',

    directives: {
        clickOutsideModal: ClickOutsideModal
    },

    data() {
        return {
            isOpen: false,
            position: {},
        };
    },

    methods: {
        customFormatter(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        openModal(element) {
            this.isOpen = true;

            this.$nextTick(() => {
                const rect = element.getBoundingClientRect();
                const modal = this.$refs.incidentCreationModal;
                const modalWidth = modal.offsetWidth;
                const modalHeight = modal.offsetHeight;

                const widthExcess = window.innerWidth - rect.left - modalWidth - 50;
                const heightExcess = window.innerHeight - rect.bottom - modalHeight;

                this.position = {
                    top: heightExcess > 0 ? rect.bottom: rect.top - modalHeight,
                    left: widthExcess > 0 ? rect.left : rect.left - Math.abs(widthExcess),
                }
                this.$emit('lock');
            });
        },
        closeModal() {
            this.$emit('unlock')
            this.isOpen = false;
        }
    }
}
</script>