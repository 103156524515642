<template>
    <div class="animate-pulse" v-if="loading">Loading...</div>
    <div v-else>
        <courses :caption="this.getResource.title" backLink="/trainings">
            <template slot="video">
                <div class="space-y-3">
                    <img :src="this.getResource.display_image" :alt="this.getResource.title"
                        class="h-[400px] m-auto mb-2" />
                    <a class="bg-c-primary px-4 py-2 text-gray-100 hover:text-white font-bold text-sm rounded-md cursor-pointer uppercase no-underline hover:bg-c-primary/90"
                        :href="this.getResource.link" target="_blank">
                        Open resource
                        <i class="bi bi-hand-index-thumb transform text-xl -rotate-45 ml-2"></i>
                    </a>
                    <a v-if="already_answered && !loadingFlag  && hasCourse"
                        class="bg-c-secondary px-4 py-2 text-gray-100 hover:text-white font-bold text-sm rounded-md cursor-pointer ml-2 uppercase no-underline hover:bg-c-secondary/90"
                        @click.prevent="$router.push({ name: 'surveys.view', params: { id: getResource.survey_id } })">
                        Test your knowledge with the quiz now!
                        <i class="bi bi-journal-check ml-2"></i>
                    </a>
                </div>
            </template>

            <div class="w-9/12 mx-auto">
                <p class="font-bold italic">
                    "I have read and understood the process “{{ this.getResource.title }}” and thereby commit to
                    execute it to
                    the best of my ability, and to escalate any questions I might have regarding the process I
                    might have in
                    the future"
                </p>

                <div v-if="already_answered && !loadingFlag" class="alert alert-success" role="alert">
                    You already agreed with this SOP, but you can send new feedbacks any time you want!
                </div>

                <div v-else-if="!already_answered && !loadingFlag" class="">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <b-form-checkbox v-model="Agreement" id="Agreement" value="Agree" required>I
                                    Agree
                                </b-form-checkbox>
                            </div>
                        </div>
                        <input type="text" v-if="username === '' || !userSecured" class="form-control is-invalid"
                            aria-label="Input" placeholder="Write your username" v-model="username" required>
                        <input type="text" v-else class="form-control is-valid" aria-label="Input"
                            placeholder="Write your username" v-model="username" required>
                        <div v-if="Agreement == 'Agree' && userSecured" class="valid-feedback">
                            *Please, mark the box and write your username to validate you read and agree the SOP
                        </div>
                        <div v-else class="invalid-feedback">
                            *Please, mark the box and write your username to validate you read and agree the SOP
                        </div>
                    </div>
                </div>

                <div label="Please let us know if you have any feedback:" class="mb-5">
                    <div class="input-group mb-1">
                        <b-form-textarea v-if="feedback == null || feedback == ''" v-model="feedback" no-resize
                            type="text" class="form-control is-invalid" rows="3" max-rows="3"
                            aria-describedby="button-addon1" placeholder="Please write any comment you have"
                            required></b-form-textarea>
                        <b-form-textarea v-else v-model="feedback" no-resize type="text" class="form-control is-valid"
                            rows="3" max-rows="3" aria-describedby="button-addon1"
                            placeholder="Please write any comment you have" required></b-form-textarea>
                        <div class="input-group-append">
                            <button class="bg-c-secondary rounded-r-lg p-3 text-white" type="button" id="button-addon1"
                                @click="sendFeedback()">Send</button>
                        </div>
                        <div class="invalid-feedback">*Write a comment to submit your agreement or a new feedback</div>
                    </div>
                </div>
            </div>
        </courses>
    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Courses from "@/views/components/Layouts/Resources/Courses";
export default {
    name: "",
    components: {
        Courses
    },
    computed: {
        ...mapState(['clocker']),
        ...mapGetters([
            'getFeedbackStatus',
            'getResource'
        ]),
        userSecured: function () {
            return this.username.toUpperCase() == this.emp_id.toUpperCase()
        },

        hasCourse: function () {
            return this.getResource.survey_id && this.getResource.survey_id !== null;
        }
    },
    async created() {

        await this.loadResource(this.$route.params.slug)

        this.$emit('set-title', this.getResource.title);

        this.feedback_name = this.getResource.slug;

        this.loading = false;

        this.confirmTerms(this.getResource.slug);
    },
    data() {
        return {
            Agreement: null,
            feedback: null,
            feedback_name: null,
            store_type: null,
            submitted: false,
            loadingFlag: true,
            already_answered: false,
            username: '',
            CurrentPermissions: [],
            loading: true
        }
    },
    methods: {
        ...mapActions([
            'checkFeedbackStatus',
            'storeFeedback',
            'loadResource',
        ]),

        confirmTerms: function (feedback) {
            this.checkFeedbackStatus(feedback).then(() => {
                if (this.getFeedbackStatus == 'already_answered') {
                    this.already_answered = true;
                    this.loadingFlag = false;
                    this.store_type = 'concatenate';
                    this.Agreement = 'Agree';
                    this.username = this.EmployeeId;
                } else if (this.getFeedbackStatus == 'not_initialized') {
                    this.already_answered = false;
                    this.loadingFlag = false;
                    this.store_type = 'new';
                }
            }, error => {
                console.error("Error fetching the exam: " + error)
            });
        },

        sendFeedback: function () {
            if (this.Agreement != 'Agree' || this.feedback == null || this.feedback == '' || this.username == null || this.username == '') {
                this.$fire({
                    type: 'warning',
                    title: 'There is information missing',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                let objectExam = {
                    'feedback_name': this.feedback_name,
                    'feedback': this.feedback,
                    'store_type': this.store_type,
                };
                this.storeFeedback(objectExam).then((res) => {
                    if (res.status == 200) {
                        this.$fire({
                            type: 'success',
                            title: 'The Feedback has been store successfully',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            this.$router.go(0);
                        });
                    } else {
                        this.$fire({
                            type: 'warning',
                            title: 'There are some errors',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            this.$router.go(0);
                        });
                    }
                });
            }
        },
    },
};
</script>