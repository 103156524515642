<template>
<div>
    <div class="flex flex-row align-center items-center py-5" v-if="isLoading">
        <material-loader class="mx-auto"/>
    </div>
     
    <div class="" v-else>
         <p class="text-center text-gray-500">
            Update the training details and press the "save" button in order to edit the training information.
        </p>
        <div class="bg-white shadow-sm max-w-[700px] mx-auto rounded-md p-10">
            <h4 class="font-semibold text-gray-700 pl-2 text-left mb-4">Training Details</h4>
            <div class="flex flex-row flex-wrap mb-2">
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-2/3 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Trainer            
                    </label>        
                    <trainers-list @toggle="setTrainer" :val="trainer"/> 
                    <div class="relative w-full" v-if="$v.form.trainer_empID.$dirty" >
                        <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.trainer_empID.$invalid">This field is mandatory</p>
                        <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                    </div>         
                </div>                 
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/3 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Agents #            
                    </label>        
                    <input class="w-full border-b-2 px-3 block focus:border-gray-700" min="1" type="number" v-model="trainees">                                           
                    <div class="relative w-full" v-if="$v.form.trainees_number.$dirty" >
                        <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.trainees_number.$invalid">This field is mandatory</p>
                        <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                    </div> 
                </div>     
                <div class="relative flex flex-column align-start justify-center sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Purchase Order Name           
                    </label>        
                    <input class="w-full border-b-2 px-3 block focus:border-gray-700" type="text" v-model="form.po_label">
                </div>   
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Department            
                    </label>        
                    <departments-list @toggle="setDepartment" @ready="markAsLoaded('departments',$event)" :val="department"/>    
                    <div class="relative w-full" v-if="$v.form.department_id.$dirty" >
                        <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.department_id.$invalid">This field is mandatory</p>
                        <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                    </div>                                  
                </div>     
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2" v-if="statusFlags.departments">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Campaign            
                    </label>        
                    <campaign-list :department="department" @ready="markAsLoaded('campaigns',$event)" :val="campaign" @toggle="setCampaign"/>
                    <div class="relative w-full" v-if="$v.form.campaign_id.$dirty" >
                        <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.campaign_id.$invalid">This field is mandatory</p>
                        <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                    </div>                                      
                </div>   
                <!-- #LOB-->
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2" v-if="statusFlags.campaigns">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        L.O.B            
                    </label>
                    <lob-list :campaign="campaign" :val="LOB" @toggle="setLob"/>        
                    <div class="relative w-full" v-if="$v.form.lob_id.$dirty" >
                        <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.lob_id.$invalid">This field is mandatory</p>
                        <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                    </div>
                </div>
                <!-- #Location-->
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Location         
                    </label>        
                    <locations @toggle="setLocation" :val="getCurrentTraining.location"/>       
                    <div class="relative w-full" v-if="$v.form.location.$dirty" >
                        <p class="text-red-400 text-xs text-left px-2 pt-2" v-if="$v.form.location.$invalid">This field is mandatory</p>
                        <p class="text-green-400 text-xs text-left px-2 pt-2" v-else>Looks great.</p>
                    </div> 
                                
                </div>   
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Start Date         
                    </label>        
                    <input class="w-full border-b-2 px-3 block focus:border-gray-700" :min="this.baseDate" type="date" v-model="form.start_date" @keyup="$v.form.title.$touch()">        
                </div> 
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        End Date         
                    </label>        
                    <input class="w-full border-b-2 px-3 block focus:border-gray-700"  type="date" :min="getCurrentTraining.start_date" v-model="form.end_date" @keyup="$v.form.title.$touch()">        
                                
                </div> 
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Schedule Start         
                    </label>        
                    <input class="w-full border-b-2 px-3 block focus:border-gray-700" type="time"  v-model="form.schedule_start" >        
                                
                </div> 
                <div class="relative flex flex-column align-start justify-center mb-1 sm:w-full md:w-1/2 px-2 mb-2">
                    <label class="text-gray-400 text-left text-sm ml-2 block">
                        Schedule End         
                    </label>        
                    <input class="w-full border-b-2 px-3 block focus:border-gray-700" type="time"   v-model="form.schedule_end">                                             
                    
                </div> 
                <button class="mx-auto bg-gray-700 rounded-sm shadow-sm p-2 px-3 mt-4 text-white" @click.prevent="submit">
                    <span v-if="saving" >
                        Saving
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 animate-bounce inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                    </span>
                    <span v-else>
                        Save
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {required,between } from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex'
import  DepartmentsList from '@/views/mockups/DepartmentsList'
import CampaignList from '@/views/mockups/CampaingsList'
import LobList from '@/views/mockups/LobList'
import Locations from '@/views/mockups/Locations'
import TrainersList from '@/views/mockups/TrainersList'
import MaterialLoader from '@/views/mockups/MaterialLoader'

export default {
    components:{
        'departments-list':DepartmentsList,
        CampaignList,
        LobList,
        Locations,
        TrainersList,
        MaterialLoader
    },
    data(){
        return{
            form:[],
            baseDate:null,
            statusFlags:{
                departments:false,
                campaigns:false
            },
            loading:true,
            saving:false
        }
        
    },
    validations:{
        form: {
            department_id:{
                required
            },
            campaign_id:{
                required
            },
            lob_id:{
                required
            },
            location:{
                required
            },
            trainer_empID:{
                required
            },
            trainees_number:{
                required,between:between(1,999)
            }, 
            start_date:{
                required
            },
            end_date:{
                required
            },
            schedule_start:{
                required
            },
            schedule_end:{
                required
            },  
        }
    },
    beforeMount(){
        this.$emit('set-title', 'Edit Training');
      
        this.baseDate = this.$moment().add(1,'days').format('YYYY-MM-DD');
        this.fetchTrainingById(
            this.$route.params.id
        ).catch(()=>{

            this.$fire({
                type:'error',
                title:'The selected training doesnt exist'
            })
            .then(()=>{
                this.$router.push({name:'trainings.index'});
            })
        });
      
    },
    computed:{  
        ...mapGetters(['getTrainingStatus','getCurrentTraining','getDefinitionStatus']),
        location:function(){
            return this.form.location            
        },
        campaign:function(){
            return this.form.campaign_id            
        },
        trainer:function(){
            return this.form.trainer_empID;            
        },
        department:function(){
            return this.form.department_id            
        },
        LOB:function(){
            return this.form.lob_id;
        },
        trainees:{
            get(){
                return this.form.trainees_number;
            },
            set(value){
                this.form.trainees_number = value ;
            }
        },
        detaset:function(){            
            return this.form;
        },
        timeIsLower:function(){

            return this.form.schedule_end <= this.form.schedule_start;
        },
        isLoading:function(){
            return this.loading;
        }

    },
    methods:{
        ...mapActions(['updateTraining','fetchTrainingById']),
        setDepartment:function(department){
           this.form.department_id = department;
        },
        setCampaign:function(campaign_id){
            this.form.campaign_id = campaign_id;
        },
        setLob:function(lob){
            this.form.lob_id = lob;
        },
        setLocation:function(location){
            this.form.location = location;
        },
        setTrainer:function(trainer){
            this.form.trainer_empID = trainer;
        },
        markAsLoaded:function(index){
           this.statusFlags[index] = true;           
        },
        submit(){
            this.$v.form.$touch();
 
            if(this.timeIsLower) return  this.$fire({
                    type:'warning',
                    title:'The schedule end time must be greater than the schedule start time.',
                    showConfirmButton:true
                });

            if(this.$v.form.$invalid){
                this.$fire({
                    type:'warning',
                    title:'Fill the mandatory fields in order to proceed.',
                    showConfirmButton:true
                });
                return;
            }

            this.saving = true;
            this.updateTraining(this.form).
            then(()=>{
                 this.$fire({
                    type:'success',
                    title:'The training was stored successfully.',
                    showConfirmButton:true
                });
            })
            .catch((error)=>{
                 let message =  error.response.data.message ?? 'Something went wrong';
            
              this.$fire({
                  type: 'warning',
                  title:`Something went wrong.Error message: ${message}`,
                  showConfirmButton: true,                
              });    
            })
            .then(()=>{
                this.saving = false;
              
            }).then(()=> this.$router.push({name:'trainings.index'}));
        }
    },
    created(){
        this.loading = true;
        this.fetchTrainingById(
            this.$route.params.id
        ).then(()=>{
            this.form = this.getCurrentTraining;
            
        }).then(()=>{
            this.$store.commit('setTrainingStatus',false);
            this.loading = false;
        })
        .catch(()=>{

            /*this.$fire({
                type:'error',
                title:'The selected training doesnt exist'
            })
            .then(()=>{
                this.$router.push({name:'trainings.index'});
            });*/
        });
    }
    
}
</script>