<template>
<div>
    <div v-if="isLoading">
        <div class="relative mt-3 flex flex-row items-center max-w-5xl bg-white px-5 pt-3 mb-3 rounded-sm mx-auto animate-pulse">
            <div class="rounded-full w-12 h-12 bg-gray-300 overflow-hidden">
                
            </div>
             <div class="relative ml-3 text-left">
                <div class="relative text-xl font-semibold bg-gray-300 w-48 rounded-full h-4 mb-2">
                   
                </div>
                 <div class="relative text-xl font-semibold bg-gray-300 w-48 rounded-full h-4 mb-2">
                  
                </div>
                <div>
                     Loading data...
                </div>
            </div>
        </div>
    </div>

    <div v-else class="relative mt-3 flex flex-row items-center max-w-5xl bg-white px-4 pt-3 rounded-sm mx-auto">
         <div class="rounded-full w-12 h-12 bg-gray-200 overflow-hidden ml-3 mb-1">
                <img src="https://placecats.com/200/300" alt="profile_pic"/>
        </div>
        <div class="relative ml-3 text-left">
            <span class="text-xl font-semibold">
                {{getUserProfile.name}} 
                <span class="text-xs text-gray-400 font-semibold">#{{getUserProfile['emp_id']}}</span>
            </span>
            <span class="text-gray-400 text-xs block">
                {{getUserProfile.roles.map(role=> role.name).join(', ')}}
            </span>
        </div>

        <button class="ml-auto mr-3 bg-gray-700 px-3 py-2 rounded-sm shadow-sm text-white" @click="saveChanges">
            <loading v-if="sending"/>
            <span v-else>Save</span>
        </button>
    </div>
</div>

<!-- 
    1.- Crear barra de busqueda para buscar un usuario por employee_id,
    2.- Crear un componente que mue4stre los datos del usuario incluidos los roles ligados a su usuario.
    3.- Crear un componente skeleton para mostrar la animacion de carga al estar buscando los datos del usuario.
-->
  
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import Loading from '@/views/mockups/loading'
export default {
    components: {
        Loading
    },
    props:{profile:{type:Object, required:true} }, 
    data(){
        return {
            loading:true,
            sending: false
        }
    },
    computed:{
        ...mapGetters(['getUserProfile']),
        isLoading:function(){
            return this.profile?.emp_id == 'undefined' || this.profile?.emp_id == null;
        }
    },
    methods:{
        ...mapActions(['fetchUserProfile']),
        saveChanges:function(){
            this.$confirm("", "Are you sure you want to save the changes?", "question").then((result) => {
                if (result){                
                    this.sending = true; 
                    this.$emit('save');
                }
            })
        }
    },
    mounted(){
        
    }
}
</script>

<style>

</style>