<template>
  <div class="size-full flex flex-col divide-y-2">
    <div
        v-if="!disableButtons"
        class="w-full bg-[#f8fafc] flex flex-row justify-between border-b-[1px]"
    >
      <div class="flex flex-row transform-all duration-300" v-if="enableSelect">
        <button
            :disabled="incidentsIsLoading"
            class="py-2 text-sm pl-2 pr-4 text-[#000544]"
            @click.prevent="toggleSelect"
        >
          <span>Select Mode: {{ selectMode ? 'On' : 'Off' }}</span>
        </button>
        <button
            v-if="activateSelectAll" :disabled="incidentsIsLoading"
            class="text-sm pl-2 pr-4 text-[#000544]" @click.prevent="selectAll"
        >
          Select All
        </button>
        <button
            v-if="activateDeselectAll" :disabled="incidentsIsLoading"
            class="text-sm pl-2 pr-4 text-[#000544]"
            @click.prevent="deselectAll"
        >
          Deselect All
        </button>
      </div>
      <div class="py-2 text-sm pl-2 pr-4 text-[#000544]" v-else>
        Filter by either Pending or HR Pending for Select Mode
      </div>
      <div class="flex flex-row">
        <button
            :class="{'block opacity-100': selectMode, 'invisible opacity-0': !selectMode}"
            :disabled="!selectMode || incidentsIsLoading || checkMarkedEntries.length === 0"
            class="text-sm transition-all ease-in-out duration-300 hover:bg-[#8DC27F] bg-[#7FAF6F] text-white py-1 px-2 rounded-md mx-2 mb-1 disabled:bg-gray-300 group"
            @click.prevent="changeStatus(checkMarkedEntries, 'APPROVED')"
        >
          Approve Selected
        </button>
        <button
            :class="{'block opacity-100': selectMode, 'invisible opacity-0': !selectMode}"
            :disabled="!selectMode || incidentsIsLoading || checkMarkedEntries.length === 0"
            class="text-sm transition-all ease-in-out duration-300 hover:bg-[#E3766A] bg-[#D6655A] text-white py-1 px-2 rounded-md mx-2 mb-1 disabled:bg-gray-300 group"
            @click.prevent="changeStatus(checkMarkedEntries, 'APPROVED')"
        >
          Reject Selected
        </button>
      </div>
    </div>
    <div class="overflow-hidden bg-[#f8fafc]" style="scrollbar-gutter: stable;">
      <table
          class="table-fixed light-table shadow-none bg-white m-0 w-full border-b-2"
      >
        <thead>
          <tr>
            <th v-if="selectMode === true" class="!p-0" colspan="1"></th>
            <th class="text-center !px-0 !py-4" colspan="2">
              ID
            </th>
            <th class="text-center !p-0" colspan="5">
              Employee
            </th>
            <th class="text-center !p-0" colspan="6">
              Request Info
            </th>
            <th class="text-center !p-0" colspan="4">
              Date Range
            </th>
            <th class="text-center !p-0" colspan="4">
              Daily Duration
            </th>
            <th class="text-center !p-0" colspan="4">
              Status
            </th>
            <th v-if="!disableButtons" class="text-center !p-0" colspan="5">
              Actions
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="overflow-auto size-full" style="scrollbar-gutter: stable;">
      <table class="table-fixed light-table shadow-none bg-white m-0 w-full">
        <tbody>
          <tr
              v-for="(option, index) in options"
              :id="option.id"
              :key="option.id"
              class="border-y-[1px] hover:bg-gray-50 group h-20"
          >
            <td
                v-if="selectMode === true" class="content-center !text-end !p-0"
                colspan="1"
            >
              <input
                  :id="`ck_${index}`" v-model="checkMarkedEntries"
                  :checked="checkMarkedEntries.includes(option.id)"
                  :disabled="isLoading" :value="option.id"
                  type="checkbox"
                  @click="refreshLastTouched(index, $event)"
              >
            </td>

            <td class="content-center text-center !p-0" colspan="2">
              <span>{{ option.id }}</span>
            </td>

            <td colspan="5">
              <div class="font-semibold">
                {{ option.empID }}
              </div>
              <div class="text-xs leading-[0.80rem] text-start content-start">
                <ul class="capitalize">
                  <li
                      v-for="(field, index) in [
                      option.employee.title,
                      option.employee.campaign,
                      option.employee.lob
                      ]"
                      :key="`employeeField${index}`"
                      class="capitalize"
                  >
                    {{ field.toLowerCase() }}
                  </li>
                </ul>
              </div>
            </td>

            <td class="text-center content-center" colspan="6">
              <div class="flex flex-col w-full gap-y-2">
                <div class="relative rounded-full overflow-hidden text-center">
                  <div
                      :style="incidentPillStyle(option.name)"
                      class="opacity-40 absolute size-full"
                  >
                  </div>
                  <span
                      :style="incidentTextColor(option.name)"
                  >
                    {{ option.name }}
                  </span>
                </div>
                <div class="flex flex-col text-left">
                  <span class="block text-xs leading-[0.8rem]">
                    Submitted: {{
                      format(option.createdAt, 'MM-dd-yyyy hh:ss a')
                    }}
                  </span>
                  <span class="block text-xs leading-[0.8rem]">
                    By: {{ option.createdBy }}
                  </span>
                </div>
              </div>
            </td>

            <td class="text-center content-center" colspan="4">
              <div
                  class="flex flex-col items-center divide-y relative size-full"
              >
                <div
                    class="font-semibold p-1"
                >
                  {{ formatDate(option.from) }}
                </div>
                <div
                    class="!border-none px-2 absolute mx-auto top-1/3 text-xs
                bg-[#FFFFFF] group-hover:bg-gray-50"
                >
                  To
                </div>
                <div class="font-semibold p-1">
                  {{ formatDate(option.to) }}
                </div>
              </div>
            </td>

            <td class="text-center content-center" colspan="4">
              <div
                  v-if="option.startTime !== null"
                  class="flex flex-col items-center divide-y relative size-full"
              >
                <div class="font-semibold p-1">
                  {{ formatTime(option.startTime) }}
                </div>
                <div
                    class="!border-none px-2 absolute mx-auto top-1/3 text-xs
                bg-[#FFFFFF] group-hover:bg-gray-50"
                >
                  To
                </div>
                <div class="font-semibold p-1">
                  {{ formatTime(option.endTime) }}
                </div>
              </div>
              <div
                  v-else-if="option.type.all_day"
                  class="flex flex-col items-center divide-y relative size-full"
              >
                <div class="font-semibold p-1">
                  All Day
                </div>
              </div>
              <div
                  v-else
                  class="flex flex-col items-center divide-y relative size-full"
              >
                <div class="font-semibold p-1">
                  {{ secondsToHHmm(option.dailyDurationSeconds) }}
                </div>
              </div>
            </td>

            <td class="content-center text-center" colspan="4">
              <div class="flex flex-col size-full">
                <div class="relative rounded-full overflow-hidden text-center">
                  <div
                      :class="{
                  'bg-[#F3C87A]': ['HR PENDING', 'PENDING'].includes(option.status),
                  'bg-[#D6655A]': option.status === 'REJECTED',
                  'bg-[#A8CC8C]': option.status === 'APPROVED',
                  }"
                      class="opacity-40 absolute size-full"
                  >
                  </div>
                  <span
                      :class="{
                  'text-[#615030]': ['HR PENDING', 'PENDING'].includes(option.status),
                  'text-[#5A312B]': option.status === 'REJECTED',
                  'text-[#435138]': option.status === 'APPROVED',
                  }"
                  >
                    {{ option.status }}
                  </span>
                </div>
                <div class="w-full">
                  <div v-if="option.updatedStaff">
                    <span class="text-xs">Updated: {{ option.updatedStaff }}</span>
                  </div>
                  <div v-if="option.updatedHr">
                    <span class="text-xs">HR: {{ option.updatedHr }}</span>
                  </div>
                  <div v-if="option.hrFolio !== null">
                    <span class="text-xs">HR Folio: {{ option.hrFolio }}</span>
                  </div>
                </div>
              </div>
            </td>

            <td
                v-if="!disableButtons" class="text-center content-center"
                colspan="5"
            >
              <div class="flex flex-col size-full gap-y-1 px-2">
                <div class="flex flex-row gap-x-1 w-full">
                  <button
                      v-if="option.status === 'PENDING' || (option.status === 'HR PENDING' && isHr)"
                      :disabled="isLoading || (option.status === 'HR PENDING' && option.type.needs_folio === true && option.hrFolio === null)"
                      class="flex-1 bg-[#7FAF6F] text-white hover:bg-[#8DC27F] h-8 rounded-lg opacity-0 group-hover:opacity-100 disabled:grayscale"
                      @click="changeStatus([option.id], 'APPROVED')"
                  >
                    Approve
                  </button>
                  <button
                      v-if="option.status === 'PENDING' || (option.status === 'HR PENDING' && isHr)"
                      :disabled="isLoading"
                      class="flex-1 bg-[#D6655A] text-white hover:bg-[#E3766A] h-8 rounded-lg opacity-0 group-hover:opacity-100 disabled:grayscale"
                      @click="changeStatus([option.id], 'REJECTED')"
                  >
                    Reject
                  </button>
                </div>
                <div v-if="option.status === 'HR PENDING' && isHr" class="w-full">
                  <button
                      :disabled="isLoading"
                      class="w-full bg-[#5d7a9d] text-white hover:bg-[#6f90bf] h-8 rounded-lg opacity-0 group-hover:opacity-100 disabled:grayscale"
                      @click="addFolio([option.id])"
                  >
                    Add Folio
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import {format} from "date-fns";
import {enUS} from 'date-fns/locale';

export default {
    name: "IncidentTable",

    props: {
        options: {
            required: true,
            type: Array,
        },
        isLoading: {
            required: false,
            type: Boolean,
        },
        enableSelect: {
            required: false,
            default: false,
            type: Boolean,
        }
    },

    data() {
        return {
            checkMarkedEntries: [],
            lastTouchedID: null,
            selectMode: false,
        };
    },

    computed: {
        ...mapGetters(
            'incidentManager',
            [
                'incidentsIsLoading',
                'incidentHistory',
                'incidentTypeList'
            ]
        ),
        
        isHr() {
            return this.permissions.includes('HR');
        },

        disableButtons() {
            return this.permissions.includes('GeneralPermissions');
        },

        activateSelectAll() {
            return this.selectMode === true &&
                this.checkMarkedEntries.length < this.options.length;
        },

        activateDeselectAll() {
            return this.selectMode === true &&
                this.checkMarkedEntries.length ===  this.options.length;
        },
    },
    
    watch: {
        enableSelect(newValue) {
            if (newValue === false) {
                this.selectMode = false;
                this.checkMarkedEntries = [];
            }
        },
    },

    methods: {
        ...mapActions(
            'incidentManager',
            [
                'editRequestHrFolio',
                'editRequestStatus'
            ]
        ),

        changeStatus(incidentIds, status) {
            this.$fire({
                title: "Are you sure?",
                text: `You will be changing the status of the following
                (${incidentIds.length}) incident id(s):
                ${incidentIds.join(',')}; to ${status}`,
                type: 'question',
                showConfirmButton: true,
                showCancelButton: true
            }).then(
                (response) => {
                    if (!response.value) {
                        return;
                    }

                    this.editRequestStatus(
                        {
                            request_ids: incidentIds,
                            status: status,
                        }
                    ).then(
                        () => {
                            this.$fire({
                                type: 'success',
                                title: 'Success!'
                            });
                            this.$emit('update');
                        },
                    );
                },
            ).catch(
                (error) => {
                    console.error(error);
                    this.$fire({
                        title: "Something went wrong.",
                        type: "Error",
                        showConfirmButton: true,
                    });
                }
            )
        },

        toggleSelect() {
            this.checkMarkedEntries = [];
            this.selectMode = !this.selectMode;
        },
        
        addFolio(incident) {
            this.$fire({
                title: "Add A Folio",
                text: `Incident ID: ${incident[0]}`,
                input: "number",
                inputAttributes: {
                    autocapitalize: "off",
                    autocorrect: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Confirm",
            }).then(
                (result) => {
                    if (result.value === undefined) {
                        return;
                    }

                    const payload = {
                        request_ids: incident,
                        folio: parseInt(result.value),
                    };
                    return this.editRequestHrFolio(payload);
                }
            ).catch(
                (error) => {
                    console.error(error);
                    this.$fire({
                        title: "Something went wrong.",
                        type: "error",
                        showConfirmButton: true,
                    })
                }
            ).finally(
                () => {this.$emit('update');}
            );
        },

        format,
        
        secondsToHHmm(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const timeStrings = [];
            
            if (hours > 0) {
                timeStrings.push(`${hours} Hour${hours > 1 ? 's' : ''}`);
            }
            if (minutes > 0) {
                timeStrings.push(`${minutes} Minute${minutes > 1 ? 's': ''}`);
            }
            
            return timeStrings.join(" ");
        },

        formatTime(timeString) {
            return format(new Date(timeString), 'hh:mm a');
        },

        uncheckValues(valueArray) {
            this.checkMarkedEntries = this.checkMarkedEntries.filter(
                x => !valueArray.includes(x),
            );
        },

        submit(entries, status) {
            const params = {
                entries: entries,
                status: status
            }
            this.$emit('submit', params);
        },

        incidentTextColor(incidentName) {
            const incidentColors = this.incidentTypeList.find(
                x => {
                    return x.name.toLowerCase() === incidentName.toLowerCase();
                }
            );

            return {color: incidentColors.textColor,}
        },

        incidentPillStyle(incidentName) {
            const incidentColors = this.incidentTypeList.find(
                x => {
                    return x.name.toLowerCase() === incidentName.toLowerCase();
                }
            );

            return {
                backgroundColor: incidentColors.color,
            }
        },

        checkmarkValues(valueArray) {
            this.checkMarkedEntries = [
                ...new Set([...this.checkMarkedEntries, ...valueArray]),
            ];
        },

        getCheckboxes() {
            let checkboxes = document.querySelectorAll('[id^="ck"]');

            return Array.from(checkboxes);
        },

        getCheckboxValues() {
            return this.getCheckboxes().map(x => parseInt(x.value));
        },

        selectAll() {
            this.checkmarkValues(this.getCheckboxValues());
        },

        deselectAll() {
            this.uncheckValues(this.checkMarkedEntries);
        },

        refreshLastTouched(currentID, event) {
            if (this.lastTouchedID !== null && event.shiftKey === true) {
                let checkboxes = this.getCheckboxes()

                const toCheck = checkboxes[this.lastTouchedID].checked;

                let actionable = this.getCheckboxValues().slice(
                    Math.min(currentID, this.lastTouchedID) + 1,
                    Math.max(currentID, this.lastTouchedID),
                );

                if (toCheck === true) {
                    this.checkmarkValues(actionable);
                }

                if (toCheck === false) {
                    this.uncheckValues(actionable);
                }
            }

            this.lastTouchedID = currentID;
        },

        formatDate(dateObj) {
            return format(dateObj, 'MMMM do yyyy', {locale: enUS});
        },
    },
}
</script>
<style scoped>
tbody tr:first-child {
    border-top-width: 0;
}

tbody tr:last-child {
    border-bottom-width: 0;
}

tbody tr {
    border-right-width: 0;
    border-left-width: 0;
}

td {
    padding: 0 !important;
}
</style>